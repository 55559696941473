import { getUserToken } from './apiFunctions/auth';

async function request(url, options) {
  const response = await fetch(process.env.REACT_APP_API_RB + url, {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': getUserToken(),
    },
    ...options,
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch(err => ({ err }));

  return response;
}

function checkStatus(response) {
  if (response.ok) {
    return response;
  } else {
    throw new Error(response.statusText);
  }
}

function parseJSON(response) {
  return response.json();
}

const getPlanById = async planId => {
  const response = await request(`/planos/id/${planId}`, { method: 'GET' });
  return response;
};

export { request, getPlanById };

import { Box, Typography } from "@mui/material";
import {
  BACKGROUND_GRAY_100,
  BACKGROUND_LIGHT_GREEN,
  BACKGROUND_WHITE,
  BORDER_GRAY_100,
  BORDER_LIGHT_GREEN,
  TEXT_GRAY_700,
} from "../../constants/colors";
import FormCheck from "../../assets/imgs/svgs/FormCheck";

export default function FormSteps({ steps }: any) {
  const RenderSteps = steps.map((step: any, index: any) => {
    return (
      <>
        <Box
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          gap={"10px"}
          justifyContent={"center"}
          key={index}
          position={"relative"}
          sx={{
            transition: "all ease-in-out 0.3s",
          }}
          width={"50px"}
        >
          <Box
            bgcolor={step.done ? BACKGROUND_LIGHT_GREEN : BACKGROUND_WHITE}
            borderRadius={"1000px"}
            border={
              step.done
                ? "none"
                : step.current
                ? `4px solid ${BORDER_LIGHT_GREEN}`
                : `4px solid ${BORDER_GRAY_100}`
            }
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
            width={"50px"}
            height={"50px"}
            sx={{ transition: "all ease-in-out 0.3s" }}
          >
            {step.done ? (
              <FormCheck />
            ) : (
              <Typography
                color={TEXT_GRAY_700}
                fontSize={"19px"}
                fontWeight={"500"}
                lineHeight={"1rem"}
              >
                {step.number}
              </Typography>
            )}
          </Box>
          <Typography
            position={"absolute"}
            color={TEXT_GRAY_700}
            fontSize={"12px"}
            fontWeight={"500"}
            bottom={"-25px"}
            sx={{ inlineSize: "max-content" }}
          >
            {step.title}
          </Typography>
        </Box>
        {steps.length > step.number && (
          <Box
            width={"100%"}
            height={"4px"}
            bgcolor={step.done ? BACKGROUND_LIGHT_GREEN : BACKGROUND_GRAY_100}
            sx={{ transition: "all ease-in-out 0.3s" }}
          />
        )}
      </>
    );
  });

  return (
    <Box
      display={"flex"}
      paddingY={"1.5rem"}
      paddingX={"3.5rem"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ transition: "all ease-in-out 0.3s" }}
    >
      {RenderSteps}
    </Box>
  );
}

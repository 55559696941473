import { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import {
  SituationUserDto,
  SituationUserDtoHandler,
} from "../../dtos/SituationUserDto";
import api from "../../services/api";
import { getHeaders } from "../../utils/apiFunctions/auth";
import { Box } from "@mui/material";
export interface DashboardNivigationProps {
  className?: string;
}

// Dashboard nav
export default function DashboardNavLayout(props: DashboardNivigationProps) {
  const navigate = useNavigate();
  //const location = useLocation();
  const [situationUser, setSituationUser] = useState<SituationUserDto>(
    SituationUserDtoHandler.getDefault()
  );
  const [isSituationUser, setIsSituationUser] = useState(
    situationUser.situation
  );
  const iSituationUserRef = useRef(isSituationUser);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  //manipulando models
  function openModal() {
    setIsOpen(true);
  }

  function closeModal(event: any) {
    event.preventDefault();
    setIsOpen(false);
  }

  //alterando o navBar para mobile
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  useEffect(() => {
    loadSitution();
  }, []);

  async function loadSitution() {
    api
      .get(`/contratos/situacao/`, getHeaders())
      .then((response) => {
        const newSituationUser = SituationUserDtoHandler.getByDatabase(
          response.data[0]
        );
        iSituationUserRef.current = newSituationUser.situation;
        setSituationUser(newSituationUser);
        setIsSituationUser(iSituationUserRef.current);
      })
      .catch(() => console.log("Erro ao autenticar o usuário"));
  }

  //link active
  const navLinkStyles = ({ isActive }: any) => {
    return {
      color: isActive ? "#154B90" : "#969696",
    };
  };

  //function para sair
  function handleLogout() {
    localStorage.removeItem("user");
    setIsOpen(false);
    navigate("/login");
  }

  return (
    <Box>
      <button onClick={toggleNav} className="btnMenu">
        Menu
      </button>
      {(toggleMenu || screenWidth > 1024) && (
        <nav className="nav">
          <div className={"nav__menu"}>
            <ul className="nav__list">
              <li className="nav__item">
                <NavLink
                  to="/dashboard/video"
                  className="nav__link"
                  style={navLinkStyles}
                  onClick={() => setToggleMenu(!toggleMenu)}
                >
                  Vídeos
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  to="/dashboard/prices"
                  className="nav__link"
                  style={navLinkStyles}
                  onClick={() => setToggleMenu(!toggleMenu)}
                >
                  Cotações
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  to="/dashboard/graphic"
                  className="nav__link"
                  style={navLinkStyles}
                  onClick={() => setToggleMenu(!toggleMenu)}
                >
                  Gráficos
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  to="/dashboard/movies"
                  className="nav__link"
                  style={navLinkStyles}
                  onClick={() => setToggleMenu(!toggleMenu)}
                >
                  Séries
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  to="/dashboard/news"
                  className="nav__link"
                  style={navLinkStyles}
                  onClick={() => setToggleMenu(!toggleMenu)}
                >
                  Alertas
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink
                  to="/dashboard/indicators"
                  className="nav__link"
                  style={navLinkStyles}
                  onClick={() => setToggleMenu(!toggleMenu)}
                >
                  Indicadores
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="btn__nav">
            <button
              className="button_person"
              onClick={() => {
                navigate("/dashboard/myAccount");
                setToggleMenu(!toggleMenu);
              }}
            >
              Minha conta
            </button>
            <button className="button_person button__logot" onClick={openModal}>
              Sair
            </button>
            <Modal
              isOpen={modalIsOpen}
              onResquestClose={closeModal}
              ariaHideApp={false}
              contentLabel="Example modal"
              overlayClassName="modal-overlay"
              className="modal-content"
            >
              <h3 className="modal_title">Deseja realmente sair?</h3>
              <div className="box-button-modal">
                <button
                  className="button-modal button-cancelar"
                  onClick={closeModal}
                >
                  Cancelar
                </button>
                <button
                  className="button-modal button-sair"
                  onClick={handleLogout}
                >
                  Sair
                </button>
              </div>
            </Modal>
          </div>
        </nav>
      )}
    </Box>
  );
}

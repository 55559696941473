import { useState, useEffect } from 'react';

const CountdownTimer = ({
  title = 'OFERTA POR TEMPO LIMITADO!',
  titleFont = 'text-lg',
  numberFont = 'text-3xl',
  labelFont = 'text-xs',
  limitDate = '2023-11-26T24:00:00',
}) => {
  const targetDate = new Date(limitDate).getTime(); // Data-alvo (substitua pela data desejada)
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeDifference = targetDate - now;

      if (timeDifference <= 0) {
        clearInterval(interval);
        setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeRemaining({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return (
    <div
      className={`bottom-0 z-[2147483646] flex w-full flex-col items-center justify-evenly gap-3 p-3 md:bottom-auto md:w-fit md:rounded-xl md:bg-transparent`}
    >
      <p className={`text-center font-black uppercase ${titleFont}`}>{title}</p>
      <div className="flex w-full justify-evenly">
        <div className={` flex flex-col items-center justify-center font-black ${numberFont}`}>
          {timeRemaining.days} <p className={`${labelFont}`}>dias</p>
        </div>
        <div className={`flex flex-col items-center justify-center font-black ${numberFont}`}>
          {timeRemaining.hours} <p className={`${labelFont}`}>horas</p>
        </div>
        <div className={`flex flex-col items-center justify-center font-black ${numberFont}`}>
          {timeRemaining.minutes} <p className={`${labelFont}`}>minutos</p>
        </div>
        <div className={`flex flex-col items-center justify-center font-black ${numberFont}`}>
          {timeRemaining.seconds} <p className={`${labelFont}`}>segundos</p>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;

import { useEffect } from 'react';
import Signature from '../../components/Form/Signature';
import '../styles.scss';
import scrollToTop from '../../utils/scrollToTop';
import { useParams } from 'react-router-dom';
import HelpBadge from '../Plans/HelpBadge';

export default function Form() {
  // Verificando origem de publicidade
  const { ad } = useParams();
  // reseta LocalStorage e verifica se o ad não é undefined
  //localStorage.removeItem("ad");
  if (ad !== undefined) {
    const publi = ad;
    localStorage.setItem('ad', String(publi));
  }
  useEffect(() => {
    scrollToTop();
  });
  return (
    <>
      <Signature />
      <HelpBadge />
    </>
  );
}

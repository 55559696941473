import { Apple, MockupLateralMobile, PlayStore } from '../../assets/imgs';

export default function AccessComponent() {
  return (
    <section
      className="flex items-center justify-center bg-white px-3 pb-20 pt-40 md:px-28 lg:min-h-screen"
      id="acesse"
    >
      <div className="grid grid-cols-1 items-center justify-center lg:grid-cols-3">
        <img
          src={MockupLateralMobile}
          className="order-2 max-h-[400px] justify-self-center sm:order-1 lg:max-h-[600px]"
          alt=""
        />
        <div className="order-1 col-span-1 flex flex-col gap-8 sm:order-2 sm:col-span-2 sm:px-10">
          <div>
            <h2 className="font-helvetica text-2xl font-black uppercase text-zinc-800 lg:text-3xl xl:text-4xl">
              Acesse de qualquer navegador web
            </h2>
            <h2 className="font-helvetica text-2xl font-black uppercase text-green-500 lg:text-3xl xl:text-4xl">
              ou pelo nosso APP
            </h2>
          </div>
          <p className="max-w-2xl text-lg leading-relaxed text-gray-500 xl:text-xl">
            Assine já e tenha acesso ao nosso conteúdo exclusivo direto no nosso app no seu smartphone, veja como é
            fácil.
          </p>
          <div className="aspect-video w-full max-w-2xl overflow-hidden rounded-xl">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/2Iu3P76SHzg"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
          <div className="flex items-center gap-3">
            <img
              src={PlayStore}
              onClick={() =>
                window.open('https://play.google.com/store/apps/details?id=br.com.rbvideo.rbvideo&hl=pt_BR', '_blank')
              }
              className="h-14 cursor-pointer sm:h-14"
              alt="playstore link"
            />
            <img
              src={Apple}
              onClick={() => window.open('https://apps.apple.com/us/app/rb-video/id1324714161', '_blank')}
              className="h-11 cursor-pointer sm:h-11"
              alt="appstore link"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

import { useState } from "react";
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { UserForgot, UserForgotHandler } from "../../interfaces/userForgot";
import api from "../../services/api";
import "../styles/formStyle.scss";
import {
  BACKGROUND_WHITE,
  BORDER_GRAY_100,
  BUTTON_LIGHT_GREEN,
  TEXT_DARK_BLUE,
  TEXT_GRAY_700,
} from "../../constants/colors";
import { LoginLayout } from "../../layouts";
import { ButtonHandler } from "../../components";

export default function Forgot() {
  const [userCpf, setUserCpf] = useState<UserForgot>(
    UserForgotHandler.getDefault()
  );
  const [open, setOpen] = useState(false);

  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });

  async function sendEmail(user: any) {
    try {
      await api.post("/clientes/recuperar", user);
    } catch (error) {
      console.log("Erro recuperar senha");
      return;
    }
  }

  function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  }

  function handleValidate() {
    if (!userCpf.cpf)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo CPF!",
      });
    if (userCpf.cpf.length < 11)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o CPF correto!",
      });

    return true;
  }

  function handleChangeUser(key: string, value: any) {
    setUserCpf({ ...userCpf, [key]: value });
  }

  function handleSubmit(e: any) {
    e.preventDefault();
    const user = UserForgotHandler.toDatabase(userCpf);

    if (!handleValidate()) return;

    setStatus({
      type: "",
      mensagem: "",
    });

    sendEmail(user[0]);
    setOpen(true);
    userCpf.cpf = "";
  }

  const ErrorMessage = () => {
    return (
      <>
        {status.type === "error" ? (
          <Alert
            variant="filled"
            severity="error"
            sx={{
              mb: 1,
              maxWidth: "320px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className={status.type === "error" ? "errorAssinatura" : "div-OFF"}
          >
            {status.mensagem}
          </Alert>
        ) : (
          ""
        )}
      </>
    );
  };

  const BottomContent = () => {
    return (
      <>
        <Box
          border={"1px solid"}
          borderColor={BORDER_GRAY_100}
          borderRadius={"10px"}
          p={2}
          width={"100%"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            flexWrap={"nowrap"}
          >
            <Link
              color={TEXT_DARK_BLUE}
              href="/login"
              underline="hover"
              width={"fit-content"}
            >
              VOLTAR PARA O LOGIN
            </Link>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <LoginLayout
        title={"Recuperar Senha"}
        errorMessage={<ErrorMessage />}
        bottomContent={<BottomContent />}
      >
        <Box>
          <Typography
            variant="h3"
            color={TEXT_GRAY_700}
            fontWeight={700}
            fontSize={"1rem"}
          >
            Digite o CPF da sua conta de usuário
          </Typography>
          <Typography color={TEXT_GRAY_700}>
            Nós lhe enviaremos um e-mail com a sua senha.
          </Typography>
        </Box>
        <TextField
          color="success"
          fullWidth
          type={"tel"}
          placeholder="CPF"
          required
          onChange={(e) => {
            handleChangeUser("cpf", e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  edge="start"
                  sx={{ cursor: "default" }}
                  className="icon-btn"
                >
                  <AccountCircle />
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: 11 }}
          size="small"
          sx={{
            backgroundColor: BACKGROUND_WHITE,
            borderRadius: "4px",
          }}
          value={userCpf.cpf}
        />
        <ButtonHandler
          title={"Enviar e-mail com a senha"}
          color={BUTTON_LIGHT_GREEN}
          action={handleSubmit}
          disabled={false}
        />
      </LoginLayout>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          A senha foi enviada ao seu e-mail!
        </Alert>
      </Snackbar>
    </>
  );
}

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Chip, Grid, Pagination, Stack } from "@mui/material";
import { Market } from "../../enums";
import { DashboardVideoComponent } from "../../components/Dashboard";
import api from "../../services/api";
import scrollToTop from "../../utils/scrollToTop";
import {
  getHeaders,
  loadAuth,
  loadSituation,
} from "../../utils/apiFunctions/auth";

export default function Videos() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [market, setMarket] = useState<Market>(Market.all);
  const marketRef = useRef(market);
  const [marketUser, setMarketUser] = useState([{}]);

  /*/ Derruba login ativo simultaneo
  useEffect(() => {
    const interval = setInterval(() => {
      loadAuth(handleAuth);
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  */
  //pegando o carregamento de dados
  useEffect(() => {
    loadAuth(handleAuth);
    loadSituation(handleSituation);
    const fetchPosts = async () => {
      setLoading(true);
      await loadVideo();
      await loadFilterPlanUser();
      setLoading(false);
    };
    fetchPosts();
  }, []);

  function handleSituation(data?: any) {
    if (data.situation !== "Pago") {
      navigate("/dashboard/myAccount");
      console.log("Usuario redirecionado, situação de pagamento 'Não Pago'");
    }
  }
  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("user");
    navigate("/login");
    console.log("Usuario não logado");
  }

  async function loadFilterPlanUser() {
    await api
      .get(`/videos/filtro/`, getHeaders())
      .then((res) => {
        const newFilterMarket = res.data;
        marketRef.current = newFilterMarket;
        setMarketUser(res.data);
      })
      .catch(() => console.log("Erro ao carregar videos"));
  }

  //carregando a array de dados dos videos da api.
  async function loadVideo() {
    await api
      .get(`/videos/`, getHeaders())
      .then((res) => {
        setPosts(res.data);
      })
      .catch(() => console.log("Erro ao carregar videos"));
  }

  //carregando a array de dados dos videos da apicom o filter.
  async function loadVideoFilter(marketPlan: any) {
    await api
      .get(`/videos/mercado/${marketPlan}`, getHeaders())
      .then((res) => {
        setPosts(res.data);
      })
      .catch(() => console.log("Erro ao carregar videos"));
  }

  //Obtendo os posts atuais por pages
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  //mudando as pages
  function paginate(pageNumber: any): void {
    return setCurrentPage(pageNumber);
  }

  async function handleChangeMarket(market: Market) {
    marketRef.current = market;
    setMarket(market);
    await loadVideoFilter(market);

    if (market !== Market.all) return await loadVideoFilter(market);

    await loadVideo();
  }

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        width={"100%"}
        maxWidth={1380}
        ml={"auto"}
        mr={"auto"}
      >
        <Stack
          direction="row"
          flexWrap={"wrap"}
          justifyContent={"center"}
          mt={2}
          mb={1}
          visibility={loading ? "hidden" : "visible"}
        >
          <Chip
            label="Todos"
            color="primary"
            component="a"
            onClick={() => handleChangeMarket(Market.all)}
            clickable
            variant={market === Market.all ? "filled" : "outlined"}
            sx={{ margin: "4px" }}
          />
          {marketUser.map((marketPlan: any, index: number) => (
            <Chip
              key={index}
              label={marketPlan.nome}
              color="primary"
              sx={{ margin: "4px" }}
              clickable
              component="a"
              onClick={() => handleChangeMarket(marketPlan.id)}
              variant={market === marketPlan.id ? "filled" : "outlined"}
              disabled={loading}
            />
          ))}
        </Stack>
        <Grid
          container
          justifyContent={"center"}
          alignContent={"center"}
          direction={"row"}
          spacing={2}
          p={4}
        >
          <DashboardVideoComponent posts={currentPosts} loading={loading} />
        </Grid>
        <Pagination
          count={Math.ceil(posts.length / postsPerPage)}
          color="primary"
          variant="outlined"
          shape="rounded"
          showFirstButton={true}
          showLastButton={true}
          hideNextButton={true}
          hidePrevButton={true}
          onChange={(e, value) => {
            paginate(value);
            scrollToTop();
          }}
          className="pagination"
          style={{ margin: "32px" }}
        />
      </Grid>
    </>
  );
}

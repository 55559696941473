import { ThirdPartyService } from "../../services";

export default function findCEP(
  cep,
  setLoadingCep,
  setDisableAddressInput,
  props
) {
  setLoadingCep(true);
  ThirdPartyService.findAddressByCep(cep)
    .then((address) => {
      setDisableAddressInput({
        address: address.address ? true : false,
        city: address.city ? true : false,
        neighborhood: address.neighborhood ? true : false,
        state: address.state ? true : false,
      });
      props.onChange({
        ...props.user,
        address: {
          ...address,
          cep,
        },
      });
    })
    .finally(() => {
      setLoadingCep(false);
    })
    .catch((error) => {
      console.log(error);
      window.alert(
        "Serviço de localização de CEP temporariamente indisponível, por favor preencha os dados de endereço manualmente."
      );
      setDisableAddressInput({
        address: false,
        city: false,
        neighborhood: false,
        state: false,
      });
    });
}

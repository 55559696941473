import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Pagination, Typography } from "@mui/material";
import { DashboardNewsComponent } from "../../components/Dashboard";
import { TEXT_DARK_BLUE } from "../../constants/colors";
import scrollToTop from "../../utils/scrollToTop";
import "../styles/newsStyle.scss";
import { loadNews } from "../../utils";
import { loadAuth, loadSituation } from "../../utils/apiFunctions/auth";

export default function News() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    loadAuth(handleAuth);
    loadSituation(handleSituation);
    const fetchPosts = async () => {
      setLoading(true);
      const res = await loadNews();
      setPosts(res);
      setLoading(false);
    };
    fetchPosts();
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [currentPage, posts]);

  function handleSituation(data?: any) {
    if (data.situation !== "Pago") {
      navigate("/dashboard/myAccount");
    }
  }
  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("user");
    navigate("/login");
  }
  //Obtendo os posts atuais por pages
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"30px"}
        padding={"30px"}
      >
        <Box>
          <Typography
            color={TEXT_DARK_BLUE}
            fontSize={"36px"}
            fontWeight={600}
            marginLeft={"9%"}
            variant="h1"
          >
            Alertas Rural Business
          </Typography>
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            gap={"20px"}
            justifyContent={"center"}
            margin={"64px auto"}
            maxWidth={"1500px"}
          >
            <DashboardNewsComponent posts={currentPosts} loading={loading} />
          </Box>
        </Box>
        <Pagination
          count={Math.ceil(posts.length / postsPerPage)}
          color="primary"
          shape="rounded"
          showFirstButton={true}
          showLastButton={true}
          hideNextButton={true}
          hidePrevButton={true}
          onChange={(e, value) => setCurrentPage(value)}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          variant="outlined"
        />
      </Box>
    </>
  );
}

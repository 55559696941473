import EventEmitter from "events";
import Axios from "axios";
import { UserAddress } from "../interfaces";

class ThirdParty extends EventEmitter {
  private static instance: ThirdParty;
  static getInstance() {
    if (!this.instance) {
      this.instance = new ThirdParty();
    }

    return this.instance;
  }

  findAddressByCep(cep: string): Promise<UserAddress> {
    const url = `https://viacep.com.br/ws/${cep}/json`;
    return new Promise((resolve, reject) => {
      Axios.get(url)
        .then((response) => {
          const data = response ? response.data : {};
          resolve({
            cep: data.cep || "",
            address: data.logradouro || "",
            neighborhood: data.bairro || "",
            state: data.uf || "",
            city: data.localidade || "",
            numberAddress: "",
          });
        })
        .catch(() => reject("Erro ao tentar localizar CEP"));
    });
  }
}

export const ThirdPartyService = ThirdParty.getInstance();

import { NewsDtoHandler } from "../../dtos/NewsDto";
import api from "../../services/api";

export default async function loadOneNews(
  news: String | undefined,
  token: String | null,
  setOneNews: any,
  setLoading: any
) {
  const newsId = news;
  //Tirando aspa da string do token
  const tokenJWT = token?.substring(1, token?.length - 1);
  setLoading(true);

  //Headers da API
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${tokenJWT}`,
    },
  };

  api
    .get(`/noticias/id/${newsId}`, headers)
    .then((response) => {
      setOneNews(NewsDtoHandler.getByDatabase(response.data[0]));
    })
    .catch(() => console.log("Erro ao carregar dados do usuário"))
    .finally(() => setLoading(false));
}

import { useLocation, useNavigate } from "react-router-dom";
import { Logo, LogoWhite } from "../../assets/imgs";

export default function LogoNavComponent() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const path = pathname.split("/");

  function handleNavigate() {
    if (!localStorage.getItem("user")) return navigate("/");
    navigate("/dashboard/video");
  }

  return (
    <>
      {path.find((value) => value === "blackfriday") ? (
        <img
          src={LogoWhite}
          className="mx-auto max-w-[220px] cursor-pointer"
          onClick={handleNavigate}
        />
      ) : (
        <img
          src={Logo}
          className="mx-auto max-w-[220px] cursor-pointer"
          onClick={handleNavigate}
        />
      )}
    </>
  );
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import { Box, Typography } from "@mui/material";
import api from "../../services/api";
import { Loading } from "../../assets/imgs";
import scrollToTop from "../../utils/scrollToTop";
import "../styles/moviesStyle.scss";
import { BACKGROUND_GRAY_700, TEXT_WHITE } from "../../constants/colors";
import { getSeries } from "../../utils";
import {
  getHeaders,
  loadAuth,
  loadSituation,
  getUserToken,
} from "../../utils/apiFunctions/auth";

export default function Movies() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [seriesData, setSeriesData] = useState<any>([]);
  const [episodesData, setEpisodesData] = useState<any>([]);

  useEffect(() => {
    loadAuth(handleAuth);
    loadSituation(handleSituation);
    getSeries(setSeriesData, getEpisodes);
  }, []);

  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("user");
    navigate("/login");
  }

  function handleSituation(data?: any) {
    if (data.situation !== "Pago") {
      navigate("/dashboard/myAccount");
    }
  }

  const getEpisodes = async (id: any) => {
    setLoading(true);
    api
      .get(`/videos/series/${id}`, getHeaders())
      .then((response) => {
        setEpisodesData(response.data);
      })
      .catch(() => console.log("Erro ao autenticar o usuário"))
      .finally(() => {
        setLoading(false);
      });
  };

  const renderSeries = seriesData.map((serie: any, index: any) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      const series = document.querySelectorAll(".button-image");
      const seriesContainer = document.querySelector(".series-container");

      for (var i = 0; i < series.length; i++) {
        series[i].classList.remove("selected-series");
      }
      const { value } = e.currentTarget;

      getEpisodes(value);

      seriesContainer?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      scrollToTop();

      e.currentTarget.classList.add("selected-series");
    };

    return (
      <button
        className={`button-image ${index === 0 ? "selected-series" : ""}`}
        key={serie.serie_id}
        value={serie.serie_id}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <img
          className="serie-image"
          src={`https://ruralbusiness.com.br/series/series/${serie.serie_capa}`}
        />
      </button>
    );
  });

  const renderEpisodes = episodesData.map((episode: any, index: any) => {
    return (
      <Box
        key={index}
        bgcolor={BACKGROUND_GRAY_700}
        borderRadius={"7px"}
        padding={"20px 0"}
        maxWidth={"700px"}
        width={"100%"}
        className="episode"
      >
        <iframe
          src={`https://player.rbvideo.com.br/index.html?id=${episode.cap_cdn}&thumb=${episode.cap_thumb}`}
          allowFullScreen
          className="aspect-video w-full bg-black"
        />
        <Typography
          color={TEXT_WHITE}
          fontSize={"1.2rem"}
          padding={"20px"}
          lineHeight={"1.2rem"}
          textAlign={"center"}
          textTransform={"uppercase"}
        >
          Capítulo {episode.cap_ordem} - {episode.cap_nome}
        </Typography>
      </Box>
    );
  });

  const LoadEpisodes = () => {
    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <Box
            alignItems={"center"}
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
            justifyContent={"center"}
            padding={"40px 0"}
            className="episodes-container"
          >
            {renderEpisodes}
          </Box>
        )}
      </>
    );
  };

  return (
    <Box position={"relative"}>
      <Box
        bgcolor={"rgba(0, 0, 0, 0.1)"}
        display={"flex"}
        justifyContent={"space-between"}
        overflow={"auto"}
        padding={"20px"}
        position={"sticky"}
        width={"100%"}
        top={0}
        gap={"20px"}
        sx={{
          backdropFilter: "blur(15px)",
          transition: "all 0.3s ease-in-out",
          scrollbarWidth: "thin",
        }}
        className="series-container"
      >
        {renderSeries}
      </Box>
      <LoadEpisodes />
    </Box>
  );
}

import { ErrorOutline, SupportAgent } from "@mui/icons-material";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import {
  BACKGROUND_GRAY_100,
  BACKGROUND_WHITE,
  BORDER_GRAY_100,
  BUTTON_LIGHT_GREEN,
  TEXT_GRAY_700,
} from "../../constants/colors";

export default function PaymentError() {
  function handleLoadScript() {
    import("../../services/script")
      .then(({ script }: any) => {
        script();
      })
      .catch((err) => {
        // Handle failure
      });
  }

  return (
    <>
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        alignContent={"center"}
        width={"100%"}
        mt={10}
        mb={10}
      >
        <Box
          bgcolor={BACKGROUND_WHITE}
          border={"1px solid"}
          borderColor={BORDER_GRAY_100}
          borderRadius={"10px"}
          display={"flex"}
          flexDirection={"column"}
          gap={5}
          p={4}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            gap={1}
          >
            <ErrorOutline color="error" sx={{ margin: "auto" }} />
            <Typography variant="h5" fontWeight={500} color="error">
              Erro na confirmação de pagamento
            </Typography>
          </Box>

          <Box display={"flex"} flexDirection={"column"} gap={3}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1}
            >
              <Typography
                fontSize={18}
                fontWeight={100}
                textAlign={"center"}
                color={TEXT_GRAY_700}
              >
                Entre em contato para mais infomações
              </Typography>
              <Button
                disableElevation
                onClick={handleLoadScript}
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: BUTTON_LIGHT_GREEN,
                  padding: "15px 30px",
                }}
              >
                <Typography variant="subtitle2" ml={1}>
                  Falar com atendente
                </Typography>
                <SupportAgent sx={{ ml: 1 }} />
              </Button>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                width={"100%"}
                height={"1px"}
                bgcolor={BACKGROUND_GRAY_100}
              />
              <Typography color={TEXT_GRAY_700} textAlign={"center"} px={1}>
                Ou
              </Typography>
              <Box
                width={"100%"}
                height={"1px"}
                bgcolor={BACKGROUND_GRAY_100}
              />
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Link
                underline="always"
                fontSize={18}
                textAlign={"center"}
                href="/dashboard/myAccount"
                sx={{
                  color: TEXT_GRAY_700,
                  textDecorationColor: TEXT_GRAY_700,
                }}
              >
                Ir para minha conta
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
}

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { NewsDto, NewsDtoHandler } from "../../dtos/NewsDto";
import { PlanUserDto, PlanUserDtoHandler } from "../../dtos/PlanUserDto";
import TabButton from "../../components/Plans/TabButton";
import { Loading } from "../../assets/imgs";
import {
  BACKGROUND_WHITE,
  BORDER_GRAY_100,
  BORDER_GRAY_50,
  TEXT_BLACK,
  TEXT_DARK_BLUE,
  TEXT_GRAY_600,
} from "../../constants/colors";
import { loadOneNews, newDate, userSeeAlert } from "../../utils";
import TextToSpeech from "../../components/TextToSpeech/TextToSpeech";

const token = localStorage.getItem("user");

export default function OneNews() {
  const navigate = useNavigate();
  const { news } = useParams();
  const [oneNews, setOneNews] = useState<NewsDto>(NewsDtoHandler.getDefault());
  const [loading, setLoading] = useState();
  const [isUserAllowed, setIsUserAllowed] = useState<Boolean>(false);
  const [planUser, setPlanUser] = useState<PlanUserDto>(
    PlanUserDtoHandler.getDefault()
  );

  //puxando dados da API
  useEffect(() => {
    userSeeAlert(setPlanUser, setIsUserAllowed);
    loadOneNews(news, token, setOneNews, setLoading);
  }, [news]);

  function handleNavigate() {
    return navigate(`/planos/${planUser.id}`);
  }

  const OneNewsContent = () => {
    return (
      <>
        <Box
          margin={"32px auto"}
          padding={"32px 0"}
          sx={{ filter: !isUserAllowed ? "blur(10px)" : "none" }}
        >
          <Typography
            color={TEXT_DARK_BLUE}
            fontSize={"44px"}
            fontWeight={600}
            textAlign={"center"}
          >
            {oneNews.title}
          </Typography>
          <Typography
            color={TEXT_GRAY_600}
            fontSize={"18px"}
            textAlign={"center"}
            marginBottom={"32px"}
          >
            {newDate(oneNews.date, "llll")}
          </Typography>
          <Typography
            color={TEXT_BLACK}
            fontSize={"22px"}
            fontWeight={500}
            textAlign={"center"}
            marginBottom={"32px"}
          >
            {oneNews.lead}
          </Typography>
          <div className="bg-gradient-to-tr from-blue-800 to-blue-500 p-4 text-white rounded-full md:rounded-br-none md:absolute px-4">
            <p className="text-center md:text-base font-medium">Ouça o alerta em áudio!</p>
          </div>
          <TextToSpeech html={oneNews.text} />
          <Typography fontSize={"20px"} color={TEXT_BLACK}>
            <Box
              id="terms-content"
              dangerouslySetInnerHTML={{ __html: oneNews.text }}
            />
          </Typography>
          <Link href="/dashboard/news" underline="none">
            <Typography
              color={TEXT_GRAY_600}
              textAlign={"end"}
              marginRight={"32px"}
              marginTop={"32px"}
            >
              &larr; Voltar
            </Typography>
          </Link>
        </Box>
      </>
    );
  };

  const BlockedContent = () => {
    return (
      <>
        <Box margin={"32px auto"} padding={"32px 0"}>
          <Typography
            color={TEXT_DARK_BLUE}
            fontSize={"44px"}
            fontWeight={600}
            marginBottom={"4px"}
            textAlign={"center"}
          >
            {oneNews.title}
          </Typography>
          <Box
            margin={"32px auto"}
            padding={"32px 0"}
            sx={{ filter: !isUserAllowed ? "blur(10px)" : "none" }}
          >
            <Typography
              color={TEXT_GRAY_600}
              fontSize={"18px"}
              textAlign={"center"}
              marginBottom={"32px"}
            >
              {newDate(oneNews.date, "llll")}
            </Typography>
            <Typography
              color={TEXT_BLACK}
              fontSize={"22px"}
              fontWeight={500}
              textAlign={"center"}
              marginBottom={"32px"}
            >
              {oneNews.lead}
            </Typography>
            <Typography fontSize={"20px"} color={TEXT_BLACK}>
              <Box
                id="terms-content"
                dangerouslySetInnerHTML={{
                  __html: oneNews.text.split("\n")[0],
                }}
              />
            </Typography>
          </Box>
          <Box
            bgcolor={"rgba(0, 0, 0, 0.4)"}
            borderRadius={"10px"}
            height={"100%"}
            left={0}
            position={"absolute"}
            paddingTop={"20%"}
            paddingX={"10px"}
            top={0}
            id="insert"
            overflow={"auto"}
            width={"100%"}
            zIndex={10}
          >
            <Box
              bgcolor={BACKGROUND_WHITE}
              border={"1px solid"}
              borderColor={BORDER_GRAY_100}
              borderRadius={"5px"}
              display={"flex"}
              flexDirection={"column"}
              margin={"auto"}
              maxWidth={"500px"}
            >
              <Box
                alignItems={"center"}
                borderBottom={"1px solid"}
                borderColor={BORDER_GRAY_50}
                borderRadius={"10px 10px 0 0"}
                display={"flex"}
                justifyContent={"center"}
                padding={"20px"}
                position={"relative"}
              >
                <Typography
                  color={TEXT_BLACK}
                  textAlign={"center"}
                  fontWeight={"bold"}
                  fontSize={"1rem"}
                  textTransform={"uppercase"}
                >
                  Oops! Parece que você não pode ver este Alerta... Assine o
                  Plano TOP+ para ter acesso aos Alertas diários da Rural
                  Business
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignSelf={"center"}
                padding={"40px"}
                rowGap={"10px"}
              >
                <TabButton handleNavigate={handleNavigate}>
                  Assinar Agora
                </TabButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  const RenderOneNews = () => {
    return (
      <>
        {!loading ? (
          !isUserAllowed ? (
            <BlockedContent />
          ) : (
            <OneNewsContent />
          )
        ) : (
          <Loading />
        )}
      </>
    );
  };

  return (
    <>
      <Box
        bgcolor={BACKGROUND_WHITE}
        border={"1px solid"}
        borderColor={BORDER_GRAY_100}
        borderRadius={"10px"}
        height={"fit-content"}
        padding={"30px"}
        position={"relative"}
        margin={"64px auto"}
        maxWidth={"1024px"}
      >
        <RenderOneNews />
      </Box>
    </>
  );
}

import { Box, Button, Typography } from "@mui/material";
import {
  BACKGROUND_DARK_BLUE,
  BACKGROUND_WHITE,
  TEXT_DARK_BLUE,
  TEXT_WHITE,
} from "../../constants/colors";

export default function DashboardButtonComponent({
  title,
  children,
  handleScreenWidth,
  toggleState,
  setToggleState,
  number,
  action,
}: any) {
  const dinamicStyles = {
    bgColor: toggleState === number ? BACKGROUND_DARK_BLUE : BACKGROUND_WHITE,
    color: toggleState === number ? TEXT_WHITE : TEXT_DARK_BLUE,
    fontWeight: toggleState === number ? 600 : 500,
  };

  function actionHandler() {
    if (action) {
      action();
    }
    setToggleState(number);
    handleScreenWidth(number);
  }

  return (
    <Button
      onClick={() => {
        actionHandler();
      }}
      sx={{
        alignItems: "center",
        backgroundColor: dinamicStyles.bgColor,
        border: "none",
        borderRadius: "10px",
        display: "flex",
        padding: "12px",
        justifyContent: "flex-start",
        fontSize: "18px",
        gap: "10px",
      }}
    >
      <Box
        alignItems={"center"}
        color={dinamicStyles.color}
        display={"flex"}
        justifyContent={"center"}
      >
        {children}
      </Box>
      <Typography
        color={dinamicStyles.color}
        fontSize={"1rem"}
        fontWeight={dinamicStyles.fontWeight}
        textAlign={"left"}
      >
        {title}
      </Typography>
    </Button>
  );
}

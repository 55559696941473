import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import api from "../../../../services/api";
import { useRef, useState } from "react";
import { UserDto } from "../../../../dtos";
import { TEXT_RED } from "../../../../constants/colors";
import MyAccountFormButton from "../../../Buttons/MyAccountFormButton";

interface UserPasswordProps {
  user: UserDto;
  onChange: (user: UserDto) => void;
  onSave: (user: UserDto) => void;
  loading: boolean;
}

export default function UserPassword(props: UserPasswordProps) {
  const [oldPassword, setOldPassword] = useState({
    atualPassword: "",
    newPassword: "",
    showPassword: false,
  });
  const [validationOldPassword, setValidationOldPassword] = useState(true);
  const validationOldPasswordRef = useRef(validationOldPassword);
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
    field: "",
  });
  const [open, setOpen] = useState(false);

  async function loadUser(body: any) {
    await api
      .post("/clientes/login", body)
      .then((response) => {
        const statusPassword = response.data.error;
        validationOldPasswordRef.current = statusPassword;
        setValidationOldPassword(statusPassword);
      })
      .catch((e) => console.log(e));
  }

  function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  }

  //mudando os valores antigo
  function handleChange(key: string, value: any) {
    props.onChange({ ...props.user, [key]: value });
  }

  //mudando os valores do input do password atual
  function handleChangeOldPassword(key: string, value: any) {
    setOldPassword({ ...oldPassword, [key]: value });
  }

  //mudando o status do textfield para mostrar a senha
  const handleClickShowPassword = () => {
    setOldPassword({
      ...oldPassword,
      showPassword: !oldPassword.showPassword,
    });
  };

  //mudando os icon visibly
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  //validando o erro do campo
  function handleValidationOldPassword(auth: any) {
    if (auth)
      return setStatus({
        type: "error",
        mensagem: "A senha precisa ser igual a atual",
        field: "atualPassword",
      });

    return true;
  }

  //validando dados do user
  function handleValidate() {
    if (!oldPassword.atualPassword)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo senha!",
        field: "oldPassword",
      });
    if (!props.user.password)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo senha!",
        field: "password",
      });
    if (props.user.password.length < 8)
      return setStatus({
        type: "error",
        mensagem: "Necessário que a senha tenha no minimo 8 caracteres!",
        field: "password",
      });
    if (!oldPassword.newPassword)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo confirmar senha!",
        field: "newPassword",
      });
    if (props.user.password !== oldPassword.newPassword) {
      return setStatus({
        type: "error",
        mensagem: "Necessário que a senha seja igual!",
        field: "password",
      });
    }

    return true;
  }

  //verificando e manipulando password do user
  async function handlePassword() {
    const user = props.user.cpf;
    const password = oldPassword.atualPassword;
    const body = { user, password };

    await loadUser(body);

    if (!handleValidationOldPassword(validationOldPasswordRef.current)) return;
  }

  function handleSubmit(event: any) {
    event && event.preventDefault();
    
    if (!handleValidate()) return;

    if (!handleValidationOldPassword(validationOldPasswordRef.current)) return;

    setOpen(true);
    props.onSave(props.user);
  }

  return (
    <>
      <form className="myAccountForm" onSubmit={handleSubmit}>
        {status.type === "error" ? (
          <p style={{ color: TEXT_RED, marginLeft: "38px", marginTop: "8px" }}>
            {status.mensagem}
          </p>
        ) : (
          ""
        )}
        <TextField
          id="campo"
          className="label"
          label="Senha antiga"
          size="small"
          color={status.field === "atualPassword" ? "warning" : "success"}
          focused={status.field === "atualPassword" ? true : false}
          type={oldPassword.showPassword ? "text" : "password"}
          value={oldPassword.atualPassword}
          InputLabelProps={{ shrink: true }}
          onChange={(e) =>
            handleChangeOldPassword("atualPassword", e.target.value)
          }
          autoFocus
          style={{ width: "100%" }}
          onBlur={handlePassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {oldPassword.showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          id="campo"
          className="label"
          label="Senha nova"
          size="small"
          color={status.field === "newPassword" ? "warning" : "success"}
          focused={status.field === "newPassword" ? true : false}
          type={oldPassword.showPassword ? "text" : "password"}
          InputLabelProps={{ shrink: true }}
          style={{ width: "100%" }}
          onChange={(e) =>
            handleChangeOldPassword("newPassword", e.target.value)
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {oldPassword.showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          id="campo"
          className="label"
          label="Confirma senha nova"
          size="small"
          color={status.field === "password" ? "warning" : "success"}
          focused={status.field === "password" ? true : false}
          type={oldPassword.showPassword ? "text" : "password"}
          value={props.user.password}
          InputLabelProps={{ shrink: true }}
          style={{ width: "100%" }}
          onChange={(e) => handleChange("password", e.target.value || "")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {oldPassword.showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <MyAccountFormButton
          title={"Salvar"}
          type={"submit"}
          disabled={props.loading}
        />
      </form>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Senha alterado com sucesso.
        </Alert>
      </Snackbar>
    </>
  );
}

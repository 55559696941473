import { PlanUserDtoHandler } from "../../../dtos/PlanUserDto";
import api from "../../../services/api";
import getHeaders from "./getHeaders";

export default async function loadUserPlan(setPlanUser) {
  await api
    .get("/contratos/situacao/", getHeaders())
    .then((response) => {
      const newPlanUser = PlanUserDtoHandler.getByDataBase(response.data[0]);
      setPlanUser(newPlanUser);
    })
    .catch(() => console.log("Erro ao carregar planos do usuário."));
}

export interface UserContDto {
  planId: string;
  clientId: string;
  marketplace: string;
  payment: number;
  publicity: number;
  note: string;
  affiliated: string;
}

export class UserContDtoHandler  {
  public static getDefault(data: any = {}): UserContDto {
    return {
      planId: data.planId || '',
      clientId: data.clientId || '',
      marketplace: data.marketplace || '',
      payment: data.payment || '',
      publicity: data.publicity || 0,
      note: data.note || '',
      affiliated: data.affiliated || '',
    };
  }

  public static toDatabase(user: UserContDto): any {
    return {
      cont_plano_id: user.planId,
      cont_cliente_id: user.clientId,
      cont_mercado: user.marketplace,
      cont_forma_pgto: user.payment,
      cont_publicidade: user.publicity,
      cont_observacao: user.note,
      cont_afiliado: user.affiliated,
    };
  }
}

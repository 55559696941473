import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import api from "../../services/api";
import "./styles.scss";

interface BoletoProps {
  value: number;
  clientId: string;
  paymentMethod: number;
  planId: string;
  marketSelect: string;
}

export default function Boleto(props: BoletoProps) {
  const [contId, setContId] = useState();
  const contIdRef = useRef(contId);
  const [titleId, setTitleId] = useState();
  const titleIdRef = useRef(titleId);

  let navigate = useNavigate();

  async function loadContract() {
    const cont_plano_id = props.planId;
    const cont_cliente_id = props.clientId;
    const cont_forma_pgto = props.paymentMethod;
    const cont_mercado = props.marketSelect;
    const cont_publicidade = Number(localStorage.getItem("ad"));
    const body = {
      cont_plano_id,
      cont_cliente_id,
      cont_forma_pgto,
      cont_mercado,
      cont_publicidade,
    };

    await api
      .post("/contratos/adiciona", body)
      .then((response) => {
        const newContId = response.data.cont_id;
        contIdRef.current = newContId;
        const newTitle = response.data.titulo_id;
        titleIdRef.current = newTitle;
        setContId(newContId);
        setTitleId(newTitle);
      })
      .catch(() => console.log("Erro ao criar o contrato"));
  }

  async function handlerClick() {
    if (props.marketSelect === "null")
      return window.alert("Necessario selecionar o mercado");

    await loadContract();

    navigate(`/boleto/${contIdRef.current}/${titleIdRef.current}`);
  }

  return (
    <>
      <div className="container__pix">
        <div className="content__pix">
          <p className="text__pix" style={{ width: "100%" }}>
            Você precisa realizar o pagamento para finalizar a sua compra.
          </p>
          <Button
            variant="contained"
            fullWidth
            color={"success"}
            type="submit"
            size="large"
            onClick={handlerClick}
            disableElevation
            sx={{
              mt: 1,
              background: "#7EC057",
              ":hover": { bgcolor: "#6faa4e" },
            }}
          >
            Pagar no boleto
          </Button>
        </div>
      </div>
    </>
  );
}

import Plan from '../../pages/Plans/Plans';

export default function PlansComponent() {
  return (
    <section className="flex w-full flex-col items-center justify-center bg-[#eeeeee] pb-16" id="plano">
      <div className="flex flex-col items-center justify-center gap-3 px-16 pt-16">
        {/* <h2 className="bg-gradient-to-r from-green-500 to-yellow-400 bg-clip-text font-helvetica text-3xl font-black uppercase text-transparent lg:text-4xl">
          Planos
        </h2> */}
        <p className="mb-10 text-center font-helvetica text-2xl font-black uppercase text-zinc-800 lg:text-2xl">
          Escolha o seu plano <br /> e aumente a sua capacidade de lucro no agronegócio
        </p>
      </div>
      <Plan variant={process.env.REACT_APP_PLANS_VARIANT} />
    </section>
  );
}

import ContentLoader from "react-content-loader"

export default function ResumLoading() {
    return (
        <>
            <ContentLoader 
                speed={2}
                width={385}
                height={250}
                viewBox="0 0 385 250"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="-2" y="19" rx="3" ry="3" width="250" height="15" /> 
                <rect x="0" y="53" rx="3" ry="3" width="250" height="15" /> 
                <rect x="2" y="78" rx="3" ry="3" width="75" height="15" /> 
                <rect x="0" y="122" rx="3" ry="3" width="110" height="15" /> 
                <rect x="134" y="121" rx="3" ry="3" width="110" height="15" />
            </ContentLoader>
        </>
    )
}
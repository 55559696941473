import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataLayout } from "../../../layouts";
import { UserDataComponent } from "..";
import { PlanUserDto, PlanUserDtoHandler } from "../../../dtos/PlanUserDto";
import MyAccountFormButton from "../../Buttons/MyAccountFormButton";
import api from "../../../services/api";

export default function RenewPlan() {
  const navigate = useNavigate();

  const [planUser, setPlanUser] = useState<PlanUserDto>(
    PlanUserDtoHandler.getDefault()
  );

  //puxando dados da API
  useEffect(() => {
    loadPlanUser();
  }, []);

  function loadPlanUser() {
    const token = localStorage.getItem("user");

    //Tirando aspa da string do token
    const tokenJWT = token?.substring(1, token?.length - 1);

    //Headers da API
    const headers = {
      headers: {
        "x-access-token": `${tokenJWT}`,
      },
    };

    api
      .get("/contratos/situacao/", headers)
      .then((response) => {
        setPlanUser(PlanUserDtoHandler.getByDataBase(response.data[0]));
      })
      .catch(() => console.log("Erro ao carregar planos do usuário."));
  }

  return (
    <>
      <UserDataLayout title={"Renovar Assinatura"}>
        <UserDataComponent />
        <MyAccountFormButton
          title={"Renovar"}
          useNavigate={true}
          navigate={navigate}
          route={`/dashboard/renewPlan/${planUser.id}/${planUser.contract}/${planUser.title}`}
        />
      </UserDataLayout>
    </>
  );
}

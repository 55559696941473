import { Grid } from "@mui/material";
import ContentLoader from "react-content-loader";
import {
  BACKGROUND_GRAY_100,
  BACKGROUND_GRAY_50,
} from "../../../constants/colors";

export default function VideoLoading() {
  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={2.8} ml={1} mb={1} p={1}>
        <ContentLoader
          speed={3}
          height={"270"}
          width={"280"}
          backgroundColor={BACKGROUND_GRAY_100}
          foregroundColor={BACKGROUND_GRAY_50}
          display={"block"}
          style={{
            width: "fitContent",
            margin: "auto",
          }}
        >
          <rect y="0" width="280" height="180" />
          <rect y="190" width="280" height="20" />
          <rect y="215" width="200" height="16" />
          <rect y="236" width="185" height="16" />
        </ContentLoader>
      </Grid>
    </>
  );
}

import { Alert, Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import api from "../../services/api";
import { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useNavigate, useParams } from "react-router-dom";
import {
  BACKGROUND_WHITE,
  BUTTON_DARK_GREEN,
  BUTTON_LIGHT_GREEN,
} from "../../constants/colors";

export default function PaymentPix() {
  const navigate = useNavigate();
  const { value, planId, clientId, paymentMethod, marketSelect } = useParams();
  const [open, setOpen] = useState(false);
  const [userCont, setUserCont] = useState();
  const [qrCode, setQrCode] = useState();
  const [paymentId, setPaymentId] = useState();
  const [contId, setContId] = useState();
  const contIdRef = useRef(contId);
  const [titleId, setTitleId] = useState();
  const titleIdRef = useRef(titleId);

  //puxando dados da API
  useEffect(() => {
    const axiosPosts = async () => {
      await loadContract();
      await loadPix();
    };
    axiosPosts();
  }, []);

  //GERANDO O CONTRATO COM OS CLIENTID, PLANOID, FORMA DE PAGAMENTO (VINDO DA ROTA)
  async function loadContract() {
    const cont_plano_id = planId;
    const cont_cliente_id = clientId;
    const cont_forma_pgto = paymentMethod;
    let cont_mercado = marketSelect;
    const cont_publicidade = Number(localStorage.getItem("ad"));
    const body = {
      cont_plano_id,
      cont_cliente_id,
      cont_forma_pgto,
      cont_mercado,
      cont_publicidade,
    };

    await api
      .post("/contratos/adiciona", body)
      .then((response) => {
        const newContId = response.data.cont_id;
        contIdRef.current = newContId;
        const newTitle = response.data.titulo_id;
        titleIdRef.current = newTitle;
        setContId(newContId);
        setTitleId(newTitle);
      })
      .catch(() => console.log("Erro ao criar o contrato"));
  }

  //GERANDO O PIX COM O CONT_ID E TITULO_ID
  async function loadPix() {
    const cont_id = String(contIdRef.current);
    const titulo_id = String(titleIdRef.current);
    const body = { cont_id, titulo_id };

    await api
      .post("/cobrancas/pix", body)
      .then((response) => {
        setUserCont(response.data.Customer.Name);
        setQrCode(response.data.Payment.QrCodeString);
        setPaymentId(response.data.Payment.PaymentId);
      })
      .catch(() => console.log("Erro ao carregar pix do usuário"));
  }

  function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  function handleSubmit() {
    navigator.clipboard.writeText(`${qrCode}`);

    setOpen(true);
  }

  async function loadPaymentId() {
    api
      .get(
        `https://api.ruralbusiness.com.br/rbvideo/cobrancas/verificaPix/${paymentId}`
      )
      .then((res) => console.log(res))
      .catch();
  }

  //transformando o preco para REAL (PREÇO TA VINDO DA ROTA)
  function getPrice(total: number, currency = "R$"): string {
    let amount = total;
    const integers = Math.floor(amount / 100);
    const cents = amount % 100;

    return `${currency} ${integers},${cents}`;
  }

  async function handlerClick() {
    await loadPaymentId();

    navigate(`/dashboard/video`);
  }

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        sx={{ backgroundColor: BACKGROUND_WHITE, borderRadius: "15px" }}
        maxWidth={360}
        ml={"auto"}
        mr={"auto"}
        mt={6}
        mb={6}
      >
        <Grid item xs={12} p={2}>
          <Typography
            variant="h4"
            fontWeight={500}
            textAlign="center"
            color={"cadetblue"}
            textTransform="uppercase"
            mb={4}
          >
            Pagamento Pix
          </Typography>
          <Box display={"flex"} justifyContent={"space-between"} mt={2}>
            <Typography variant="body1" fontWeight={500}>
              Usuário:
            </Typography>
            <Typography variant="body1" fontWeight={500} color={"cadetblue"}>
              {userCont}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} mt={2}>
            <Typography variant="body1" fontWeight={500}>
              Número do contrato:
            </Typography>
            <Typography variant="body1" fontWeight={500} color={"cadetblue"}>
              {contId}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} mt={2}>
            <Typography variant="body1" fontWeight={500}>
              Valor:
            </Typography>
            <Typography variant="body1" fontWeight={500} color={"cadetblue"}>
              {getPrice(Number(value))}
            </Typography>
          </Box>

          <Box display={"flex"} justifyContent={"center"} mt={2} mb={2}>
            <QRCode className="img_pix" value={`${qrCode}`} />
          </Box>
          <Typography variant="body1" fontWeight={500} textAlign={"center"}>
            Realize o pagamento escaneando o QR Code acima, ou pague copiando o
            código.
          </Typography>
        </Grid>
        <Button
          variant="outlined"
          color="primary"
          sx={{ borderRadius: "25px", mb: 2 }}
          disableElevation
          onClick={handleSubmit}
        >
          Copiar código Pix
        </Button>
        <Button
          variant="contained"
          color="success"
          sx={{
            borderRadius: "15px",
            width: "94%",
            mb: 4,
            background: BUTTON_LIGHT_GREEN,
            ":hover": { bgcolor: BUTTON_DARK_GREEN },
          }}
          disableElevation
          onClick={handlerClick}
        >
          Pagamento realizado
        </Button>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Serial pix copiado !!
        </Alert>
      </Snackbar>
    </>
  );
}

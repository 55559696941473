import { Button } from "@mui/material";

export default function ButtonHandler({ title, color, action, disabled }: any) {
  return (
    <Button
      disabled={disabled}
      disableElevation
      onClick={action}
      sx={{
        backgroundColor: color,
        height: "50px",
        width: "100%",
      }}
      variant="contained"
    >
      {title}
    </Button>
  );
}

/*const script = () => {(function (o, c, t, a, d, e, s, k) {
  o.octadesk = o.octadesk || {};
  o.octadesk.chatOptions = {
    subDomain: a,
    showButton: d,
    openOnMessage: e,
    hide: s
  };
  var bd = c.getElementsByTagName("body")[0];
  var sc = c.createElement("script");
  sc.async = 1;
  sc.src = t;
  bd.appendChild(sc);
})(window, document, 'https://cdn.octadesk.com/embed.js', 'o133897-84c',  'false', 'true', 'false')

  return window.octadesk.chat.toggle();
};*/
const script = () => {
return window.crmPlugin('runChatShow');
};

export {script};

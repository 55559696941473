import { Box, Button } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { initMercadoPago, Payment, StatusScreen } from "@mercadopago/sdk-react";
import {
  InitiateCheckout,
  Purchase,
} from "../../../utils/facebookPixel/facebookPixelEvent";
import api from "../../../services/api";
import axios from "axios";
import scrollToTop from "../../../utils/scrollToTop";
import PaymentMPComponent from "../../../components/PaymentMp/PaymentMPComponent";
import { BUTTON_LIGHT_GREEN } from "../../../constants/colors";
import { GoogleAdsConversion } from "../../../utils/googleAds/googleAdsEvent";
import { getHeaders } from "../../../utils/apiFunctions/auth";

initMercadoPago(process.env.REACT_APP_PUBLIC_KEY_MP || "", {
  locale: "pt-BR",
});

export default function PagamentoMp() {
  const { planId, planValue, mercado, clientId } = useParams();
  const navigate = useNavigate();
  const [marketSelect, setMarketSelect] = useState<Number>(Number(mercado));
  const [errors, setErrors] = useState<boolean>(false);
  const [errorPayment, setErrorPayment] = useState<boolean>(false);
  const [statusButtonPayment, setStatusButtonPayment] =
    useState<boolean>(false);
  const [statusButtonHome, setStatusButtonHome] = useState<boolean>(false);
  const [donePayment, setDonePayment] = useState<boolean>(false);
  const [idPayment, setIdPayment] = useState<string>();
  const userCpf = useRef();
  const userEmail = useRef();
  const planValor = useRef();
  const planNome = useRef();
  const planDesc = useRef();
  const userNome = useRef("");
  const userRua = useRef();
  const userEstado = useRef();
  const userCep = useRef();
  const userBairro = useRef();
  const userCidade = useRef();
  const userNumRua = useRef();
  const [dataRequest, setDataRequest] = useState(false);
  const [isPlanActive, setIsPlanActive] = useState(true);

  useEffect(() => {
    loadUser();
    InitiateCheckout(); // Pixel facebook
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [
    errors,
    errorPayment,
    statusButtonPayment,
    statusButtonHome,
    dataRequest,
  ]);

  function loadUser() {
    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_RB + "/clientes/cadastro",
          getHeaders()
        ),
        axios.get(process.env.REACT_APP_API_RB + `/planos/id/${planId}`),
      ])
      .then(
        axios.spread((response, response2) => {
          const newGetUser = response.data[0];
          userCpf.current = newGetUser.cli_cpf_cnpj;
          userEmail.current = newGetUser.cli_email;
          userNome.current = newGetUser.cli_nome;
          userCep.current = newGetUser.cli_cep;
          userEstado.current = newGetUser.cli_estado;
          userCidade.current = newGetUser.cli_cidade;
          userBairro.current = newGetUser.cli_bairro;
          userRua.current = newGetUser.cli_rua;
          userNumRua.current = newGetUser.cli_numero;
          const newPlanDetails = response2.data[0];
          setDataRequest(true);
          planValor.current = newPlanDetails.plan_valor;
        })
      )
      .catch(() =>
        console.log("Erro ao buscar o detalhes do plano e dados do cliente")
      );
  }

  function loadInitialization() {
    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_RB + "/clientes/cadastro",
          getHeaders()
        ),
        axios.get(process.env.REACT_APP_API_RB + `/planos/id/${planId}`),
      ])
      .then(
        axios.spread((response, response2) => {
          const newGetUser = response.data[0];
          userCpf.current = newGetUser.cli_cpf_cnpj;
          userEmail.current = newGetUser.cli_email;
          userNome.current = newGetUser.cli_nome;
          userCep.current = newGetUser.cli_cep;
          userEstado.current = newGetUser.cli_estado;
          userCidade.current = newGetUser.cli_cidade;
          userBairro.current = newGetUser.cli_bairro;
          userRua.current = newGetUser.cli_rua;
          userNumRua.current = newGetUser.cli_numero;
          const newPlanDetails = response2.data[0];
          planValor.current = newPlanDetails.plan_valor;
          planNome.current = newPlanDetails.plan_nome;
          planDesc.current = newPlanDetails.plan_desc;
          setIsPlanActive(newPlanDetails.plan_active)
        })
      )
      .catch(() =>
        console.log("Erro ao buscar o detalhes do plano e dados do cliente")
      );

    const nome = userNome.current!.split(" ", 1).join("");
    const sobrenome = userNome.current!.split(" ").pop();
    const price = Number(planValor.current!) / 100;
    const initialization = {
      amount: price, // valor total a ser pago
      //preferenceId: 'RV123',
      payer: {
        email: userEmail.current,
        firstName: nome,
        lastName: sobrenome,
        identification: {
          type: "CPF", //CpfOuCnpj(userCpf.current),
          number: userCpf.current || "0",
        },
        address: {
          zipCode: userCep.current || "",
          federalUnit: userEstado.current || "",
          city: userCidade.current,
          neighborhood: userBairro.current,
          streetName: userRua.current,
          streetNumber: userNumRua.current,
          complement: "",
        },
      },
    };
    return initialization;
  }

  function loadCustomization() {
    const customization = {
      paymentMethods: {
        creditCard: "all",
        debitCard: "all",
        bankTransfer: ["pix"],
        ticket: "all",
      },
    };
    return customization;
  }

  function handleChangeMarket(market: any) {
    setMarketSelect(market);
  }

  async function loadContract(param: any, body: any) {
    await api
      .post("/contratos/adiciona", body)
      .then((response) => {
        loadPayment(param, response.data);
      })
      .catch(() => console.log("Erro ao criar o contrato"));
  }

  async function loadPayment(param: any, contract: any) {
    const body = { ...param, contract };

    await api
      .post("/cobrancas/payment-mp/", body)
      .then((response) => {
        setErrorPayment(true);
        handleStatusComponents(response.data);
      })
      .catch((e) => console.log(e));
  }

  async function handleDataContract(param: any) {
    const cont_plano_id = planId;
    const cont_cliente_id = clientId;
    const cont_mercado = String(marketSelect);
    const cont_publicidade = Number(localStorage.getItem("ad"));

    if (param.paymentType === "credit_card") {
      const cont_forma_pgto = 2;
      const body = {
        cont_plano_id,
        cont_cliente_id,
        cont_forma_pgto,
        cont_mercado,
        cont_publicidade,
      };
      return loadContract(param, body);
    }
    if (param.paymentType === "debit_card") {
      const cont_forma_pgto = 4;
      const body = {
        cont_plano_id,
        cont_cliente_id,
        cont_forma_pgto,
        cont_mercado,
        cont_publicidade,
      };
      return loadContract(param, body);
    }
    if (param.paymentType === "ticket") {
      const cont_forma_pgto = 1;
      const body = {
        cont_plano_id,
        cont_cliente_id,
        cont_forma_pgto,
        cont_mercado,
        cont_publicidade,
      };
      return loadContract(param, body);
    }
    if (param.paymentType === "bank_transfer") {
      const cont_forma_pgto = 3;
      const body = {
        cont_plano_id,
        cont_cliente_id,
        cont_forma_pgto,
        cont_mercado,
        cont_publicidade,
      };
      return loadContract(param, body);
    }
    const cont_forma_pgto = 0;
    const body = {
      cont_plano_id,
      cont_cliente_id,
      cont_forma_pgto,
      cont_mercado,
      cont_publicidade,
    };
    return loadContract(param, body);
  }

  function handleStatusComponents(status: any) {
    setIdPayment(`${status.id}`);
    setDataRequest(false);
    if (
      status.status === "rejected" ||
      status.status === "cancelled" ||
      status.status === "refunded" ||
      status.status === "charged_back"
    ) {
      return setStatusButtonPayment(true);
    }
    Purchase(planValor.current); // Pixel facebook
    GoogleAdsConversion(planValor.current); // Google ADS
    setStatusButtonPayment(false);
    setStatusButtonHome(true);
  }

  function handleButton() {
    setDataRequest(true);
    setStatusButtonPayment(false);
    setErrorPayment(false);
  }

  const memoizedPayment = useMemo(() =>
    (dataRequest) && ( // "&& isPlanActive" <- removido pois estava gerando bug no plano básico 
      <Payment
        initialization={loadInitialization()}
        customization={loadCustomization()}
        onSubmit={async (param) => {
          if (marketSelect === 0) {
            return setErrors(true);
          }
          handleDataContract(param);
          setDonePayment(true);
        }}
      />
    )
    , [marketSelect, dataRequest])

  return (
    <>
      <PaymentMPComponent
        planID={planId}
        marketID={mercado}
        showMarketOnID={"0"}
        errors={errors}
        handleChangeMarket={handleChangeMarket}
        donePayment={donePayment}
      >
        {dataRequest && memoizedPayment}
        {errorPayment && (
          <Box m={2}>
            <StatusScreen initialization={{ paymentId: `${idPayment}` }} />
          </Box>
        )}
        {statusButtonHome && (
          <Button
            variant="contained"
            disableElevation
            onClick={() => navigate("/pagamento/afterPayment")}
            sx={{
              backgroundColor: BUTTON_LIGHT_GREEN,
              height: "50px",
              marginX: 2,
              marginBottom: 2,
            }}
          >
            Ir para as boas vindas
          </Button>
        )}
        {statusButtonPayment && (
          <Button
            variant="contained"
            disableElevation
            color="error"
            onClick={handleButton}
            sx={{
              height: "50px",
              marginX: 2,
              marginBottom: 2,
            }}
          >
            Tentar novamente
          </Button>
        )}
      </PaymentMPComponent>
    </>
  );
}

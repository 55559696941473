import { useEffect, useRef } from "react";
import "../../pages/styles/myAccountStyle.scss";
import { Box } from "@mui/material";
import { BACKGROUND_WHITE, BORDER_GRAY_100 } from "../../constants/colors";

function RightContentDashboardLayout({ children, toggleState }: any) {
  const rightContainerRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    rightContainerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  }, [rightContainerRef, toggleState]);

  return (
    <>
      <Box
        bgcolor={BACKGROUND_WHITE}
        border={"1px solid"}
        borderColor={BORDER_GRAY_100}
        borderRadius={"10px"}
        display={"flex"}
        flexDirection={"column"}
        flexGrow={1}
        gap={"20px"}
        height={"fit-content"}
        minWidth={"300px"}
        ref={rightContainerRef}
        p={"20px"}
      >
        {children}
      </Box>
    </>
  );
}

export default RightContentDashboardLayout;

import { Box, TextField, Typography, useMediaQuery } from "@mui/material";
import {
  BACKGROUND_WHITE,
  BORDER_GRAY_100,
  BORDER_GRAY_600,
  TEXT_GRAY_700,
} from "../../constants/colors";

export default function Address({
  props,
  status,
  handleChange,
  disableAddressInput,
  showOnMobile,
}: any) {
  const addressQuery = useMediaQuery("(max-width:900px)");
  const AddressDisplay = () => {
    if (showOnMobile && addressQuery) {
      return "block";
    }
    if (!showOnMobile && !addressQuery) {
      return "block";
    }
    return "none";
  };

  return (
    <Box
      display={AddressDisplay()}
      maxWidth={addressQuery ? "auto" : "380px"}
      bgcolor={BACKGROUND_WHITE}
      height={"fit-content"}
      border={"1px solid"}
      borderColor={BORDER_GRAY_100}
      borderRadius={"10px"}
      flexDirection={"column"}
      width={"100%"}
    >
      <Box pt={"15px"}>
        <Typography
          color={TEXT_GRAY_700}
          fontSize={"1.1rem"}
          fontWeight={500}
          textAlign={"center"}
          variant="h2"
        >
          Endereço
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        p={"15px"}
        width={"100%"}
        gap={"20px"}
      >
        <Box display={"flex"} columnGap={"5px"}>
          <TextField
            disabled
            InputLabelProps={{
              shrink: true,
              style: { color: TEXT_GRAY_700 },
            }}
            label="CEP"
            size="small"
            sx={{
              width: "100%",
              "& .MuiInputBase-root.Mui-disabled": {
                "& > fieldset": {
                  borderColor: BORDER_GRAY_100,
                },
              },
            }}
            value={props.user.address.cep}
          />
          <TextField
            color={
              status.field === "address.numberAddress" ? "warning" : "success"
            }
            focused={status.field === "address.numberAddress" ? true : false}
            InputLabelProps={{
              shrink: true,
              style: { color: TEXT_GRAY_700 },
            }}
            label="Número"
            onChange={(e) => {
              handleChange("address.numberAddress", e.target.value);
            }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            size="small"
            sx={{
              width: "30%",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: BORDER_GRAY_600,
              },
            }}
            type={"tel"}
            value={props.user.address.numberAddress}
          />
        </Box>
        <TextField
          color={status.field === "address.address" ? "warning" : "success"}
          disabled={disableAddressInput.address}
          focused={status.field === "address.address" ? true : false}
          InputLabelProps={{
            shrink: true,
            style: { color: TEXT_GRAY_700 },
          }}
          label="Rua, Avenida, Logradouro"
          onChange={(e) => {
            handleChange("address.address", e.target.value);
          }}
          size="small"
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: BORDER_GRAY_600,
            },
            "& .MuiInputBase-root.Mui-disabled": {
              "& > fieldset": {
                borderColor: BORDER_GRAY_100,
              },
            },
          }}
          value={props.user.address?.address}
        />
        <TextField
          color={
            status.field === "address.neighborhood" ? "warning" : "success"
          }
          focused={status.field === "address.neighborhood" ? true : false}
          disabled={disableAddressInput.neighborhood}
          InputLabelProps={{
            shrink: true,
            style: { color: TEXT_GRAY_700 },
          }}
          label="Informe seu bairro"
          onChange={(e) => {
            handleChange("address.neighborhood", e.target.value);
          }}
          size="small"
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: BORDER_GRAY_600,
            },
            "& .MuiInputBase-root.Mui-disabled": {
              "& > fieldset": {
                borderColor: BORDER_GRAY_100,
              },
            },
          }}
          value={props.user.address.neighborhood}
        />
        <Box display={"flex"} columnGap={"5px"}>
          <TextField
            color={status.field === "address.state" ? "warning" : "success"}
            focused={status.field === "address.state" ? true : false}
            disabled={disableAddressInput.state}
            InputLabelProps={{
              shrink: true,
              style: { color: TEXT_GRAY_700 },
            }}
            inputProps={{ maxLength: 2 }}
            label="UF"
            onKeyPress={(event) => {
              if (/[^a-zA-Z]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              handleChange("address.state", e.target.value);
            }}
            size="small"
            sx={{
              width: "30%",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: BORDER_GRAY_600,
              },
              "& .MuiInputBase-root.Mui-disabled": {
                "& > fieldset": {
                  borderColor: BORDER_GRAY_100,
                },
              },
            }}
            value={props.user.address.state}
          />
          <TextField
            color={status.field === "address.city" ? "warning" : "success"}
            focused={status.field === "address.city" ? true : false}
            disabled={disableAddressInput.city}
            label="Cidade"
            InputLabelProps={{
              shrink: true,
              style: { color: TEXT_GRAY_700 },
            }}
            onChange={(e) => {
              handleChange("address.city", e.target.value);
            }}
            size="small"
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: BORDER_GRAY_600,
              },
              "& .MuiInputBase-root.Mui-disabled": {
                "& > fieldset": {
                  borderColor: BORDER_GRAY_100,
                },
              },
            }}
            value={props.user.address.city}
          />
        </Box>
      </Box>
    </Box>
  );
}

import { SituationUserDtoHandler } from "../../../dtos/SituationUserDto";
import api from "../../../services/api";
import getHeaders from "./getHeaders";

export default async function loadSituation(handleSituation) {
  await api
    .get("/contratos/situacao/", getHeaders())
    .then((response) => {
      handleSituation(SituationUserDtoHandler.getByDatabase(response.data[0]));
      // console.log("Sucesso ao buscar contrato do usuário");
    })
    .catch(() => console.log("Erro ao buscar contrato do usuário"));
}

import { Typography } from "@mui/material";
import { ContractsLayout } from "../../layouts";

export default function UseLicenseAgreement() {
  return (
    <>
      <ContractsLayout title="Contrato de licença e uso Rural Business" gap={2}>
        <Typography>
          Ao clicar em "eu concordo com os termos deste contrato", o USUÁRIO (o
          "USUÁRIO") declara ter cuidadosamente examinado e incondicionalmente
          concordado com todos os termos e condições constantes do presente
          contrato, passando a ter direito de uso do software e o conteúdo
          produzido pela rural business, conforme contratação com a rural
          network ltda (a RN), com sede na capital do estado de mato grosso do
          sul, na avenida 1º de maio 620, inscrita no CNPJ/MF sob o nº
          04.033.423/0001-69, nos seguintes termos e condições:
        </Typography>
        <Typography>
          A RN fornecerá ao USUÁRIO através de assinatura e/ou provimento (s) de
          acessos de serviço (s) indicado(s) neste contrato, sem exclusividade
          sendo assim uma cessão temporária de direito de uso dos softwares,
          permanecendo a RN como plena detentora de propriedade industrial e
          intelectual, e dos direitos autorais (copyright), observando as
          cláusulas especificadas abaixo:
        </Typography>
        <Typography fontSize={"1rem"}>
          <ol
            style={{
              listStyleType: "decimal-leading-zero",
              listStylePosition: "inside",
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <li>
              A RN envidará todos os esforços no sentido de assegurar a precisão
              e a confiabilidade das informações por ela produzidas e geradas,
              não se responsabilizando por erros, falhas e suas consequências
              geradas pelas próprias fontes primárias de informações, dos meios
              de comunicação e dos sistemas de software e aplicativos (app) e,
              em nenhum caso, por danos indiretos lucros cessantes e reclamações
              de terceiros.
            </li>
            <li>
              O USUÁRIO resguardará a RN, contra qualquer reclamação baseada em
              prejuízo financeiro, que venha a ser causado pela utilização ou
              por informações veiculadas em seus sistemas, bem como contra
              qualquer dano causado em seus negócios, inclusive através de
              declarações prestadas a terceiros. Também isentará a RN de
              responsabilidade contra qualquer prejuízo, reclamação ou danos
              para pessoas ou bens em decorrência do uso do software(s) e app’s
              e/ou informações financeiras pela RN, inclusive, mas não somente,
              devendo estas proteções continuar vigentes mesmo após o término
              deste contrato, desde que se refiram ato ou fato ocorrido no seu
              prazo de vigência.
            </li>
            <li>
              O USUÁRIO será único responsável pela instalação e configuração e
              verificação de compatibilidade de software, hardware e aparelhos
              smartphone, a RN apenas é responsável por fornecer atualizações e
              suporte a padrões compatíveis com a demanda geral de aplicações.
              Na ocorrência de erro ou incorreção nos softwares utilizados
              fornecidos pela RN, caberá a RN apenas a responsabilidade pela
              adequação e correção desses softwares, não existindo nenhuma outra
              responsabilidade por parte da RN por quaisquer dados inerentes a
              esses erros e incorreções.
            </li>
            <li>
              O USUÁRIO do software e app’s e das informações geradas pela RN,
              entende que todos estes serviços contidos neste contrato não
              poderão ser cedidos, sublocados, copiados ou transferidos a
              terceiros, por qualquer meio e qualquer tempo, sem a autorização
              prévia e por escrito por parte da rn. O não cumprimento implicará
              em multa indenizatória equivalente a 900 (novecentas) vezes o
              valor total anual do presente contrato, além da rescisão imediata
              do mesmo, sem prejuízo das demais sanções legais cabíveis e de
              ressarcimento de honorários advocatícios e demais despesas
              inerentes ao processo. Essas obrigações continuarão vigentes mesmo
              após o término deste contrato.
            </li>
            <li>
              O USUÁRIO será responsável pelo pagamento dos serviços à RN,
              expressos em moeda corrente e os reajustes de valores ou na
              modalidade de cobrança poderá ser feito sem aviso prévio.
            </li>
            <li>
              O USUÁRIO ao contratar qualquer plano de assinatura de serviço da
              RN independente do tempo de contratação ao final de cada período a
              RN efetuara a renovação do contrato dentro do mesmo plano assinado
              anteriormente e efetuando a cobrança do mesmo com as mesmas
              condições e formas de pagamento do contrato anterior, e caso o
              USUÁRIO não deseje renovar sua assinatura o mesmo deve comunicar a
              RN em um prazo de até 30 dias antes do encerramento do contrato
              vigente.
            </li>
            <li>
              Na hipótese de o USUÁRIO desejar rescindir antecipadamente o
              presente instrumento, deverá solicitar com antecedência mínima de
              30 dias, a sua rescisão, após 7 dias da contração a RN não
              realizará reembolso de pagamentos já efetivado.
            </li>
            <li>
              O USUÁRIO pagará diretamente as concessionárias de
              telecomunicações, os custos de serviços de comunicações
              contratados que ocorrerem por força deste contrato.
            </li>
            <li>
              O atraso no pagamento de qualquer valor devido por parte do
              usuário, implicará no cancelamento automático do serviço e de
              todas as obrigações da RN, a qual poderá rescindir o presente
              contrato e ainda exigir o pagamento dos meses faltantes para o
              término do período contrato, da mesma forma como previsto na
              cláusula abaixo, baseando-se, para tanto, no último faturamento.
            </li>
            <li>
              No caso de houver impedimento justificado decorrente de problemas
              de comunicação das operadoras de telecomunicações, paralisação de
              funcionários setoriais, impedimento legal, decisão judicial ou
              motivos de forca maior, a obrigação aqui prevista por parte da RN
              será dispensada.
            </li>
            <li>
              A RN poderá alterar cancelar ou retirar qualquer serviço objeto
              deste contrato total ou parcialmente, a qualquer momento, sem
              comunicação prévia.
            </li>
            <li>
              Fica expressamente proibido ao USUÁRIO utilizar-se do nome,
              marcas, direitos intelectuais da RN, sem expressa e previa
              autorização da rn. O USUÁRIO também se compromete a observar todas
              as restrições ou condições de uso das informações e conteúdos
              fornecidos por terceiros. Inclusive quanto a acesso, armazenamento
              ou redistribuição, que tenham sido entregues pela RN ao cliente.
            </li>
            <li>
              A vigência deste contrato é de acordo com a descrição do plano
              selecionado sendo de período mínimo de 12 meses tendo renovação
              automática conforme descrito no parágrafo 7º deste contrato.
            </li>
            <li>
              O pagamento poderá ser efetuado através de boleto bancário, pix ou
              cartões de crédito.
            </li>
            <li>
              Nos serviços de informações móveis (celular) e, ao concordar com o
              presente termo de uso, o USUÁRIO declara e garante que:
              <ol style={{ listStyleType: "upper-alpha", marginLeft: "2rem" }}>
                <li>
                  O USUÁRIO está ciente de que nem todos os aparelhos celulares
                  têm capacidade para download e acesso à internet;
                </li>
                <li>
                  A RN não se responsabiliza pela indisponibilidade de conteúdos
                  de download para alguns tipos de celulares;
                </li>
                <li>
                  A RN não se responsabiliza pela utilização de aparelhos e/ou
                  linhas telefônicas de terceiros ficando isento de qualquer
                  eventual problema devido à prática deste ato;
                </li>
                <li>
                  A tarifação tratada neste termo de uso não está incluída os
                  valores correspondentes ao tráfego de dados, devendo o USUÁRIO
                  consultar previamente a sua operadora sobre o custo com a
                  referida transferência de dados.
                </li>
              </ol>
            </li>
            <li>
              Ao aceitar esse contrato o USUÁRIO também concordará com nossas
              políticas de privacidade descrita em contrato disponível no rodapé
              de todas páginas da RN em conformidade com a lei geral de proteção
              de dados pessoais (lei 13.709/18), o marco civil da internet (lei
              12.965/14) (e o regulamento da ue n. 2016/6790). Ainda, o
              documento poderá ser atualizado em decorrência de eventual
              atualização normativa, razão pela qual se convida o USUÁRIO a
              consultar periodicamente esta seção.{" "}
              <a href="/privacyPolicy">(clique aqui para acessar)</a>
            </li>
            <li>
              As partes elegem o foro central da comarca de Campo Grande (MS)
              como competente para dirimir quaisquer controvérsias oriundas do
              presente contrato, com exclusão de qualquer outro, por mais
              privilegiado que seja ou se torne.
            </li>
          </ol>
        </Typography>
      </ContractsLayout>
    </>
  );
}

import api from "../../services/api";

export default async function getEpisodes(id, setLoading, setEpisodesData) {
  setLoading(true);
  const tokenUser = localStorage.getItem("user");
  ///Tirando aspa da string do token
  const tokenJWT = tokenUser?.substring(1, tokenUser?.length - 1);

  //Headers da API
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${tokenJWT}`,
    },
  };

  api
    .get(`/videos/series/${id}`, headers)
    .then((response) => {
      setEpisodesData(response.data);
    })
    .catch((e) => console.log(e))
    .finally(() => {
      setLoading(false);
    });
}

import { useEffect, useState } from "react";
import { UserDto, UserDtoHandler } from "../../../../dtos";
import UserForm from "./UserForm";
import api from "../../../../services/api";
import { Typography } from "@mui/material";
import { BORDER_GRAY_50, TEXT_LIGHT_GREEN } from "../../../../constants/colors";

export default function EditUser() {
  const [user, setUser] = useState<UserDto>(UserDtoHandler.getDefault());
  const [loading, setLoading] = useState(false);

  //puxando dados da API
  useEffect(() => {
    loadUser();
  }, []);

  function loadUser() {
    const token = localStorage.getItem("user");

    //Tirando aspa da string do token
    const tokenJWT = token?.substring(1, token?.length - 1);

    //Headers da API
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${tokenJWT}`,
      },
    };

    api
      .get("/clientes/cadastro", headers)
      .then((response) => {
        setUser(UserDtoHandler.getByDatabase(response.data[0]));
      })
      .catch(() => console.log("Erro ao carregar dados do usuário"));
  }

  function handleSave(userData: UserDto) {
    setLoading(true);
    const data = UserDtoHandler.patch(userData);

    const token = localStorage.getItem("user");

    //Tirando aspa da string do token
    const tokenJWT = token?.substring(1, token?.length - 1);

    //Headers da API
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `${tokenJWT}`,
      },
    };

    api
      .put("/clientes/cadastro", data[0], headers)
      .then((res) => {
        setUser(UserDtoHandler.patch(res.data[0]));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <Typography
        borderBottom={"1px solid"}
        borderColor={BORDER_GRAY_50}
        color={TEXT_LIGHT_GREEN}
        fontSize={32}
        variant="h1"
      >
        Alterar dados
      </Typography>
      <UserForm
        user={user}
        onChange={(u) => setUser(u)}
        onSave={handleSave}
        loading={loading}
      />
    </>
  );
}

import api from "../../services/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { Loading } from "../../assets/imgs";
import { BACKGROUND_GRAY_700 } from "../../constants/colors";
import {
  getUserToken,
  loadAuth,
  loadSituation,
} from "../../utils/apiFunctions/auth";

export default function Graphics() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loadAuth(handleAuth);
    loadSituation(handleSituation);
    loadingAnimation();
  }, []);

  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("user");
    navigate("/login");
  }

  function handleSituation(data?: any) {
    if (data.situation !== "Pago") {
      navigate("/dashboard/myAccount");
    }
  }

  async function loadingAnimation() {
    setLoading(true);
    await api
      .get(
        `https://ruralbusiness.com.br/rbvideo/central_grafico/default.asp?token=${getUserToken()}`
      )
      .then((res) => {
        if (res) {
          setLoading(false);
        }
      });
  }

  const LoadGraphics = () => {
    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <iframe
            src={`https://ruralbusiness.com.br/rbvideo/central_grafico/default.asp?token=${getUserToken()}`}
            className="table-responsive"
            width="100%"
            height="1060px"
            frameBorder="0"
            scrolling="no"
            style={{ maxWidth: "1024px", overflowY: "hidden" }}
          ></iframe>
        )}
      </>
    );
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        height={"100%"}
        sx={{
          backgroundColor: BACKGROUND_GRAY_700,
          borderRadius: "15px",
          p: 2,
        }}
        maxWidth={1056}
        mt={6}
        mb={6}
        ml={"auto"}
        mr={"auto"}
      >
        <Grid item width={"100%"} xs={12}>
          <LoadGraphics />
        </Grid>
      </Grid>
    </>
  );
}

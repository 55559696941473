//import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LeftContentDashboardLayout,
  RightContentDashboardLayout,
} from "../../layouts";
import {
  EditPassword,
  EditPlan,
  EditUser,
  MyAccountButtons,
  MyPlan,
  RenewPlan,
} from "../../components/Dashboard/MyAccount";

import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  BORDER_GRAY_50,
  TEXT_DARK_BLUE,
  TEXT_LIGHT_GREEN,
} from "../../constants/colors";
import { UserDto, UserDtoHandler } from "../../dtos";
import { PlanUserDto, PlanUserDtoHandler } from "../../dtos/PlanUserDto";
import "../../services/script";
import "../styles/myAccountStyle.scss";
import { loadAuth, loadUser, loadUserPlan } from "../../utils/apiFunctions/auth";

export default function MyAccount(): JSX.Element {
  const isLargeScreen = useMediaQuery("(min-width: 769px)");
  const navigate = useNavigate();
  const [getUser, setGetUser] = useState<UserDto>(UserDtoHandler.getDefault());
  const [planUser, setPlanUser] = useState<PlanUserDto>(
    PlanUserDtoHandler.getDefault()
  );
  const [toggleState, setToggleState] = useState(1);
  const toggleStateRef = useRef(toggleState);

  //puxando dados da API
  useEffect(() => {
    loadAuth(handleAuth);
    handleScreenWidth(toggleStateRef.current);
    const loadAxios = async () => {
      await loadUser(setGetUser);
      await loadUserPlan(setPlanUser);
    };
    loadAxios();
  }, []);

  //manipulando o resultado do get da Auth
  function handleAuth(auth: boolean) {
    if (!auth) return;

    localStorage.removeItem("user");
    navigate("/login");
  }

  function handleLoadScript() {
    import("../../services/script")
      .then(({ script }: any) => {
        script();
      })
      .catch((err) => {
        // Handle failure
      });
  }

  async function handleScreenWidth(id: number) {
    toggleStateRef.current = id;
    setToggleState(toggleStateRef.current);
    const element = document.getElementById(`${id}`);
    element?.scrollIntoView({ behavior: "smooth" });
  }

  const LoadCurrentPage = () => {
    return (
      <>
        {toggleState === 1 ? (
          <MyPlan />
        ) : toggleState === 2 ? (
          <EditPassword />
        ) : toggleState === 3 ? (
          <EditUser />
        ) : toggleState === 4 ? (
          <RenewPlan />
        ) : toggleState === 5 ? (
          <Typography
            borderBottom={"1px solid"}
            borderColor={BORDER_GRAY_50}
            color={TEXT_LIGHT_GREEN}
            fontSize={32}
            textAlign={"center"}
            variant="h1"
          >
            Atendimento
          </Typography>
        ) : toggleState === 6 ? (
          <EditPlan />
        ) : null}
      </>
    );
  };

  return (
    <>
      <Box
        alignItems={"center"}
        borderRadius={"25px"}
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        marginBottom={"50px"}
        padding={isLargeScreen ? "0 50px" : "0"}
      >
        <Typography
          color={TEXT_DARK_BLUE}
          textAlign={"center"}
          fontSize={32}
          padding={"10px"}
        >
          Central do Assinante
        </Typography>
        <Box
          display={"flex"}
          gap={"30px"}
          width={isLargeScreen ? "100%" : "auto"}
          maxWidth={isLargeScreen ? "900px" : "auto"}
          flexDirection={isLargeScreen ? "row" : "column"}
        >
          <LeftContentDashboardLayout
            getUserRef={getUser.fullName}
            planUserRef={planUser.planName}
          >
            <MyAccountButtons
              handleScreenWidth={handleScreenWidth}
              toggleState={toggleState}
              setToggleState={setToggleState}
              handleLoadScript={handleLoadScript}
            />
          </LeftContentDashboardLayout>
          <RightContentDashboardLayout toggleState={toggleState}>
            <LoadCurrentPage />
          </RightContentDashboardLayout>
        </Box>
      </Box>
    </>
  );
}

import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import Resumo from "../../components/ResumoPayment";
import Boleto from "../../components/Boleto";
import Cartao from "../../components/Cartao";
import Pix from "../../components/Pix";

import { UserCard, UserCardHandler } from "../../interfaces/userCard";
import { PlanDetailDto, PlanDetailDtoHandler } from "../../dtos/PlanDetail";
import { PaymentMethodType, PeriodType } from "../../enums";
import { PlanMainDto, PlanMainDtoHandler } from "../../dtos";
import api from "../../services/api";
import "./paymentStyle.scss";
import "./newPaymentStyle.scss";
import { TEXT_RED } from "../../constants/colors";

export default function Payment() {
  const navigate = useNavigate();

  const { id, clientId } = useParams();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>(
    PaymentMethodType.creditCard
  );
  const paymentMethodRef = useRef(paymentMethod);
  const [selectPeriod, setSelectPeriod] = useState<PeriodType>(
    PeriodType.Annual
  );
  const selectedPeriodRef = useRef(selectPeriod);
  const [userCard, setUserCard] = useState<UserCard>(
    UserCardHandler.getDefault()
  );
  const [planDetail, setPlanDetail] = useState<PlanDetailDto>(
    PlanDetailDtoHandler.getDefault()
  );
  const [planMain, setPlanMain] = useState<PlanMainDto>(
    PlanMainDtoHandler.getDefault()
  );
  const [contId, setContId] = useState();
  const contIdRef = useRef(contId);
  const [titleId, setTitleId] = useState();
  const titleIdRef = useRef(titleId);
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  const [isPortion, setIsPortion] = useState(planDetail.installment);
  const isPortionRef = useRef(isPortion);
  const [loading, setLoading] = useState(false);
  const [marketSelect, setMarketSelect] = useState(planDetail.planMarket);
  const isMarketSelect = useRef(marketSelect);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [statusPaymento, setStatusPayment] = useState("");
  const [textButton, setTextButton] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadPlanDetail();
    loadPlanMarket();
    handleTextPaymentMethod(selectedPeriodRef.current);
    handleChangePaymentMethod(paymentMethod);
    setLoading(true);
  }, []);

  async function loadPlanMarket() {
    await api
      .get(`/planos/id/${id}`)
      .then((res) => {
        const newPlan = PlanMainDtoHandler.getByDataBase(res.data[0]);
        setPlanMain(newPlan);
        const marketPlan = newPlan.planMarket;
        isMarketSelect.current = marketPlan;
        setMarketSelect(isMarketSelect.current);
      })
      .catch(() => console.log("Erro ao buscar o mercado do plano"));
  }

  async function loadPlanDetail() {
    await api
      .get(
        `/planos/${id}/${selectedPeriodRef.current}/${paymentMethodRef.current}`
      )
      .then((response) => {
        console.log(response.data[0]);
        const newPlanDetails = PlanDetailDtoHandler.getByDatabase(
          response.data[0]
        );
        const portionPlan = newPlanDetails.installment;
        setPlanDetail(newPlanDetails);
        isPortionRef.current = String(portionPlan);
        setIsPortion(isPortionRef.current);
        userCard.portion = isPortionRef.current;
        setLoading(false);
      })
      .catch(() => console.log("Erro ao buscar o detalhes do plano"));
  }

  async function loadContract() {
    const cont_plano_id = planDetail.id;
    const cont_cliente_id = clientId;
    const cont_forma_pgto = planDetail.paymentMethod;
    const cont_mercado = String(marketSelect);
    const cont_publicidade = Number(localStorage.getItem("ad"));
    const body = {
      cont_plano_id,
      cont_cliente_id,
      cont_forma_pgto,
      cont_mercado,
      cont_publicidade,
    };

    await api
      .post("/contratos/adiciona", body)
      .then((response) => {
        const newContId = response.data.cont_id;
        contIdRef.current = newContId;
        const newTitle = response.data.titulo_id;
        titleIdRef.current = newTitle;
        setContId(newContId);
        setTitleId(newTitle);
      })
      .catch(() => console.log("Erro ao criar o contrato"));
  }

  async function handleChangeSelectedPeriod(period: PeriodType) {
    selectedPeriodRef.current = period;
    setSelectPeriod(period);
    await loadPlanDetail();

    if (period === PeriodType.Monthly) {
      paymentMethodRef.current = PaymentMethodType.creditCard;
      setPaymentMethod(PaymentMethodType.creditCard);
      await loadPlanDetail();
      setIsPortion(isPortionRef.current);
      userCard.portion = isPortionRef.current;
    }
    if (period === PeriodType.SemiAnnual) {
      paymentMethodRef.current = PaymentMethodType.creditCard;
      setPaymentMethod(PaymentMethodType.creditCard);
      await loadPlanDetail();
      setIsPortion(isPortionRef.current);
      userCard.portion = isPortionRef.current;
    }
    if (period === PeriodType.Annual) {
      paymentMethodRef.current = PaymentMethodType.creditCard;
      setPaymentMethod(PaymentMethodType.creditCard);
      await loadPlanDetail();
    }

    setIsPortion(isPortionRef.current);
    userCard.portion = isPortionRef.current;

    handleTextPaymentMethod(period);
  }

  function handleChangePaymentMethod(method: PaymentMethodType): void {
    paymentMethodRef.current = method;
    setPaymentMethod(method);
    setIsPortion(isPortionRef.current);

    if (method === PaymentMethodType.boleto) {
      setIsPortion(isPortionRef.current);
    }
    if (method === PaymentMethodType.pix) {
      setIsPortion(isPortionRef.current);
    }
    if (selectedPeriodRef.current === PeriodType.SemiAnnual) {
      setIsPortion(isPortionRef.current);
      if (method === PaymentMethodType.boleto) {
        setIsPortion(isPortionRef.current);
      }
      if (method === PaymentMethodType.pix) {
        setIsPortion(isPortionRef.current);
      }
    }

    loadPlanDetail();
  }

  function handleTextPaymentMethod(period: PeriodType) {
    if (period === PeriodType.Monthly) {
      return setStatus({
        type: "error",
        mensagem: "Pagamento apenas no cartão.",
      });
    }
    if (period === PeriodType.SemiAnnual) {
      return setStatus({
        type: "sucess",
        mensagem: "",
      });
    }
    if (period === PeriodType.Annual) {
      return setStatus({
        type: "sucess",
        mensagem: "",
      });
    }
    return true;
  }

  function handleChangeMarket(market: any) {
    setMarketSelect(market);
  }
  //manipulandoe e enviando dados do cartao
  async function handleSaveCard(userData: UserCard) {
    await loadContract();
    setButtonStatus(true);
    userCard.cardValidity = userCard.cardMonth + "/20" + userCard.cardYear;
    userCard.id = String(contIdRef.current || "");
    userCard.title = String(titleIdRef.current || "");
    const strgNumberCard = userCard.cardNumber.replace(/[^0-9]/g, "");
    userCard.cardNumber = strgNumberCard;
    const data = UserCardHandler.toDatabase(userData);

    api
      .post("/cobrancas/recorrencia/cartao", data[0])
      .then((res) => {
        handlePaymentStatus(res.data.paymentStatusId, res.data.paymentStatus);
      })
      .catch(() => {
        setButtonStatus(false);
        setStatusPayment(`Número do cartão de crédito inválido`);
        setTextButton(true);
      })
      .finally(() => setButtonStatus(false));
  }

  function handlePortion(data: any) {
    setIsPortion(data);
  }

  function handlePaymentStatus(statusId: any, status: any) {
    if (statusId === 2) {
      return navigate(`/pagamento/${contIdRef.current}`);
    }

    setButtonStatus(false);
    setStatusPayment(`${status}`);
    setTextButton(true);

    return;
  }

  return (
    <>
      <div className="container-payment">
        <div className="container-paymentMethod">
          <div className="container-multistep">
            <p className="container-step container-step_check">
              <span className="number">1.</span> Dados Pessoais
            </p>
            <p className="container-step">
              <span className="number">2.</span> Pagamento
            </p>
          </div>
          <hr />
          <hr />
          <div className="container_period">
            <div className="content_period">
              <h2 className="title_period">Periodo</h2>

              <div className="container_btn_period">
                <button
                  className={
                    selectPeriod === PeriodType.Monthly
                      ? "btn_plan active-tabs"
                      : "btn_plan"
                  }
                  onClick={() => handleChangeSelectedPeriod(PeriodType.Monthly)}
                >
                  Mensal
                </button>
                <button
                  className={
                    selectPeriod === PeriodType.SemiAnnual
                      ? "btn_plan active-tabs"
                      : "btn_plan"
                  }
                  onClick={() =>
                    handleChangeSelectedPeriod(PeriodType.SemiAnnual)
                  }
                >
                  Semestral
                </button>
                <button
                  className={
                    selectPeriod === PeriodType.Annual
                      ? "btn_plan active-tabs"
                      : "btn_plan"
                  }
                  onClick={() => handleChangeSelectedPeriod(PeriodType.Annual)}
                >
                  Anual
                </button>
              </div>
            </div>
          </div>
          <hr />
          <hr />
          <div
            className={id === "35" ? "marketContainer" : "marketContainerOff"}
          >
            <div className="contentMarket">
              <h2 className="marketTittle">Seleciona seu mercado:</h2>
              <FormControl size="small" color="success">
                <InputLabel>Mercado</InputLabel>
                <Select
                  id="demo-simple-select"
                  className="selectMarket"
                  label="Mercado"
                  onChange={(e) => {
                    handleChangeMarket(e.target.value);
                  }}
                >
                  <MenuItem value={1}>Soja e Trigo</MenuItem>
                  <MenuItem value={2}>Milho</MenuItem>
                  <MenuItem value={3}>Gado e Proteina Animal</MenuItem>
                  <MenuItem value={5}>Leite</MenuItem>
                  <MenuItem value={7}>Frango e Suínos</MenuItem>
                </Select>
              </FormControl>
            </div>
            <hr />
            <hr />
          </div>
          <h2 className="title_period">Forma de pagamento</h2>
          {status.type === "error" ? (
            <p
              style={{
                color: TEXT_RED,
                marginLeft: "64px",
                marginBottom: "8px",
                marginTop: "16px",
              }}
            >
              {status.mensagem}
            </p>
          ) : (
            ""
          )}
          <div className="bloc-tabs_pagamento">
            <button
              className={
                paymentMethod === 2
                  ? "tabs_pagamento active-tabs_pagamento"
                  : "tabs_pagamento"
              }
              onClick={() =>
                handleChangePaymentMethod(PaymentMethodType.creditCard)
              }
            >
              Cartão
            </button>
            <button
              className={
                paymentMethod === 1
                  ? "tabs_pagamento active-tabs_pagamento"
                  : "tabs_pagamento"
              }
              onClick={() =>
                handleChangePaymentMethod(PaymentMethodType.boleto)
              }
              disabled={selectPeriod === PeriodType.Monthly}
            >
              Boleto
            </button>
            <button
              className={
                paymentMethod === 3
                  ? "tabs_pagamento active-tabs_pagamento"
                  : "tabs_pagamento"
              }
              onClick={() => handleChangePaymentMethod(PaymentMethodType.pix)}
              disabled={selectPeriod === PeriodType.Monthly}
            >
              Pix
            </button>
          </div>
          <div className="content-tabs">
            <div
              className={
                paymentMethod === 2 ? "content  active-content" : "content"
              }
            >
              <Cartao
                user={userCard}
                onChange={(u) => setUserCard(u)}
                onSave={(e) => handleSaveCard(e)}
                period={selectPeriod}
                value={planDetail.value}
                planId={id || ""}
                onPortion={(e) => handlePortion(e)}
                portion={planDetail.installment || ""}
                loading={loading}
                marketPlan={marketSelect}
                button={buttonStatus}
                textButton={textButton}
                textValidateErro={statusPaymento}
              />
            </div>

            <div
              className={
                paymentMethod === 1 ? "content  active-content" : "content"
              }
            >
              <Boleto
                value={planDetail.value}
                clientId={clientId || ""}
                planId={String(planDetail.id)}
                paymentMethod={planDetail.paymentMethod}
                marketSelect={String(marketSelect)}
              />
            </div>

            <div
              className={
                paymentMethod === 3 ? "content  active-content" : "content"
              }
            >
              <Pix
                value={planDetail.value}
                clientId={clientId || ""}
                planId={String(planDetail.id)}
                paymentMethod={planDetail.paymentMethod}
                marketSelect={String(marketSelect)}
              />
            </div>
          </div>
        </div>
        <Resumo
          price={planDetail.value}
          planId={id}
          periodo={planDetail.description}
          portion={isPortion || ""}
          loading={loading}
        />
      </div>
    </>
  );
}

export default function getHeaders() {
  const token = localStorage.getItem("user");
  // console.log("User Token LocalStorage")
  const tokenJWT = token?.substring(1, token?.length - 1);
  //Headers da API
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${tokenJWT}`,
    },
  };

  return headers;
}

import { useNavigate } from 'react-router-dom';
import { MockupMobile } from '../../assets/imgs';
import { ShoppingCartRounded, TaskAltRounded } from '@mui/icons-material';
import ButtonOutline from '../Buttons/ButtonOutline';

export default function ProductComponent() {
  const navigate = useNavigate();

  return (
    <>
      {/* <!-- PRODUTO --> */}
      <section className="flex items-center justify-center px-3 py-5 md:px-28" id="produto">
        <div className="grid grid-cols-1 items-center justify-center py-24 lg:grid-cols-2">
          <div className="flex flex-col justify-center gap-10">
            <div className="flex flex-col items-center">
              <h2 className="text-stroke text-stroke-blue text-center font-helvetica text-3xl font-black uppercase lg:text-4xl xl:text-5xl">
                Assista onde quiser
              </h2>
              <h2 className="w-fit font-helvetica text-2xl font-black uppercase text-[#215d9f] lg:text-3xl xl:text-4xl">
                Quando quiser
              </h2>
            </div>

            <ul className="flex max-w-lg flex-col gap-5 self-center text-base font-medium uppercase text-zinc-700 xl:text-lg">
              <li className="flex items-center gap-3">
                <TaskAltRounded fontSize="medium" className="text-yellow-500" />
                <p className="">Diponível em todas plataformas</p>
              </li>
              <li className="flex items-center gap-3">
                <TaskAltRounded fontSize="medium" className="text-yellow-500" />
                <p className="">analises inéditas dos mercados de soja, milho, trigo, boi, leite, frango e suíno</p>
              </li>
              <li className="flex items-center gap-3">
                <TaskAltRounded fontSize="medium" className="text-yellow-500" />
                <p className="">séries investigativas inéditas e exclusivas</p>
              </li>
              <li className="flex items-center gap-3">
                <TaskAltRounded fontSize="medium" className="text-yellow-500" />
                <p className="">+ Gráficos, cotações e alertas de mercado</p>
              </li>
              <li className="flex items-center gap-3">
                <TaskAltRounded fontSize="medium" className="text-yellow-500" />
                <p className="">APP COM OPÇÃO OFFLINE BAIXE OS VIDEOS E ASSISTA ATÉ ONDE NÃO TEM SINAL DE INTERNET</p>
              </li>
            </ul>

            <div className="flex flex-col flex-wrap justify-center gap-3 sm:justify-start">
              <ButtonOutline
                icon={<ShoppingCartRounded fontSize="medium" />}
                link={'/plan'}
                onClick={undefined}
                className={'max-w-lg self-center bg-[#215d9f] text-base text-white'}
              >
                Assine Já
              </ButtonOutline>
              {/* <p className="text-center text-base text-zinc-500 underline">A partir de R$ 19,90/mês</p> */}
            </div>
          </div>
          <img src={MockupMobile} alt="" className="max-h-[300px] justify-self-center lg:max-h-[600px]" />
        </div>
      </section>
    </>
  );
}

export interface NewsDto {
  id?: number;
  title: string;
  lead: string;
  imagem: number;
  date: string;
  text: string;
}

export class NewsDtoHandler {
  public static getDefault(data: any = {}): NewsDto {
    return {
      id: data.id || null,
      title: data.title || '',
      lead: data.lead || '',
      imagem: data.imagem || null,
      date: data.date || '',
      text: data.text || '',
    }
  }

  public static getByDatabase(data: any = {}): NewsDto {
    return this.getDefault({
      id: data.not_numero,
      title: data.not_titulo,
      lead: data.not_lead,
      imagem: data.not_imagem,
      date: data.not_data,
      text: data.not_texto
    })
  }
}
export { default as formatPrice } from './formatPrice';
export { default as scrollToTop } from './scrollToTop';
export { default as findCEP } from './apiFunctions/findCEP';
export { default as loadNews } from './apiFunctions/loadNews';
export { default as loadOneNews } from './apiFunctions/loadOneNews';
export { default as newDate } from './apiFunctions/newDate';
export { default as newVideoDate } from './apiFunctions/newVideoDate';
export { default as userSeeAlert } from './apiFunctions/userSeeAlert';
export { default as capitalizeWords } from './form/capitalizeWords';
export { default as formValidation } from './form/formValidation';
export { default as marketplace } from './apiFunctions/marketplace';
export { default as newVideo } from './apiFunctions/newVideo';
export { default as getSeries } from './apiFunctions/getSeries';
export { default as getEpisodes } from './apiFunctions/getEpisodes';

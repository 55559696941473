import { Navigate, Outlet } from "react-router-dom";

function useAuth() {
  //comunicar com o servidor, localstorege
  let user: any;

  const _user = localStorage.getItem("user");

  if (_user) {
    user = JSON.parse(_user);
    //console.log("user", { ...user })
  }
  if (user) {
    return true;
  } else {
    return false;
  }
}

export default function ProtectedRoute(): JSX.Element {
  const auth = useAuth();

  return auth ? <Outlet /> : <Navigate to="/login" />;
}

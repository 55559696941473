import { UF } from "../../constants/uf";
import api from "../../services/api";

export default async function formValidation(props, setStatus) {
  const emailValido =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const error = await api
    .get(`/clientes/cpf/${props.user.cpf.replace(/[^0-9,]*/g, "")}`)
    .then((response) => {
      return response.data.message;
    })
    .catch(() => {
      console.log("Erro ao procurar cpf");
    });

  if (!props.user.fullName.trim())
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo nome!",
      link: "",
      field: "fullName",
    });
  else if (props.user.fullName.length < 8)
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o nome completo!",
      link: "",
      field: "fullName",
    });
  else if (!props.user.email)
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo email!",
      link: "",
      field: "email",
    });
  else if (!props.user.email.match(emailValido))
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher um email valido!",
      link: "",
      field: "email",
    });
  else if (!props.user.password)
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo senha!",
      link: "",
      field: "password",
    });
  else if (props.user.password.length < 8)
    return setStatus({
      type: "error",
      mensagem: "Necessário que a senha tenha no minimo 8 caracteres!",
      link: "",
      field: "password",
    });
  else if (!props.user.confirmPassword)
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo confirmar senha!",
      link: "",
      field: "confirmPassword",
    });
  else if (props.user.confirmPassword !== props.user.password)
    return setStatus({
      type: "error",
      mensagem: "Necessário que a senha seja igual!",
      link: "",
      field: "confirmPassword",
    });
  else if (!props.user.cpf)
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo CPF!",
      link: "",
      field: "cpf",
    });
  else if (error === "Usuário já possui cadastro!") {
    return setStatus({
      type: "error",
      mensagem: "Esse CPF já possui um cadastro!",
      link: "Clique aqui para entrar!",
      field: "cpf",
    });
  } else if (error === "CPF inválido!") {
    return setStatus({
      type: "error",
      mensagem: "CPF inválido!",
      link: "",
      field: "cpf",
    });
  } else if (props.user.cpf.length < 11)
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o CPF correto!",
      link: "",
      field: "cpf",
    });
  else if (!props.user.phoneNumber)
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo celular!",
      link: "",
      field: "phoneNumber",
    });
  else if (props.user.phoneNumber.length < 14)
    return setStatus({
      type: "error",
      mensagem: "Preencha um número de celular válido!",
      link: "",
      field: "phoneNumber",
    });
  else if (!props.user.address?.cep)
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo CEP!",
      link: "",
      field: "cep",
    });
  else if (props.user.address.cep.length < 8)
    return setStatus({
      type: "error",
      mensagem: "Digite um CEP válido!",
      link: "",
      field: "cep",
    });
  else if (!props.user.address.numberAddress)
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo Número!",
      link: "",
      field: "address.numberAddress",
    });
  else if (!props.user.address?.address.trim())
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo Rua!",
      link: "",
      field: "address.address",
    });
  else if (!props.user.address?.neighborhood.trim())
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo Bairro!",
      link: "",
      field: "address.neighborhood",
    });
  else if (!props.user.address?.state)
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo UF!",
      link: "",
      field: "address.state",
    });
  else if (!UF.includes(props.user.address?.state.toUpperCase()))
    return setStatus({
      type: "error",
      mensagem: "Preencha uma UF válida!",
      link: "",
      field: "address.state",
    });
  else if (!props.user.address?.city.trim())
    return setStatus({
      type: "error",
      mensagem: "Necessário preencher o campo Cidade!",
      link: "",
      field: "address.city",
    });
  else {
    setStatus({
      type: "",
      mensagem: "",
      link: "",
      field: "",
    });
  }
  return true;
}

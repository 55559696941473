export const cpfMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const phoneMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que não seja número por nada
    .replace(/(\d{2})(\d)/, "($1) $2") // captura 2 grupos de números, o primeiro de 2 e o segundo de 1, adicionando parênteses e espaço entre eles
    .replace(/(\d{5})(\d)/, "$1-$2") // captura 2 grupos de números, o primeiro de 5 e o segundo de 1, adicionando um traço entre eles
    .replace(/(-\d{4})\d+?$/, "$1"); // captura 4 números seguidos de um traço e não permite digitar mais nada
};

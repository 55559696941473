import moment from "moment";

export default function newVideo(date) {
  const newDate = new Date();
  const dateDay = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const dayDate = moment(date).utc().locale("pt-br").format("l");
  const today = `${dateDay}/${month}/${year}`;

  if (today === dayDate) return "Novo";
  return "";
}

import { PlanUserDtoHandler } from "../../dtos/PlanUserDto";
import api from "../../services/api";

export default async function userSeeAlert(
  setPlanUser: any,
  setIsUserAllowed: any
) {
  const tokenUser = localStorage.getItem("user");
  ///Tirando aspa da string do token
  const tokenJWT = tokenUser?.substring(1, tokenUser?.length - 1);

  //Headers da API
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": `${tokenJWT}`,
    },
  };

  api
    .get("/contratos/situacao/", headers)
    .then((response) => {
      setPlanUser(PlanUserDtoHandler.getByDataBase(response.data[0]));
      response.data[0].cont_mercado !== 13
        ? setIsUserAllowed(false)
        : setIsUserAllowed(true);
    })
    .catch(() => console.log("Erro ao autenticar o usuário"));
}

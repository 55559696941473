import { Button } from "@mui/material";
import { BUTTON_LIGHT_GREEN } from "../../constants/colors";

export default function MyAccountFormButton({
  title,
  action,
  navigate,
  useNavigate = false,
  route,
  type = "button",
  disabled = false,
}: any) {
  const actionHandler = () => {
    if (useNavigate) {
      navigate(route);
    } else if (action) {
      action();
    }
  };

  return (
    <Button
      disabled={disabled}
      disableElevation
      fullWidth
      onClick={actionHandler}
      size="large"
      sx={{
        background: BUTTON_LIGHT_GREEN,
        mt: 2,
        height: "50px",
      }}
      type={type}
      variant="contained"
    >
      {title}
    </Button>
  );
}

import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PlanDetailDto, PlanDetailDtoHandler } from "../../dtos/PlanDetail";
import {
  BACKGROUND_LIME_GREEN,
  TEXT_DARK_BLUE,
  TEXT_GRAY_600,
  TEXT_GRAY_700,
  TEXT_WHITE,
} from "../../constants/colors";
import api from "../../services/api";
import "../../pages/styles.scss";
import { formatPrice } from "../../utils";

interface ResumProps {
  planId?: string;
  price?: number;
  periodo?: string;
  portion?: string;
}

export default function OverviewPaymentMP(props: ResumProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clientId, contract, title } = useParams();
  const [planDetail, setPlanDetail] = useState<PlanDetailDto>(
    PlanDetailDtoHandler.getDefault()
  );

  const params = pathname.split("/", 3);

  useEffect(() => {
    loadPlanDetail();
  }, []);

  async function loadPlanDetail() {
    await api
      .get(`/planos/id/${props.planId}`)
      .then((response) => {
        const newPlanDetails = PlanDetailDtoHandler.getByDatabase(
          response.data[0]
        );
        setPlanDetail(newPlanDetails);
      })
      .catch(() => console.log("Erro ao buscar o detalhes do plano"));
  }

  function handlerTradePlan() {
    if (params[1] === "pagamento") return navigate(`/planos/${clientId}`);

    navigate(`/dashboard/renewPlan/${clientId}/${contract}/${title}`);
  }

  return (
    <>
      <Box
        alignItems={"center"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        justifyContent={"center"}
        mt={2}
        mx={2}
        borderRadius={"8px"}
        boxShadow={"0px 0px 15px 0px #0000001c"}
      >
        <Box
          bgcolor={BACKGROUND_LIME_GREEN}
          borderRadius={"10px 10px 0 0"}
          padding={2}
          sx={{
            backgroundImage:
              planDetail.id === 34
                ? "linear-gradient(280.24deg, #69c462 -22.13%, #00113c 96.65%)"
                : "",
          }}
          width={"100%"}
        >
          <Typography
            className={`${planDetail.id === 34 ? "plan_title_detach" : ""}`}
            color={TEXT_WHITE}
            fontSize={24}
            fontWeight={500}
            textAlign={"center"}
            variant={"h6"}
          >
            Plano {planDetail.name}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            textAlign={"center"}
            fontWeight={"normal"}
            color={TEXT_GRAY_700}
          >
            Até {planDetail.installment}x de
          </Typography>
          <Box display={"flex"} justifyContent={"center"} alignItems={"end"}>
            <Typography
              variant="subtitle1"
              sx={{ mr: 1, mb: 0.5 }}
              fontWeight={"normal"}
            >
              R$
            </Typography>
            <Typography
              variant="h4"
              color={TEXT_GRAY_700}
              fontSize={"2.65rem"}
              fontWeight={800}
            >
              {formatPrice(planDetail.value / parseInt(planDetail.installment))}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="subtitle2"
          textAlign={"center"}
          fontWeight={"normal"}
          color={TEXT_GRAY_600}
        >
          Ou R${formatPrice(planDetail.value)} no Pix, Boleto ou Débito
        </Typography>
        <Button
          variant="text"
          sx={{
            color: TEXT_DARK_BLUE,
            textTransform: "lowercase",
            textDecoration: "underline",
            marginBottom: 1,
          }}
          onClick={handlerTradePlan}
        >
          Trocar
        </Button>
      </Box>
    </>
  );
}

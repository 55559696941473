import Vimeo from "@u-wave/react-vimeo";
import { Box, Grid, Typography } from "@mui/material";
import { VideoLoading } from "../../assets/imgs";
import { marketplace, newVideo, newVideoDate } from "../../utils/apiFunctions";
import {
  getUserToken
} from "../../utils/apiFunctions/auth";


export default function DashboardVideoComponent({ posts, loading }: any) {
  const VideosLoadingAnimation = () => {
    return (
      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        direction={"row"}
        spacing={2}
      >
        <VideoLoading />
        <VideoLoading />
        <VideoLoading />
        <VideoLoading />
        <VideoLoading />
        <VideoLoading />
        <VideoLoading />
        <VideoLoading />
      </Grid>
    );
  };

  const Videos = () => {
    return (
      <>
        {posts.map((post: any, index: number) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={2.8}
            ml={1}
            mb={1}
            p={1}
          >
             {!post.vid_cdn && (
              <Box
                component={"iframe"}
                src={`https://player.vimeo.com/video/${post.vid_end}?h=${post.vid_hash}`}
                width={"100%"}
                allow="autoplay; fullscreen; picture-in-picture"
                border={"none"}
              />
            )}
            {post.vid_cdn && (
              <Box
              paddingTop={"10px"}
                component={"iframe"}
                src={`https://player.rbvideo.com.br/index.html?id=${post.vid_cdn}&thumb=${post.vid_thumb}&cli_id=${getUserToken()}&app=web`} //+ "&preload=false"
                width={"100%"}
                height={"80%"}
                allow="fullscreen; accelerometer; gyroscope; autoplay ;picture-in-picture"
                allowFullScreen
                border={"none"}
                />
              /*<Box
                component={"iframe"}
                src={post.vid_cdn}
                width={"100%"}
                allow="fullscreen; accelerometer; gyroscope; autoplay ;picture-in-picture"
                border={"none"}
              />*/
            )}
            <Box maxWidth={"410px"} marginX={"auto"}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography variant="subtitle2" fontWeight={"normal"} pr={1}>
                  {marketplace(post.vid_mercado)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={"orange"}
                  fontWeight={700}
                  pr={1}
                >
                  {newVideo(post.vid_data)}
                </Typography>
              </Box>
              <Typography
                variant="subtitle2"
                fontWeight={"normal"}
                textAlign={"start"}
                pr={1}
              >
                {newVideoDate(post.vid_data)}
              </Typography>
              <Typography
                variant="body1"
                color={"black"}
                textAlign={"start"}
                fontWeight={500}
                pr={1}
              >
                {post.vid_titulo}
              </Typography>
            </Box>
          </Grid>
        ))}
      </>
    );
  };

  const RenderVideos = () => {
    return <>{loading ? <VideosLoadingAnimation /> : <Videos />}</>;
  };

  return (
    <>
      <RenderVideos />
    </>
  );
}

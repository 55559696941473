import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Link, Typography } from "@mui/material";
import styled from "styled-components";
import { Apple, PlayStore } from "../../assets/imgs";
import {
  BACKGROUND_DARK_BLUE,
  BACKGROUND_WHITE,
  BORDER_DARK_BLUE,
  BORDER_GRAY_100,
  TEXT_DARK_BLUE,
  TEXT_WHITE,
} from "../../constants/colors";
import { useEffect } from "react";
import scrollToTop from "../../utils/scrollToTop";

const RBtitle = styled.span`
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${TEXT_DARK_BLUE};
`;

export default function AfterPayment() {
  const navigate = useNavigate();
  // const { contId } = useParams();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      mb={5}
    >
      <Box
        bgcolor={BACKGROUND_WHITE}
        border={"1px solid"}
        borderColor={BORDER_GRAY_100}
        borderRadius={"10px"}
        justifyContent={"center"}
        alignContent={"center"}
        width={"100%"}
        maxWidth={700}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"24px"} p={4}>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography
              color={TEXT_DARK_BLUE}
              fontSize={27}
              fontWeight={"bold"}
              variant="h4"
              textTransform={"uppercase"}
              textAlign={"center"}
            >
              SEJA BEM-VINDO(A) A
            </Typography>
            <Typography
              variant="h4"
              textTransform={"uppercase"}
              textAlign={"center"}
              fontSize={50}
              color={TEXT_WHITE}
              fontWeight={"bold"}
            >
              <RBtitle>RURAL BUSINESS</RBtitle>
            </Typography>
          </Box>
          <Box display={"flex"} width={"100%"} height={"320px"} maxHeight={320}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/stRixlgMKac"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          </Box>

          <Typography
            variant="h5"
            textTransform={"uppercase"}
            textAlign={"center"}
            color={TEXT_DARK_BLUE}
            fontSize={20}
            fontWeight={"regular"}
          >
            PARA TER ACESSO AO CONTEÚDO BASTA ACESSAR O NOSSO SITE{" "}
            <Link
              color={TEXT_DARK_BLUE}
              display={"inline"}
              fontSize={20}
              fontWeight={600}
              underline="hover"
              target="_blank"
              href="/"
              sx={{
                ":visited": {
                  color: TEXT_DARK_BLUE,
                },
              }}
            >
              RBVIDEO.COM.BR
            </Link>{" "}
            OU BAIXAR O NOSSO APP!
          </Typography>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <Box display={"flex"} gap={"10px"}>
                <Box
                  alt="playstore link"
                  component={"img"}
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=br.com.rbvideo.rbvideo&hl=pt_BR",
                      "_blank"
                    )
                  }
                  src={PlayStore}
                  height={"60px"}
                  sx={{ cursor: "pointer" }}
                />
                <Box
                  alt="appstore link"
                  component={"img"}
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/rb-video/id1324714161",
                      "_blank"
                    )
                  }
                  src={Apple}
                  py={"5px"}
                  height={"60px"}
                  sx={{ cursor: "pointer" }}
                />
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <Button
                  disableElevation
                  fullWidth
                  variant="contained"
                  onClick={() => navigate("/dashboard/video")}
                  sx={{
                    color: TEXT_DARK_BLUE,
                    background: BACKGROUND_WHITE,
                    border: "3px solid",
                    borderRadius: "10px",
                    ":hover": {
                      bgcolor: BACKGROUND_DARK_BLUE,
                      color: TEXT_WHITE,
                      borderColor: BORDER_DARK_BLUE,
                    },
                    fontSize: 20,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    letterSpacing: 2,
                  }}
                >
                  Assista Online
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          bgcolor={BACKGROUND_DARK_BLUE}
          borderRadius={"0px 0px 10px 10px"}
          p={4}
          width={"100%"}
        >
          <Typography
            color={TEXT_WHITE}
            fontSize={14}
            fontWeight={"bold"}
            textAlign={"center"}
            mb={2}
          >
            Seja bem vindo a maior e mais completa agencia de informações
            agropecuárias do Brasil!
          </Typography>
          <Typography
            color={TEXT_WHITE}
            fontSize={14}
            fontWeight={"regular"}
            textAlign={"center"}
          >
            Em caso de duvidas entre em contato com nossa central de
            atendimento:
          </Typography>
          <Typography color={TEXT_WHITE} fontSize={14} textAlign={"center"}>
            De segunda a sexta das <b>8h as 18h</b>
          </Typography>
          <Typography color={TEXT_WHITE} fontSize={14} textAlign={"center"}>
            <b>(67)3026-7495 / (67)3025-5205 / (67)8464-1530</b>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

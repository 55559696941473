import { TrendingFlatRounded } from '@mui/icons-material';
import { About } from '../../assets/imgs';

export default function AboutComponent() {
  return (
    <>
      {/* <!-- SOBRE --> */}
      <section className="flex items-center justify-center bg-[#eeeeee] px-3 py-5 md:px-28" id="sobre">
        <div className="grid max-w-5xl grid-cols-1 items-center justify-center py-32 lg:grid-cols-2">
          <div className="flex flex-col gap-3">
            <h2 className="mb-5 w-fit text-3xl font-black uppercase text-[#215d9f] lg:text-4xl">Sobre nós</h2>
            <p className="text-xl">
              <p className="text-xl font-medium text-zinc-800">
                Somos hoje a maior e mais completa agencia de informações especializadas no agronegócio do Brasil, com
                mais de 33 anos no mercado.
              </p>
              <p className="mt-5 text-lg text-zinc-600">
                Em 1990 a RURAL BUSINESS surgiu se posicionando como a primeira agência provedora de informações
                estratégicas para o Agronegócio, com o objetivo de olhar de forma investigativa e profissional o dia a
                dia do mercado de grãos e de carnes - no Brasil e no mundo, melhorando a sua capacidade de lucrar no
                agronegócio.
              </p>
            </p>
            <p
              className="cursor-pointer text-base text-gray-400 hover:underline"
              onClick={() => window.open('https://ruralbusiness.com.br/default.asp?p=quem-somos.asp', '_blank')}
            >
              veja mais <TrendingFlatRounded />
            </p>
          </div>
          <img src={About} alt="logo rural business" className="max-h-72 self-center justify-self-center md:max-h-96" />
        </div>
      </section>
    </>
  );
}

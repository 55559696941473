import {
  AttachMoney,
  BorderColor,
  CancelPresentation,
  Feed,
  LockReset,
  SupportAgent,
  Sync,
} from "@mui/icons-material";
import DashboardButtonComponent from "../DashboardButtonComponent";

export default function MyAccountButtons({
  handleScreenWidth,
  toggleState,
  setToggleState,
  handleLoadScript,
}: any) {
  return (
    <>
      <DashboardButtonComponent
        title={"Meu Plano"}
        handleScreenWidth={handleScreenWidth}
        toggleState={toggleState}
        setToggleState={setToggleState}
        number={1}
      >
        <Feed
          titleAccess="Meu plano"
          style={{ marginRight: "4px", fontSize: "16px" }}
        />
      </DashboardButtonComponent>
      <DashboardButtonComponent
        title={"Alterar senha"}
        handleScreenWidth={handleScreenWidth}
        toggleState={toggleState}
        setToggleState={setToggleState}
        number={2}
      >
        <LockReset
          titleAccess="Alterar senha"
          style={{ marginRight: "4px", fontSize: "16px" }}
        />
      </DashboardButtonComponent>
      <DashboardButtonComponent
        title={"Alterar dados"}
        handleScreenWidth={handleScreenWidth}
        toggleState={toggleState}
        setToggleState={setToggleState}
        number={3}
      >
        <BorderColor
          titleAccess="Alterar dados"
          style={{ marginRight: "4px", fontSize: "16px" }}
        />
      </DashboardButtonComponent>
      <DashboardButtonComponent
        title={"Renovar plano"}
        handleScreenWidth={handleScreenWidth}
        toggleState={toggleState}
        setToggleState={setToggleState}
        number={4}
      >
        <Sync
          titleAccess="Renovar plano"
          style={{ marginRight: "4px", fontSize: "16px" }}
        />
      </DashboardButtonComponent>
      <DashboardButtonComponent
        title={"Atendimento"}
        handleScreenWidth={handleScreenWidth}
        toggleState={toggleState}
        setToggleState={setToggleState}
        number={5}
        action={handleLoadScript}
      >
        <SupportAgent
          titleAccess="Atendimento"
          style={{ marginRight: "4px", fontSize: "16px" }}
        />
      </DashboardButtonComponent>
      <DashboardButtonComponent
        title={"Trocar/Cancelar assinatura"}
        handleScreenWidth={handleScreenWidth}
        toggleState={toggleState}
        setToggleState={setToggleState}
        number={6}
      >
        <CancelPresentation
          titleAccess="Cancelamento"
          style={{ marginRight: "4px", fontSize: "16px" }}
        />
      </DashboardButtonComponent>
    </>
  );
}

import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  BACKGROUND_WHITE,
  BORDER_GRAY_100,
  BORDER_GRAY_600,
  BUTTON_DARK_GREEN,
  BUTTON_LIGHT_GREEN,
  TEXT_DARK_BLUE,
  TEXT_GRAY_700,
  TEXT_RED,
} from "../../constants/colors";
import Address from "./Address";
import { cpfMask, phoneMask } from "./mask";
import FormSteps from "./FormSteps";
import { SmallLoading } from "../../assets/imgs";
import { UserFormProps } from "../../interfaces/userFormProps";
import {
  capitalizeWords,
  findCEP,
  formValidation,
  scrollToTop,
} from "../../utils";
import "../styles/paymentStyle.scss";

export default function UserForm(props: UserFormProps) {
  const formQuery = useMediaQuery("(max-width: 900px)");
  // começa com type error para formulário não passar faltando informação
  const [status, setStatus] = useState({
    type: "error",
    mensagem: "",
    link: "",
    field: "",
  });
  const [disableAddressInput, setDisableAddressInput] = useState({
    address: true,
    city: true,
    neighborhood: true,
    state: true,
  });
  const steps: any = [
    { number: 1, title: "Seu Plano", done: true, current: false },
    { number: 2, title: "Dados Pessoais", done: false, current: true },
    { number: 3, title: "Pagamento", done: false, current: false },
  ];

  const [loadingCpf, setLoadingCpf] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);

  //manipula todos os inputs do formulario
  const handleChange = (name: any, value: any) => {
    if (name.includes(".")) {
      // Handle nested object properties
      const [parent, child] = name.split(".");
      props.setUserForm((prevState: any) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: value,
        },
      }));
    } else {
      // Handle regular properties
      props.setUserForm((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  //validando dados do user
  const handleValidate = async () => {
    setLoadingCpf(true);
    await formValidation(props, setStatus)
      .then(() => {
        setLoadingCpf(false);
      })
      .catch(() => {
        setLoadingCpf(false);
      });
  };

  //alterando os valores do address do user
  function handleChangeAddress(e: any) {
    const cep = e.target.value.replace(/\D/g, "");
    props.onChange({
      ...props.user,
      address: {
        ...props.user.address,
        cep,
      },
    });

    cep.length === 8 &&
      findCEP(cep, setLoadingCep, setDisableAddressInput, props);
  }

  async function handleSubmit(event: any) {
    event && event.preventDefault();
    handleValidate();
    if (status.type !== "error") {
      //Tratando Inputs antes de mandar ao servidor

      props.user.fullName = props.user.fullName.trim()
      props.user.email = props.user.email.trim()

      //Tirando máscaras do cpf e celular
      props.user.cpf = props.user.cpf.replace(/[^0-9,]*/g, "");
      props.user.phoneNumber = props.user.phoneNumber.replace(/[^0-9,]*/g, "");

      //Tirando possíveis espaços a mais quando usuário digitar endereço
      if (!disableAddressInput.address) {
        props.user.address.address = capitalizeWords(
          props.user.address.address.trim()
        );
      }

      if (!disableAddressInput.neighborhood) {
        props.user.address.neighborhood = capitalizeWords(
          props.user.address.neighborhood.trim()
        );
      }

      if (!disableAddressInput.city) {
        props.user.address.city = capitalizeWords(
          props.user.address.city.trim().toLowerCase()
        );
      }

      if (!disableAddressInput.state) {
        props.user.address.state = props.user.address.state.toUpperCase();
      }

      //Igualando addewssNumber ao numberAddress
      props.user.addressNumber = props.user.address.numberAddress;

      props.onSave(props.user);
    } else {
      scrollToTop();
    }
  }

  const RenderErrorMessage = () => {
    return (
      <>
        {status.type === "error" && (
          <Typography
            display={"block"}
            margin={"auto"}
            padding={"8px"}
            borderRadius={"5px"}
            color={TEXT_RED}
            fontSize={"0.9rem"}
            textAlign={"center"}
          >
            {status.mensagem} {""}
            {status.link !== "" && (
              <Link color={TEXT_DARK_BLUE} href="/login">
                {status.link}
              </Link>
            )}
          </Typography>
        )}
      </>
    );
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={formQuery ? "column" : "row"}
        gap={"32px"}
        justifyContent={"center"}
        marginY={"25px"}
      >
        <Box
          bgcolor={BACKGROUND_WHITE}
          border={"1px solid"}
          borderColor={BORDER_GRAY_100}
          borderRadius={"10px"}
          maxWidth={formQuery ? "100%" : "460px"}
        >
          <FormSteps steps={steps} />
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
            padding={"15px"}
          >
            <RenderErrorMessage />
            <TextField
              color={status.field === "fullName" ? "warning" : "success"}
              focused={status.field === "fullName" ? true : false}
              fullWidth
              InputLabelProps={{
                shrink: true,
                style: { color: TEXT_GRAY_700 },
              }}
              label="Nome completo"
              name="fullName"
              onChange={(e) => {
                handleChange("fullName", e.target.value);
              }}
              size="small"
              type={"text"}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: BORDER_GRAY_600,
                },
              }}
              value={props.user.fullName}
            />
            <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
              <TextField
                color={status.field === "email" ? "warning" : "success"}
                focused={status.field === "email" ? true : false}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                  style: { color: TEXT_GRAY_700 },
                }}
                label="Seu E-mail"
                onChange={(e) => {
                  handleChange("email", e.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === " ") {
                    event.preventDefault();
                  }
                }}
                required
                size="small"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: BORDER_GRAY_600,
                  },
                }}
              />
              <Typography
                color={TEXT_GRAY_700}
                fontSize={"0.8rem"}
                fontWeight={400}
                lineHeight={"0.9rem"}
                marginBottom={"15px"}
                paddingX={"15px"}
              >
                Ao colocar seu e-mail, você concorda com a{" "}
                <Link
                  color={TEXT_DARK_BLUE}
                  href="/privacyPolicy"
                  target="_blank"
                >
                  politica de privacidade
                </Link>{" "}
                da Rural Business e autoriza o envio de comunicados via email
              </Typography>
            </Box>

            <Box display={"flex"} gap={"5px"}>
              <TextField
                color={status.field === "password" ? "warning" : "success"}
                focused={status.field === "password" ? true : false}
                InputLabelProps={{
                  shrink: true,
                  style: { color: TEXT_GRAY_700 },
                }}
                label="Sua senha"
                onChange={(e) => {
                  handleChange("password", e.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === " ") {
                    event.preventDefault();
                  }
                }}
                type="password"
                value={props.user.password}
                size="small"
                sx={{
                  flexGrow: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: BORDER_GRAY_600,
                  },
                }}
              />
              <TextField
                color={
                  status.field === "confirmPassword" ? "warning" : "success"
                }
                focused={status.field === "confirmPassword" ? true : false}
                InputLabelProps={{
                  shrink: true,
                  style: { color: TEXT_GRAY_700 },
                }}
                label="Confirmar a senha"
                onChange={(e) =>
                  handleChange("confirmPassword", e.target.value)
                }
                onKeyPress={(event) => {
                  if (event.key === " ") {
                    event.preventDefault();
                  }
                }}
                type="password"
                size="small"
                sx={{
                  flexGrow: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: BORDER_GRAY_600,
                  },
                }}
              />
            </Box>
            <Box display={"flex"} gap={"5px"}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                justifyItems={"center"}
                width={"100%"}
                sx={{
                  transition: "all ease-in-out 0.4s",
                }}
              >
                <TextField
                  autoComplete="off"
                  color={status.field === "cpf" ? "warning" : "success"}
                  disabled={loadingCpf}
                  focused={status.field === "cpf" ? true : false}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                    style: { color: TEXT_GRAY_700 },
                  }}
                  inputProps={{ maxLength: 14 }}
                  label="CPF"
                  value={props.user.cpf}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    handleChange("cpf", cpfMask(e.target.value));
                  }}
                  placeholder="Apenas números"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: BORDER_GRAY_600,
                    },
                  }}
                />
                {loadingCpf && <SmallLoading />}
              </Box>
            </Box>
            <Box display={"flex"} gap={"5px"}>
              <TextField
                color={status.field === "phoneNumber" ? "warning" : "success"}
                focused={status.field === "phoneNumber" ? true : false}
                label="Celular"
                InputLabelProps={{
                  shrink: true,
                  style: { color: TEXT_GRAY_700 },
                }}
                inputProps={{ maxLength: 15 }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  handleChange("phoneNumber", phoneMask(e.target.value));
                }}
                placeholder="DD + número"
                value={props.user.phoneNumber}
                size="small"
                sx={{
                  flexGrow: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: BORDER_GRAY_600,
                  },
                }}
              />
              <Box
                display={"flex"}
                justifyContent={"center"}
                justifyItems={"center"}
                sx={{
                  transition: "all ease-in-out 0.4s",
                }}
              >
                <TextField
                  color={status.field === "cep" ? "warning" : "success"}
                  disabled={loadingCep}
                  focused={status.field === "cep" ? true : false}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: TEXT_GRAY_700 },
                  }}
                  inputProps={{ maxLength: 8 }}
                  label="CEP"
                  onChange={(e) => {
                    handleChangeAddress(e);
                  }}
                  placeholder="Apenas números"
                  value={props.user.address.cep}
                  size="small"
                  sx={{
                    flexGrow: 1,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: BORDER_GRAY_600,
                    },
                  }}
                />
                {loadingCep && <SmallLoading />}
              </Box>
            </Box>
            {/* Formulario de Endereço */}
            <Address
              disableAddressInput={disableAddressInput}
              handleChange={handleChange}
              props={props}
              showOnMobile={true}
              status={status}
            />
            <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
              <FormControl
                color="success"
                fullWidth={true}
                size="small"
                sx={{ gap: "15px" }}
              >
                <Typography
                  color={TEXT_GRAY_700}
                  fontSize={"1.1rem"}
                  fontWeight={500}
                  textAlign={"center"}
                  variant="h2"
                >
                  Atividade principal
                </Typography>
                <Select
                  id="demo-simple-select"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: BORDER_GRAY_600,
                    },
                  }}
                  onChange={(e) => {
                    handleChange("business", e.target.value);
                  }}
                  defaultValue={1}
                  fullWidth
                >
                  <MenuItem value={1}>Agricultura</MenuItem>
                  <MenuItem value={2}>Pecuaria</MenuItem>
                  <MenuItem value={3}>As duas</MenuItem>
                </Select>
              </FormControl>
              <Typography
                color={TEXT_GRAY_700}
                fontSize={"0.8rem"}
                fontWeight={400}
                lineHeight={"0.9rem"}
                paddingX={"15px"}
              >
                Ao criar uma conta, você concorda com os{" "}
                <Link
                  color={TEXT_DARK_BLUE}
                  href="/useLicenseAgreement"
                  target="_blank"
                >
                  Termos de Contrato e uso
                </Link>{" "}
                Rural Business.
              </Typography>
            </Box>
            <Button
              disabled={loadingCpf}
              disableElevation
              fullWidth
              onClick={handleSubmit}
              type="submit"
              size="large"
              sx={{
                background: BUTTON_LIGHT_GREEN,
                height: "50px",
                ":hover": { bgcolor: BUTTON_DARK_GREEN },
              }}
              variant="contained"
            >
              Próximo passo
            </Button>
          </Box>
        </Box>
        {/* Formulario de Endereço que aparece somente em telas maiores */}
        <Address
          disableAddressInput={disableAddressInput}
          handleChange={handleChange}
          props={props}
          showOnMobile={false}
          status={status}
        />
      </Box>
    </>
  );
}

import { Button } from "@mui/material";
import { BUTTON_DARK_BLUE, BUTTON_LIME_GREEN } from "../../constants/colors";
import "../styles/planBtnStyle.scss";

export default function TabButton({ children, handleNavigate, color }: any) {
  return (
    <Button
      className="button_animation"
      variant="contained"
      size="large"
      color="success"
      sx={{
        width: "100%",
        padding: "15px 22px",
        borderRadius: "8px",
        ":hover": { bgcolor: BUTTON_DARK_BLUE },
        background: color ? color : BUTTON_LIME_GREEN,
      }}
      disableElevation
      onClick={handleNavigate}
    >
      {children}
    </Button>
  );
}

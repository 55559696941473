import ContentLoader from "react-content-loader";
import { Grid } from "@mui/material";

export default function PixLoading(props: any) {
  return (
    <>
      <Grid item xs={12} p={2}>
        <ContentLoader
          speed={2}
          width={400}
          height={460}
          viewBox="0 0 400 460"
          backgroundColor="#c9c9c9"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="14" y="14" rx="0" ry="0" width="299" height="24" />
          <rect x="14" y="54" rx="0" ry="0" width="299" height="24" />
          <rect x="14" y="95" rx="0" ry="0" width="299" height="29" />
          <rect x="14" y="156" rx="0" ry="0" width="299" height="245" />
        </ContentLoader>
      </Grid>
    </>
  );
}

import { UserAddress } from "./userAddress";

export interface User {
  id?: string;
  fullName: string;
  email: string;
  password?: string;
  confirmPassword?: string;
  cpf: string;
  phoneNumber: string;
  address: UserAddress;
  addressNumber?: string;
  business?: number;
  plan?: string | undefined;
  period?: string | undefined;
  createdAt?: string;
  updatedAt?: string;
}

export class UserHandler {
  public static getDefault(data: any = {}): User {
    return {
      id: data.id || null,
      fullName: data.fullName || "",
      phoneNumber: data.phoneNumber || "",
      address: {
        numberAddress: data.numberAddress || "",
        neighborhood: data.neighborhood || "",
        city: data.city || "",
        state: data.state || "",
        cep: data.cep || "",
        address: data.address || "",
      },
      addressNumber: data.addressNumber || "",
      email: data.email || "",
      password: data.password || "",
      business: data.business || 1,
      cpf: data.cpf || "",
      createdAt: data.createdAt || "",
      updatedAt: data.updatedAt || "",
    };
  }

  public static toDatabase(user: User): any {
    return [
      {
        cli_id: user.id,
        cli_nome: user.fullName,
        cli_telefone: user.phoneNumber,
        cli_rua: user.address.address,
        cli_numero: user.addressNumber,
        cli_bairro: user.address.neighborhood,
        cli_cidade: user.address.city,
        cli_estado: user.address.state,
        cli_cep: user.address.cep,
        cli_email: user.email,
        cli_senha: user.password,
        cli_atividade_princ: user.business,
        cli_celular: user.phoneNumber,
        cli_cpf_cnpj: user.cpf,
        cli_data_cadastro: user.createdAt,
        cli_data_modific: user.updatedAt,
      },
    ];
  }
}

import { useEffect, useState } from "react";
import { initMercadoPago, StatusScreen } from "@mercadopago/sdk-react";
import MyAccountFormButton from "../../Buttons/MyAccountFormButton";
import { UserDataLayout } from "../../../layouts";
import { UserDataComponent } from "..";
import { Loading } from "../../../assets/imgs";
import { PlanUserDto, PlanUserDtoHandler } from "../../../dtos/PlanUserDto";
import api from "../../../services/api";
import "./myPlan.scss";

export default function MyPlan() {
  const [loading, setLoading] = useState(true);
  const [planUser, setPlanUser] = useState<PlanUserDto>(
    PlanUserDtoHandler.getDefault()
  );
  const [cardextract, setCardextract] = useState<JSON>();

  initMercadoPago(process.env.REACT_APP_PUBLIC_KEY_MP || "", {
    locale: "pt-BR",
  });

  // Inicilizando Brick Mercado Pago
  const [loadingBrick, setLoadingBrick] = useState(true);

  const initialization = {
    paymentId: `${cardextract}`,
  };
  const onReady = async () => {
    setLoadingBrick(false);
  };
  const onError = async (e: any) => {
    console.log(e);
  };

  // const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>(PaymentMethodType.boleto);
  // const paymentMethodRef = useRef(paymentMethod);
  // const [planDetail, setPlanDetail] = useState<PlanDetailDto>(PlanDetailDtoHandler.getDefault());

  //puxando dados da API
  useEffect(() => {
    loadPlanUser();
  }, []);

  function loadPlanUser() {
    const token = localStorage.getItem("user");

    //Tirando aspa da string do token
    const tokenJWT = token?.substring(1, token?.length - 1);

    //Headers da API
    const headers = {
      headers: {
        "x-access-token": `${tokenJWT}`,
      },
    };

    setTimeout(() => {
      api
        .get("/contratos/situacao/", headers)
        .then((response) => {
          const user = response.data[0];
          setPlanUser(PlanUserDtoHandler.getByDataBase(user));
          loadExtractCard(Number(user.cont_id));
        })
        .catch((e: any) => {
          console.log("Erro ao carregar planos do usuário.");
          setLoadingBrick(false);
        });
    }, 2000);
  }
  //Puxa extrato do MP
  async function loadExtractCard(cont_id: Number) {
    const token = localStorage.getItem("user");
    //Tirando aspa da string do token
    const tokenJWT = token?.substring(1, token?.length - 1);
    //Headers da API
    const headers = {
      headers: {
        "x-access-token": `${tokenJWT}`,
      },
    };
    api.get(`/contratos/extrato/${cont_id}`, headers).then((response) => {
      //setPlanUser(PlanUserDtoHandler.getByDataBase(response.data[0]));
      setCardextract(response.data.pay_id_mp);
      if (response.data.pay_id_mp !== "") {
        setLoading(false);
      }
      setLoadingBrick(false);
    });
  }

  async function loadBoleto() {
    const cont_id = String(planUser.contract);
    const titulo_id = String(planUser.title);
    const body = { cont_id, titulo_id };

    await api
      .post("/cobrancas/geraBoleto", body, { responseType: "blob" })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        window.open(fileURL);
      })
      .catch(() => console.log("Erro ao buscar o detalhes do plano"));
  }

  const MercadoPagoBrick = () => {
    return (
      <StatusScreen
        initialization={initialization}
        onReady={onReady}
        onError={onError}
      />
    );
  };

  const RenderMercadoPago = () => {
    return <>{loadingBrick ? <Loading /> : <MercadoPagoBrick />}</>;
  };

  return (
    <>
      <UserDataLayout title={"Extrato financeiro"}>
        {/* Mercado pago */}
        <RenderMercadoPago />
        {/* Imprimir Segunda Via */}
        {!planUser.payment && !loading && (
          <MyAccountFormButton
            title={"Imprimir segunda via"}
            action={loadBoleto}
            disabled={planUser.payment !== 1}
          />
        )}
        <UserDataComponent />
      </UserDataLayout>
    </>
  );
}

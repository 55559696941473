import { Typography } from "@mui/material";
import { ContractsLayout } from "../../layouts";
import { CommitmentIMG1, CommitmentIMG2 } from "../../assets/imgs";

export default function RuralBusinessCommitment() {
  return (
    <>
      <ContractsLayout
        title={"Rural Business e o Compromisso com seus Assinantes"}
        gap={2}
      >
        <Typography>
          <p>
            Em 1990 a RURAL BUSINESS surgiu se posicionando como a primeira
            agência provedora de informações estratégicas para o Agronegócio,
            com o objetivo de olhar de forma investigativa e profissional o dia
            a dia do mercado físico de grãos e de carnes - no Brasil e no mundo.
          </p>
          <p>
            Mercados que durante décadas foram e estão sendo bombardeadas
            diariamente com informações longe da realidade ou sem uma
            investigação profissional para que se possa ver o que de fato está
            acontecendo e pode vir acontece no curto, médio e longo prazo.
          </p>{" "}
          <p>
            Foi com esta visão, de sempre investigar a verdade por trás dos
            boatos, que denunciamos a farsa dos embargos às exportações de carne
            bovina do Brasil em 2005, que abalou o mercado pecuário do país, por
            conta da descoberta de um foco de febre aftosa.
          </p>{" "}
        </Typography>
        <Typography>
          Enquanto a grande mídia falava em caos para o comércio internacional
          do país, envolvida numa teia de informação muito bem articulada por
          grandes frigoríficos e governo, a RURAL BUSINESS alertava que tudo não
          passava de pura pressão, a fim de derrubar os preços da arroba e
          garantir maior lucro para as empresas.
        </Typography>
        <Typography>
          A mentira aos poucos foi vindo à tona, com os resultados de exportação
          confirmando recordes sequentes de vendas para o país, jogando por
          terra à história de que 60 países teriam embargado a entrada de
          produto brasileiro em seu território.
        </Typography>
        <img style={{ maxWidth: "400px" }} src={CommitmentIMG1} alt="" />
        <Typography>
          Em 2017 a RURAL BUSINESS se posicionou a partir do primeiro dia da
          chamada OPERAÇÃO CARNE FRACA que investiga corruptos e corruptores no
          mercado de carnes no Brasil e após as denuncias do maior escândalo de
          corrupção do mundo com as delações da cúpula da JBS que as exportações
          de carne bovina no Brasil ao contrario do que dizia a mídia não seriam
          afetadas e mais uma vez a RURAL BUSINESS estava certa já que novos
          recordes de exportação do produto foram quebrados.
        </Typography>
        <Typography>
          O mesmo acontece com o setor agrícola, com a Equipe de Grãos da RURAL
          BUSINESS se posicionando firmemente e alertando produtores e demais
          agentes para as manobras envolvendo dados fictícios de oferta, demanda
          e estoques de passagem, tanto para o Brasil como para outros grandes
          países, como Estados Unidos, com o único objetivo de derrubar os
          preços no mercado físico.
        </Typography>
        <Typography>
          Foi assim que em meados deste ano de 2017 alertamos aos produtores de
          milho: Segurem a oferta que os preços vão subir! Assumindo os riscos,
          a RURAL BUSINESS mostrou, através de muita investigação, que as
          exportações romperiam todos as expectativas, virando o mercado do
          avesso no último trimestre do ano. E foi justamente o que aconteceu,
          tirando os produtores do prejuízo para o lucro!
        </Typography>
        <img style={{ maxWidth: "400px" }} src={CommitmentIMG2} alt="" />
        <Typography>
          O mercado do milho assistiu a uma das mais espetaculares escaladas de
          recuperação de todos os tempos, comemorando alta de 30% em apenas 3
          meses. Em algumas praças, como Goiás, os produtores venderam cada saca
          de milho por valor 42% acima das mínimas de julho, garantindo 7,50
          reais a mais no seu bolso.
        </Typography>
        <Typography>
          Nestes 26 anos de atuação o maior propósito da RURAL BUSINESS foi [e
          continua sendo] tirar “o chão” do agronegócio brasileiro da inocência
          mercadológica, a fim de reduzir riscos e aumentar as chances de lucro.
          Temos como grande objetivo “antecipar o amanhã do agronegócio, hoje”,
          como bem diz nossa campanha publicitaria nas mídias sócias, emissoras
          de TV e revistas.
        </Typography>
        <Typography>
          Com total imparcialidade, amplo pacote diário de informação
          profissional e muito trabalho investigativo, a RURAL BUSINESS garante
          aos seus assinantes ferramentas que de fato ampliam a capacidade de
          prever movimentos futuros, separar boatos dos fatos, para que em tempo
          hábil para agir, operadores deste mercado evitem prejuízos.
        </Typography>
        <Typography>
          Esse é o compromisso que temos com os nossos assinantes há mais de 27
          anos!
        </Typography>
      </ContractsLayout>
    </>
  );
}

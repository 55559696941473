import { Typography } from "@mui/material";
import { TEXT_LIGHT_BLUE } from "../../constants/colors";
import { ContractsLayout } from "../../layouts";

export default function CookiesPolicy() {
  return (
    <>
      <ContractsLayout title={"Políticas de Cookies"} gap={2}>
        <Typography>
          A presente Politica de Cookies é um documento complementar à Politica
          de Privacidade da Rural Business, disponível neste link:{" "}
          <Typography
            display={"inline"}
            sx={{
              ":hover": {
                textDecoration: "underline",
              },
              display: "inline",
              color: TEXT_LIGHT_BLUE,
            }}
          >
            <a href="/privacyPolicy">ruralbusiness.com.br/privacyPolicy</a>
          </Typography>
          . Aqui, você encontrará informações objetivas e claras sobre o que são
          Cookies, quais Cookies utilizamos em nossas aplicações, qual papel
          desempenham e como configurá-los.
        </Typography>
        <Typography>
          <ol
            style={{
              listStyleType: "none",
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <li>
              <strong>1. O que são Cookies?</strong>
              <p>
                Cookies são pequenos arquivos de texto ou fragmentos de
                informação que são baixados em seu computador, smartphone ou
                qualquer outro dispositivo com acesso à internet quando você
                visita nossa aplicação Eles contém informações sobre a sua
                navegação em nossas páginas e retém apenas informações
                relacionadas a suas preferências. Assim, essa página consegue
                armazenar e recuperar os dados sobre os seus hábitos de
                navegação, de forma a melhorar a experiência de uso, por
                exemplo. É importante frisar que eles não contém informações
                pessoais específicas, como dados sensíveis ou bancários O seu
                navegador armazena os cookies no disco rígido, mas ocupam um
                espaço de memòria mínimo, que não afetam o desempenho do seu
                computador. A maioria das informações são apagadas logo ao
                encerrar a sessão, como você verá no próximo tópico.
              </p>
              <ol style={{ listStyleType: "none" }}>
                <li>
                  <strong>1.1. Tipos de Cookies</strong>
                  <Typography display={"flex"} flexDirection={"column"} gap={1}>
                    <ol
                      style={{
                        listStyleType: "lower-alpha",
                        listStylePosition: "inside",
                      }}
                    >
                      <p>Os cookies, quanto a seus proprietários, podem ser:</p>
                      <li>
                        <strong>Cookies proprietários</strong>:
                        <p>
                          são cookies definidos por nos ou por terceiros em
                          nosso nome
                        </p>
                      </li>
                      <li>
                        <strong>Cookies de terceiros</strong>:
                        <p>
                          são cookies definidos por terceiros confiáveis em
                          nossa aplicação
                        </p>
                      </li>
                    </ol>
                    <ol
                      style={{
                        listStyleType: "lower-alpha",
                        listStylePosition: "inside",
                      }}
                    >
                      <p>Os cookies quanto ao seu tempo de vida, podem ser</p>{" "}
                      <li>
                        <strong>Cookies de sessão ou temporários</strong>:{" "}
                        <p>
                          são cookies que expiram assim que você fecha o seu
                          navegador, encerrando a sessão
                        </p>
                      </li>{" "}
                      <li>
                        <strong>Cookies persistentes ou permanentes</strong>:{" "}
                        <p>
                          são cookies que permanecem no seu dispositivo durante
                          um periodo determinado ou até que você os exclua
                        </p>
                      </li>{" "}
                    </ol>
                    <ol
                      style={{
                        listStyleType: "lower-alpha",
                        listStylePosition: "inside",
                      }}
                    >
                      <p>Os cookies, quanto a sua finalidade, podem ser</p>
                      <li>
                        <strong>Cookies necessários</strong>:{" "}
                        <p>
                          são cookies essencials que possibilitam a navegação em
                          nossas aplicações e o acesso a todos os recursos; se
                          estes, nossos serviços podem apresentar mal desempenho
                          ou não funcionar.
                        </p>
                      </li>{" "}
                      <li>
                        <strong>Cookies de desempenho</strong>:{" "}
                        <p>
                          são cookies que otimizam a forma que nossas aplicações
                          funcionam, coletando informações anónimas sobre as
                          páginas acessadas.
                        </p>
                      </li>{" "}
                      <li>
                        <strong>Cookies de funcionalidade</strong>:{" "}
                        <p>
                          são cookies que memorizam suas preferências e escolhas
                          (como seu nome de usuário)
                        </p>
                      </li>
                      <li>
                        <strong>Cookies de publicidade</strong>:{" "}
                        <p>
                          são cookies que direcionam anúncios em função dos seus
                          interesses e limitam a quantidade de vezes que o
                          anúncio aparece
                        </p>
                      </li>
                    </ol>
                  </Typography>
                </li>
              </ol>
            </li>
            <li>
              <strong>2. Por que usamos Cookies?</strong>
              <p>
                A Rural Business utiliza Cookies para fornecer a melhor
                experiência de uso, tornando nossas aplicações mais fáceis e
                personalizadas, tendo por base suas escolhas e comportamento de
                navegação. Assim, buscamos entender como você utiliza nossas
                aplicações e ajustar o conteúdo para torná-lo mais relevante
                para você, além de lembrar de suas preferências. Os Cookies
                participam deste processo porquanto armazenam, leem e executam
                os dados necessários para cumprir com o nosso objetivo.
              </p>
            </li>
            <strong>3. Que tipo de Cookies utilizamos?</strong>{" "}
            <p>
              Abaixo listamos todos os Cookies que podem ser utilizados pela
              Rural Business. É importante lembrar que você pode gerenciar a
              permissão concedida para cada Cookie em seu navegador. Além disso,
              uma vez que os Cookies capturam dados sobre você, aconselhamos a
              leitura de nossa Política de Privacidade, disponível neste link:{" "}
              <Typography
                display={"inline"}
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                  },
                  display: "inline",
                  color: TEXT_LIGHT_BLUE,
                }}
              >
                <a href="/privacyPolicy">
                  http://ruralbusiness.com.br/privacyPolicy
                </a>
              </Typography>
            </p>
            <ol
              style={{
                listStyleType: "none",
              }}
            >
              <li>
                <strong>3.1. Cookies necessários </strong>
                <div style={{ overflowX: "auto" }}>
                  <table style={{ borderCollapse: "collapse" }}>
                    <tbody>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <strong>Cookie</strong>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <strong>Tempo</strong>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <strong>Propriedade</strong>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <strong>Descrição</strong>
                            <u> </u>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>purecookieDismiss</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Permanente</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Proprietário</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Aceite das políticas de cookies</em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>ASPSESSIONIDCASSATQB</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Sessão</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Proprietário</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Validação de login</em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>ASPSESSIONIDCESSATQB</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Sessão</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Proprietário</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Validação de login</em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>__gads</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Permanente</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google Analytics</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>
                              Usado para experimentar eficiência de publicidade.
                            </em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>_ga</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Persistente</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google Analytics</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>
                              Cookie para análise da experiência do usuário em
                              nossa plataforma utilizando IDs anônimos
                            </em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>_gat</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Temporário</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google Analytics</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>
                              Usado para limitar a taxa de solicitação. Se o
                              Google Analytics for implantado pelo Gerenciador
                              de tags do Google, esse cookie será chamado de
                              _dc_gtm_&lt;property- id&gt;.
                            </em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>_gid</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Temporário</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google Analytics</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>
                              Cookie para análise da experiência do usuário em
                              nossa plataforma utilizando IDs anônimos
                            </em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>vuid</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Temporário</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Vimeo</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>
                              Otimização nas visualizações dos nossos vídeos
                              providos pela VIMEO.
                            </em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>1P_JAR</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>&nbsp;</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google Adsense</em>
                          </p>
                        </td>
                        <td
                          style={{ border: "1px solid", padding: 3 }}
                          rowSpan={7}
                        >
                          <p>
                            <em>
                              Cookies para uso de entrega de mídia customizada
                              para o usuário.Mais informações sobre o uso de
                              cookies pelo Google podem ser encontradas em:1.
                              How Google Use
                              Cookies:https://policies.google.com/technologies/cookies/;2.
                              Types of Cookies Uses By Google:
                              https://policies.google.com/technologies/types/.
                            </em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>ANID</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>&nbsp;</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google Adsense</em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>CGIC</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>&nbsp;</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google Adsense</em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>CGIC</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>&nbsp;</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google Adsense</em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>DV</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>&nbsp;</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google Adsense</em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>NID</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>&nbsp;</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google Adsense</em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>SNID</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>&nbsp;</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google Adsense</em>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>_GRECAPTCHA</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Temporário</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Google reCAPTCHA</em>
                          </p>
                        </td>
                        <td style={{ border: "1px solid", padding: 3 }}>
                          <p>
                            <em>Usado para proteção anti-span</em>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ol>
            <li>
              <strong>4. Gerenciamento dos Cookies </strong>
              <p>
                A instalação dos cookies está sujeita ao seu consentimento.
                Apesar da maioria dos navegadores estar inicialmente configurada
                para aceitar cookies de forma automática, você pode rever suas
                permissões a qualquer tempo, de forma a bloqueá-los, aceitá-los
                ou ativar notificações para quando alguns cookies forem enviados
                ao seu dispositivo. Atualmente, na primeira vez que você acessa
                nossas aplicações, será requerida a sua concordância com a
                instalação destes. Apenas após a sua aceitação eles serão
                ativados. Para tanto, utilizamos um sistema de alerta de
                confirmação na página inicial da Rural Business. Dessa maneira,
                não apenas solicitamos sua concordância, mas também informamos
                que a navegação continuada em nossos sites será entendida como
                consentimento. Como já dito, você pode, a qualquer tempo e sem
                nenhum custo, alterar as permissões, bloquear ou recusar os
                Cookies. Você também pode configurá-los caso a caso. Todavia, a
                revogação do consentimento de determinados Cookies pode
                inviabilizar o funcionamento correto de alguns recursos da
                plataforma. Para gerenciar os cookies do seu navegador, basta
                fazê-lo diretamente nas configurações do navegador, na área de
                gestão de Cookies. Você pode acessar tutoriais sobre o tema
                diretamente nos links abaixo:
              </p>
              <Typography>
                Se você usa o{" "}
                <Typography
                  sx={{
                    display: "inline",
                    color: TEXT_LIGHT_BLUE,
                    ":hover": { textDecoration: "underline" },
                  }}
                >
                  <a
                    href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank"
                  >
                    Internet Explorer
                  </a>
                </Typography>
              </Typography>
              <Typography>
                Se você usa o{" "}
                <Typography
                  sx={{
                    display: "inline",
                    color: TEXT_LIGHT_BLUE,
                    ":hover": { textDecoration: "underline" },
                  }}
                >
                  <a
                    href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s"
                    target="_blank"
                  >
                    Firefox
                  </a>
                </Typography>
              </Typography>
              <Typography>
                Se você usa o{" "}
                <Typography
                  sx={{
                    display: "inline",
                    color: TEXT_LIGHT_BLUE,
                    ":hover": { textDecoration: "underline" },
                  }}
                >
                  <a
                    href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                    target="_blank"
                  >
                    Safari
                  </a>
                </Typography>
              </Typography>
              <Typography>
                Se você usa o{" "}
                <Typography
                  sx={{
                    display: "inline",
                    color: TEXT_LIGHT_BLUE,
                    ":hover": { textDecoration: "underline" },
                  }}
                >
                  <a
                    href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;oco=1&amp;hl=pt-BR"
                    target="_blank"
                  >
                    Google Chrome
                  </a>
                </Typography>
              </Typography>
              <Typography>
                Se você usa o{" "}
                <Typography
                  sx={{
                    display: "inline",
                    color: TEXT_LIGHT_BLUE,
                    ":hover": { textDecoration: "underline" },
                  }}
                >
                  <a
                    href="https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies"
                    target="_blank"
                  >
                    Microsoft Edge
                  </a>
                </Typography>
              </Typography>
              <Typography>
                Se você usa o{" "}
                <Typography
                  sx={{
                    display: "inline",
                    color: TEXT_LIGHT_BLUE,
                    ":hover": { textDecoration: "underline" },
                  }}
                >
                  <a
                    href="https://help.opera.com/en/latest/web-preferences/#cookies"
                    target="_blank"
                  >
                    Opera
                  </a>
                </Typography>
              </Typography>
            </li>
            <li>
              <strong>5. Disposições finais </strong>
              Para a Rural Business, a privacidade e confiança são fundamentais
              para a nossa relação com você. Estamos sempre nos atualizando para
              manter os mais altos padrões de segurança Assim, reservamo-nos o
              direito de alterar esta Política de Cookies a qualquer tempo. As
              mudanças entrarão em vigor logo após a publicação, e você será
              avisado. Ao continuar a navegação nas nossas aplicações após essa
              mudança se tornar eficaz, você concorda com elas. Aconselhamos que
              você sempre verifique esta Política, bem como a nossa Política de
              Privacidade. Em caso de dúvidas sobre esta Política de Cookies,
              entre em contato conosco pelos seguintes meios:
              <ul style={{ listStyleType: "disc", listStyle: "inside" }}>
                <li>
                  <em>
                    Meio de contato:{" "}
                    <a href="mailto:desenvolvimento@ruralbusiness.com.br">
                      desenvolvimento@ruralbusiness.com.br
                    </a>
                  </em>
                </li>
                <li>
                  Esta Política de Cookies foi atualizada pela última vez:
                  06/07/2021.
                </li>
              </ul>
            </li>
          </ol>
        </Typography>
      </ContractsLayout>
    </>
  );
}

import api from "../../services/api";
import { getHeaders } from "./auth";

export default async function getSeries(setSeriesData, getEpisodes) {
  await api
    .get("/videos/series/", getHeaders())
    .then((response) => {
      setSeriesData(response.data.reverse());
      getEpisodes(response.data[0].serie_id);
    })
    .catch(() => console.log("Erro ao autenticar o usuário"));
}

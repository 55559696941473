import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AboutComponent,
  AccessComponent,
  CountdownTimer,
  PlansComponent,
  ProductComponent,
  WelcomeComponent,
} from '../../components/Home';
import { Box } from '@mui/material';
import { pageView } from '../../utils/facebookPixel/facebookPixelEvent';
import { BACKGROUND_WHITE } from '../../constants/colors';
import '../styles/homeStyle.scss';
import '../styles/media.scss';
import { Close, ShoppingCartRounded } from '@mui/icons-material';
import ButtonOutline from '../../components/Buttons/ButtonOutline';
import Modal from "react-modal";

export default function Home() {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    const value = localStorage.getItem("promotionModal");
    if (value === "show") {
      setIsOpen(true);
    }
  }

  function closeModal(event: any) {
    event.preventDefault();
    localStorage.setItem("promotionModal", "dontShow");
    setIsOpen(false);
  }
  // Verificando origem de publicidade
  const { ad } = useParams();
  // reseta LocalStorage e verifica se o ad não é undefined
  //localStorage.removeItem("ad");
  if (ad !== undefined) {
    const publi = ad;
    localStorage.setItem('ad', String(publi));
  }
  useEffect(() => {
    //Facebook Pixel Tracking
    pageView();
    openModal();
  }, []);

  const plansCountdownEnv = process.env.REACT_APP_PLANS_COUNTDOWN ?? "";
  const plansCountdown = plansCountdownEnv ? JSON.parse(plansCountdownEnv) : "";

  const modalCustomEnv = process.env.REACT_APP_MODAL_CUSTOM ?? "";
  const modalCustom = modalCustomEnv ? JSON.parse(modalCustomEnv) : "";

  return (
    <Box component={'main'} bgcolor={BACKGROUND_WHITE}>
      {/* Modal de promoções */}
      {
        modalCustom.active &&
        <Modal
        isOpen={modalIsOpen}
        onResquestClose={closeModal}
        ariaHideApp={false}
        contentLabel="Example modal"
        overlayClassName="modal-overlay"
        className="w-4/5 max-w-3xl relative rounded-lg flex flex-col justify-center p-5 bg-gray-50"
      >
        <Close
          onClick={closeModal}
          fontSize="large"
          className="place-self-end cursor-pointer"
        />
        <div className="flex flex-col justify-center items-center">
          <div className="flex items-center justify-center flex-col">
            <h2 className="text-center font-helvetica text-3xl font-black uppercase text-zinc-900 lg:text-4xl xl:text-5xl">
              {modalCustom.title}
            </h2>
            <h2 className="text-center w-fit bg-gradient-to-r from-yellow-400 to-red-600 bg-clip-text font-helvetica text-2xl font-black uppercase text-transparent lg:text-3xl xl:text-4xl">
              {modalCustom.subtitle}
            </h2>
          </div>
          {plansCountdown.active && <div className="w-full my-7 flex items-center justify-center">
          <CountdownTimer title={plansCountdown.title} limitDate={plansCountdown.limit_date} titleFont='text-2xl' />
          </div>}
        </div>
        <ButtonOutline icon={<ShoppingCartRounded fontSize="medium" />} onClick={() => {
          localStorage.setItem("promotionModal", "dontShow");
          navigate(`${modalCustom.navigate}`);
        }} className={"bg-gradient-to-r mt-10 from-yellow-400 to-red-600 text-white text-lg"} link={undefined}>
          {modalCustom.buttonText}
        </ButtonOutline>
      </Modal>}
      <WelcomeComponent />
      <AccessComponent />
      <AboutComponent />
      <ProductComponent />
      <PlansComponent />
    </Box>
  );
}

export interface UserCard {
  id: string;
  title: string;
  cardNumber: string;
  cardName: string;
  cardValidity: string;
  cardMonth?: string;
  cardYear?: string;
  cardCod: string;
  portion: string;
  termCard?: boolean;
}

export class UserCardHandler {
  public static getDefault(data: any  = {}): UserCard {
    return {
      id: data.id || '',
      title: data.title || '',
      cardNumber: data.cardNumber || '',
      cardName: data.cardName || '',
      cardValidity: data.cardValidity || '',
      cardMonth: data.cardMonth || '',
      cardYear: data.cardYear || '',
      cardCod: data.cardCod || '',
      portion: data.portion || '',
      termCard: data.termCard || true,
    }
  }

  public static toDatabase(userCard: UserCard): any {
    return [{
      cont_id: userCard.id,
      titulo_id: userCard.title,
      cartao_numero: userCard.cardNumber,
      cartao_nome:  userCard.cardName,
      cartao_validade: userCard.cardValidity,
      cartao_codigo: userCard.cardCod,
      plan_parcela: userCard.portion,
    }]
  }
}

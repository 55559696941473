import ContentLoader from "react-content-loader"

export default function CardLoading() {
    return (
        <>
            <ContentLoader 
                speed={2}
                width={385}
                height={250}
                viewBox="0 0 385 250"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="2" y="19" rx="3" ry="3" width="410" height="15" /> 
                <rect x="4" y="45" rx="3" ry="3" width="410" height="15" /> 
                <rect x="5" y="88" rx="3" ry="3" width="75" height="15" /> 
                <rect x="100" y="88" rx="3" ry="3" width="75" height="15" /> 
                <rect x="253" y="89" rx="3" ry="3" width="75" height="15" /> 
                <rect x="4" y="130" rx="3" ry="3" width="110" height="15" /> 
                <rect x="149" y="128" rx="3" ry="3" width="110" height="15" /> 
                <rect x="1" y="167" rx="3" ry="3" width="410" height="15" /> 
                <rect x="-1" y="208" rx="3" ry="3" width="410" height="15" />
            </ContentLoader>
        </>
    )
}
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import moment from "moment";
import { PlanUserDto, PlanUserDtoHandler } from "../../dtos/PlanUserDto";
import { UserDto, UserDtoHandler } from "../../dtos";
import api from "../../services/api";
import { Loading } from "../../assets/imgs";
import { TEXT_BLACK, TEXT_RED } from "../../constants/colors";

export default function UserDataComponent() {
  const navigate = useNavigate();

  const [processing, setProcessing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [planUser, setPlanUser] = useState<PlanUserDto>(
    PlanUserDtoHandler.getDefault()
  );
  const planUserRef = useRef(planUser);

  const [getUser, setGetUser] = useState<UserDto>(UserDtoHandler.getDefault());
  const getUserRef = useRef(getUser);

  const token = localStorage.getItem("user");
  //Tirando aspa da string do token
  const tokenJWT = token?.substring(1, token?.length - 1);

  //Headers da API
  const headers = {
    headers: {
      "x-access-token": `${tokenJWT}`,
    },
  };

  //puxando dados da API
  useEffect(() => {
    const loadAxios = async () => {
      await loadUser();
      await loadPlanUser();
    };
    loadAxios();
  }, []);

  async function loadUser() {
    setProcessing(true);
    setLoading(true);

    const token = localStorage.getItem("user");

    //Tirando aspa da string do token
    const tokenJWT = token?.substring(1, token?.length - 1);

    //Headers da API
    const headers = {
      headers: {
        "x-access-token": `${tokenJWT}`,
      },
    };

    await api
      .get("/clientes/cadastro", headers)
      .then((response) => {
        const newGetUser = UserDtoHandler.getByDatabase(response.data[0]);
        getUserRef.current = newGetUser;
        setGetUser(getUserRef.current);
        setLoading(false);
      })
      .catch(() => console.log("Erro ao carregar planos do usuário."))
      .finally(() => setProcessing(false));
  }

  async function loadPlanUser() {
    setProcessing(true);
    setLoading(true);

    const token = localStorage.getItem("user");

    //Tirando aspa da string do token
    const tokenJWT = token?.substring(1, token?.length - 1);

    //Headers da API
    const headers = {
      headers: {
        "x-access-token": `${tokenJWT}`,
      },
    };

    await api
      .get("/contratos/situacao/", headers)
      .then((response) => {
        setLoading(true);
        const newPlanUser = PlanUserDtoHandler.getByDataBase(response.data[0]);
        planUserRef.current = newPlanUser;
        setPlanUser(planUserRef.current);
        setLoading(false);
      })
      .catch(() => console.log("Erro ao carregar planos do usuário."))
      .finally(() => setProcessing(false));
  }

  function newDate(date: any) {
    return moment(date).utc().format("DD/MM/YYYY");
  }

  function handleTextButton(status: any) {
    if (status === "") return "Finalizar Pagamento";
    if (status === "Vencido") return "Renovar Pagamento";

    return "Alterar plano/Pagamento";
  }

  function handleNavigate(status: any) {
    if (status === "") return `/planos/${getUserRef.current.id}`;
    return `/dashboard/renewPlan/${planUserRef.current.id}/${planUserRef.current.contract}/${planUserRef.current.title}`;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div
            className={
              planUserRef.current.contract === null ? "divOff" : "box_userData"
            }
          >
            <p className="campo_userData">Nº contrato: </p>
            <p className="response_userData">{planUserRef.current.contract}</p>
          </div>
          <div
            className={
              planUserRef.current.planName === "" ? "divOff" : "box_userData"
            }
          >
            <p className="campo_userData">Plano: </p>
            <p className="response_userData">{planUserRef.current.planName}</p>
          </div>
          <div
            className={
              planUserRef.current.planPeriod === "" ? "divOff" : "box_userData"
            }
          >
            <p className="campo_userData">Periodo contratado: </p>
            <p className="response_userData">
              {planUserRef.current.planPeriod}
            </p>
          </div>
          <div
            className={
              planUserRef.current.value === null ? "divOff" : "box_userData"
            }
          >
            <p className="campo_userData">Valor: </p>
            <p className="response_userData">R$ {planUserRef.current.value}</p>
          </div>
          <div
            className={
              planUserRef.current.dueDayPlan === "" ? "divOff" : "box_userData"
            }
          >
            <p className="campo_userData">Vencimento do plano: </p>
            <p className="response_userData">
              {newDate(planUserRef.current.dueDayPlan)}
            </p>
          </div>
          <div
            className={
              planUserRef.current.situation === "" ? "divOff" : "box_userData"
            }
          >
            <p className="campo_userData">Situação do Contrato: </p>
            <p
              className="response_userData"
              style={
                planUserRef.current.situation !== "Pago"
                  ? { color: TEXT_RED }
                  : { color: TEXT_BLACK }
              }
            >
              {planUserRef.current.situation}{" "}
              {planUser.payDay !== "" ? ` - ${newDate(planUser.payDay)}` : ``}
            </p>
          </div>
          <div
            className={
              planUserRef.current.planName === "" ? "box_userData" : "divOff"
            }
          >
            <p
              className="campo_userData"
              style={{ textAlign: "center" }}
            >{`Olá, ${getUserRef.current.fullName}. Seu pagamento não foi finalizado.`}</p>
          </div>
          <div
            className={
              planUserRef.current.situation === "Pago" ? "divOff" : "divOn"
            }
          >
            <Button
              variant="contained"
              size="large"
              color={
                planUserRef.current.situation !== "Pago" ? "error" : "success"
              }
              sx={{ mt: 2 }}
              fullWidth
              disableElevation
              onClick={() =>
                navigate(handleNavigate(planUserRef.current.planName))
              }
            >
              {handleTextButton(planUserRef.current.situation)}
            </Button>
          </div>
        </>
      )}
    </>
  );
}

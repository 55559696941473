import { Typography } from "@mui/material";
import { ContractsLayout } from "../../layouts";

export default function PrivacyPolicy() {
  return (
    <ContractsLayout title={"Políticas de Privacidade"} gap={2}>
      <Typography fontSize={"1rem"}>
        <ol
          style={{
            listStyleType: "none",
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <li>
            <Typography fontWeight={"bold"}>
              SEÇÃO 1 - INFORMAÇÕES GERAIS
            </Typography>
            <Typography>
              A presente Política de Privacidade contém informações sobre
              coleta, uso, armazenamento, tratamento e proteção dos dados
              pessoais dos usuários e visitantes do aplicativo OU RURAL BUSINESS
              OU/E site ruralbusiness.com.br e rbvideo.com.br, com a finalidade
              de demonstrar absoluta transparência quanto ao assunto e
              esclarecer a todos interessados sobre os tipos de dados que são
              coletados, os motivos da coleta e a forma como os usuários podem
              gerenciar ou excluir as suas informações pessoais. Esta Política
              de Privacidade aplica-se a todos os usuários e visitantes do OU
              aplicativo RURAL BUSINESS OU site ruralbusiness.com.br e
              rbvideo.com.br e integra os Termos e Condições Gerais de Uso do OU
              aplicativo RURAL BUSINESS OU site ruralbusiness.com.br e
              rbvideo.com.br, devidamente inscrita no CNPJ sob o nº
              04.033.423/0001-69, situado em Campo Grande – MS (Av. Primeiro de
              maio, 620 – bairro jardim São Bento), doravante nominada RURAL
              NETWORK LTDA O presente documento foi elaborado em conformidade
              com a Lei Geral de Proteção de Dados Pessoais (
              <a
                href="https://www.jusbrasil.com.br/legislacao/612902269/lei-13709-18"
                target="_blank"
              >
                Lei 13.709/18
              </a>
              ), o{" "}
              <a
                href="https://www.jusbrasil.com.br/legislacao/117197216/lei-12965-14"
                target="_blank"
              >
                Marco Civil da Internet
              </a>{" "}
              (
              <a
                href="https://www.jusbrasil.com.br/legislacao/117197216/lei-12965-14"
                target="_blank"
              >
                Lei 12.965/14
              </a>
              ) (e o Regulamento da UE n. 2016/6790). Ainda, o documento poderá
              ser atualizado em decorrência de eventual atualização normativa,
              razão pela qual se convida o usuário a consultar periodicamente
              esta seção.
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>
              SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO
              VISITANTE?
            </Typography>
            <Typography>
              Os dados pessoais do usuário e visitante são recolhidos pela
              plataforma da seguinte forma:
            </Typography>
            <ul
              style={{
                listStyleType: "disc",
                listStyle: "inside",
                marginLeft: "2rem",
              }}
            >
              <li>
                Quando o usuário cria uma conta/perfil na plataforma RURAL
                BUSINESS: esses dados são os dados de identificação básicos,
                como NOME, E-MAIL, ENDEREÇO, TELEFONE, CPF. A partir deles,
                podemos identificar o usuário e o visitante, além de garantir
                uma maior segurança e bem-estar às suas necessidades. Ficam
                cientes os usuários e visitantes de que seu perfil na plataforma
                não estará acessível a todos demais usuários e visitantes da
                plataforma RURAL BUSINESS.
              </li>
              <li>
                Quando um usuário e visitante acessa OU páginas do site
                ruralbusiness.com.br e rbvideo.com.br OU o aplicativo RURAL
                BUSINESS: as informações sobre interação e acesso são coletadas
                pela empresa para garantir uma melhor experiência ao usuário e
                visitante. Estes dados podem tratar sobre as palavras-chaves
                utilizadas em uma busca, o compartilhamento de um documento
                específico, comentários, visualizações de páginas, perfis, a URL
                de onde o usuário e visitante provêm, o navegador que utilizam e
                seus IPs de acesso, dentre outras que poderão ser armazenadas e
                retidas.
              </li>
            </ul>
          </li>
          <li>
            <Typography fontWeight={"bold"}>
              SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E
              VISITANTE?
            </Typography>
            <Typography>
              Os dados pessoais do usuário e visitante recolhidos são os
              seguintes:
            </Typography>
            <ul
              style={{
                listStyleType: "disc",
                listStyle: "inside",
                marginLeft: "2rem",
              }}
            >
              <li>
                Dados para a criação da conta/perfil na plataforma RURAL
                BUSINESS: NOME, TELEFONE, CPF, ENDEREÇO.
              </li>
              <li>
                Dados para otimização da navegação: IP, PALAVRAS CHAVES E
                PAGINAS ACESSADAS DENTRO DA PLATAFORMA.
              </li>
              <li>
                Newsletter: o e-mail cadastrado pelo visitante que optar por se
                inscrever na Newsletter será coletado e armazenado até que o
                usuário solicite o descadastro.
              </li>
              <li>
                Dados relacionados a contratos: diante da formalização do
                contrato de compra e venda ou de prestação de serviços entre a
                plataforma e o usuário e visitante poderão ser coletados e
                armazenados dados relativos a execução contratual, inclusive as
                comunicações realizada entre a empresa e o usuário.
              </li>
            </ul>
            <Typography>
              O tratamento de dados pessoais para finalidades não previstas
              nesta Política de Privacidade somente ocorrerá mediante
              comunicação prévia ao usuário, de modo que os direitos e
              obrigações aqui previstos permanecem aplicáveis.
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>
              SEÇÃO 4 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?
            </Typography>
            <Typography>
              Os dados pessoais do usuário e visitante são armazenados pela
              plataforma durante o período necessário para a prestação do
              serviço ou o cumprimento das finalidades previstas no presente
              documento, conforme o disposto no{" "}
              <a
                href="https://www.jusbrasil.com.br/topicos/200399061/inciso-i-do-artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018"
                target="_blank"
              >
                inciso I
              </a>{" "}
              do{" "}
              <a
                href="https://www.jusbrasil.com.br/topicos/200399064/artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018"
                target="_blank"
              >
                artigo 15
              </a>{" "}
              da{" "}
              <a
                href="https://www.jusbrasil.com.br/legislacao/612902269/lei-13709-18"
                target="_blank"
              >
                Lei 13.709/18
              </a>
              . Os dados podem ser removidos ou anonimizados a pedido do
              usuário, excetuando os casos em que a lei oferecer outro
              tratamento. Ainda, os dados pessoais dos usuários apenas podem ser
              conservados após o término de seu tratamento nas seguintes
              hipóteses previstas no artigo 16 da referida lei:
            </Typography>
            <ol
              style={{
                listStyle: "inside",
                listStyleType: "upper-roman",
                marginLeft: "2rem",
              }}
            >
              <li>
                cumprimento de obrigação legal ou regulatória pelo controlador;
              </li>
              <li>
                estudo por órgão de pesquisa, garantida, sempre que possível, a
                anonimização dos dados pessoais;
              </li>
              <li>
                transferência a terceiro, desde que respeitados os requisitos de
                tratamento de dados dispostos nesta Lei;
              </li>
              <li>
                uso exclusivo do controlador, vedado seu acesso por terceiro, e
                desde que anonimizados os dados.
              </li>
            </ol>
          </li>
          <li>
            <Typography fontWeight={"bold"}>
              SEÇÃO 5 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS
            </Typography>
            <Typography>
              A plataforma se compromete a aplicar as medidas técnicas e
              organizativas aptas a proteger os dados pessoais de acessos não
              autorizados e de situações de destruição, perda, alteração,
              comunicação ou difusão de tais dados. Os dados relativas a cartões
              de crédito são criptografados usando a tecnologia "secure socket
              layer" (SSL) que garante a transmissão de dados de forma segura e
              confidencial, de modo que a transmissão dos dados entre o servidor
              e o usuário ocorre de maneira cifrada e encriptada. A plataforma
              não se exime de responsabilidade por culpa exclusiva de terceiro,
              como em caso de ataque de hackers ou crackers, ou culpa exclusiva
              do usuário, como no caso em que ele mesmo transfere seus dados a
              terceiros. O site se compromete a comunicar o usuário em caso de
              alguma violação de segurança dos seus dados pessoais. Os dados
              pessoais armazenados são tratados com confidencialidade, dentro
              dos limites legais. No entanto, podemos divulgar suas informações
              pessoais caso sejamos obrigados pela lei para fazê-lo ou se você
              violar nossos Termos de Serviço.
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>
              SEÇÃO 6 - COMPARTILHAMENTO DOS DADOS
            </Typography>
            <Typography>
              O compartilhamento de dados do usuário ocorre apenas com os dados
              referentes a publicações realizadas pelo próprio usuário, tais
              ações são compartilhadas publicamente com os outros usuários. Os
              dados do perfil do usuário são compartilhados publicamente em
              sistemas de busca e dentro da plataforma, sendo permitido ao
              usuário modificar tal configuração para que seu perfil não apareça
              nos resultados de busca de tais ferramentas.
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>
              SEÇÃO 7 - OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A
              TERCEIROS?
            </Typography>
            <Typography>
              Os dados pessoais não podem ser compartilhados com terceiros.
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>
              SEÇÃO 08 – COOKIES OU DADOS DE NAVEGAÇÃO
            </Typography>
            <Typography>
              Os cookies referem-se a arquivos de texto enviados pela plataforma
              ao computador do usuário e visitante e que nele ficam armazenados,
              com informações relacionadas à navegação no site. Tais informações
              são relacionadas aos dados de acesso como local e horário de
              acesso e são armazenadas pelo navegador do usuário e visitante
              para que o servidor da plataforma possa lê-las posteriormente a
              fim de personalizar os serviços da plataforma. O usuário e o
              visitante da plataforma RURAL BUSINESS manifesta conhecer e
              aceitar que pode ser utilizado um sistema de coleta de dados de
              navegação mediante à utilização de cookies. O cookie persistente
              permanece no disco rígido do usuário e visitante depois que o
              navegador é fechado e será usado pelo navegador em visitas
              subsequentes ao site. Os cookies persistentes podem ser removidos
              seguindo as instruções do seu navegador. Já o cookie de sessão é
              temporário e desaparece depois que o navegador é fechado. É
              possível redefinir seu navegador da web para recusar todos os
              cookies, porém alguns recursos da plataforma podem não funcionar
              corretamente se a capacidade de aceitar cookies estiver
              desabilitada.
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>SEÇÃO 9 - CONSENTIMENTO</Typography>
            <Typography>
              Ao utilizar os serviços e fornecer as informações pessoais na
              plataforma, o usuário está consentindo com a presente Política de
              Privacidade. O usuário, ao cadastrar-se, manifesta conhecer e pode
              exercitar seus direitos de cancelar seu cadastro, acessar e
              atualizar seus dados pessoais e garante a veracidade das
              informações por ele disponibilizadas. O usuário tem direito de
              retirar o seu consentimento a qualquer tempo, para tanto deve
              entrar em contato através do e-mail
              assinaturas@ruralbusiness.com.br e rbvideo.com.br ou por correio
              enviado ao seguinte endereço: Av. Primeiro de Maio, 620 Campo
              grande-MS CEP 79004-620
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>
              SEÇÃO 10 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE
            </Typography>
            <Typography>
              Reservamos o direito de modificar essa Política de Privacidade a
              qualquer momento, então, é recomendável que o usuário e visitante
              revise-a com frequência. As alterações e esclarecimentos vão
              surtir efeito imediatamente após sua publicação na plataforma.
              Quando realizadas alterações os usuários serão notificados. Ao
              utilizar o serviço ou fornecer informações pessoais após eventuais
              modificações, o usuário e visitante demonstra sua concordância com
              as novas normas. Diante da fusão ou venda da plataforma à outra
              empresa os dados dos usuários podem ser transferidas para os novos
              proprietários para que a permanência dos serviços oferecidos.
            </Typography>
          </li>
          <li>
            <Typography fontWeight={"bold"}>
              SEÇÃO 11 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS
            </Typography>
            <Typography>
              Para a solução de controvérsias decorrentes do presente
              instrumento será aplicado integralmente o Direito brasileiro. Os
              eventuais litígios deverão ser apresentados no foro da comarca em
              que se encontra a sede da empresa.
            </Typography>
          </li>
        </ol>
      </Typography>
    </ContractsLayout>
  );
}

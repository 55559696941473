import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import OverviewPaymentMP from "./OverviewPaymentMP";
import "../../pages/MercadoPago/PaymentMp/PaymentMPStyle.scss";
import FormSteps from "../Form/FormSteps";
import {
  BACKGROUND_WHITE,
  BORDER_GRAY_100,
  BORDER_GRAY_50,
  TEXT_GRAY_600,
} from "../../constants/colors";
import { useEffect } from "react";
import { scrollToTop } from "../../utils";
import { HelpBadge } from "../../pages";

export default function PaymentMPComponent({
  children,
  planID,
  marketID,
  showMarketOnID,
  errors,
  handleChangeMarket,
  donePayment,
}: any) {
  const steps: any = [
    { number: 1, title: "Seu Plano", done: true, current: false },
    { number: 2, title: "Dados Pessoais", done: true, current: false },
    { number: 3, title: "Pagamento", done: donePayment, current: true },
  ];

  useEffect(() => {
    scrollToTop();
  }, [donePayment]);

  return (
    <>
      <Box display={"flex"} justifyContent={"center"} marginBottom={"50px"}>
        <Box
          bgcolor={BACKGROUND_WHITE}
          border={"1px solid"}
          borderColor={BORDER_GRAY_100}
          borderRadius={"10px"}
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          justifyContent={"center"}
          maxWidth={"460px"}
          width={"100%"}
        >
          <FormSteps steps={steps} />
          <OverviewPaymentMP planId={planID} />
          <Box>
            {errors === true ? (
              <Alert sx={{ mx: 2 }} variant="outlined" severity="error">
                Selecione um mercado
              </Alert>
            ) : null}
          </Box>
          <Box
            alignItems={"center"}
            borderTop={"1px solid"}
            borderBottom={"1px solid"}
            borderColor={BORDER_GRAY_50}
            display={marketID === showMarketOnID ? "flex" : "none"}
            gap={"10px"}
            justifyContent={"center"}
            p={2}
          >
            <Typography
              color={TEXT_GRAY_600}
              fontSize={"18px"}
              fontWeight={"normal"}
            >
              Selecione seu mercado:
            </Typography>
            <Box display={"flex"} flexDirection={"column"}>
              <FormControl size="small" color="success" error={Boolean(errors)}>
                <InputLabel>Mercado</InputLabel>
                <Select
                  id="demo-simple-select"
                  className="select-market"
                  label="Mercado"
                  onChange={(e) => {
                    handleChangeMarket(e.target.value);
                  }}
                >
                  <MenuItem value={1}>Soja e Trigo</MenuItem>
                  <MenuItem value={2}>Milho</MenuItem>
                  <MenuItem value={3}>Gado e Proteina Animal</MenuItem>
                  <MenuItem value={5}>Leite</MenuItem>
                  <MenuItem value={7}>Frango e Suínos</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          {children}
        </Box>
      </Box>
      <HelpBadge />
    </>
  );
}

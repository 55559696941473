export interface PlanMainDto  {
  id?: number;
  title: string;
  value: number;
  planMarket: number;
  installment: number;
}

export class PlanMainDtoHandler {
  public static getDefault(data: any = [{}]): PlanMainDto {
    return {
      id: data.id || null,
      title: data.title || "",
      value: data.value || null,
      planMarket: data.planMarket || null,
      installment: data.installment || null,
    };
  }

  public static getByDataBase(data: any = [{}]): PlanMainDto {
    return this.getDefault({
      id: data.plan_id,
      title: data.plan_nome,
      value: data.plan_valor,
      planMarket: data.plan_mercado,
      installment: data.plan_max_parcelas,
    })
  }
}
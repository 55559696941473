import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import api from "../../../../services/api";
import UserPassword from "./UserPassword";
import { UserDto, UserDtoHandler } from "../../../../dtos";
import { BORDER_GRAY_50, TEXT_LIGHT_GREEN } from "../../../../constants/colors";
import { getHeaders } from "../../../../utils/apiFunctions/auth";

export default function EditPassword() {
  const [user, setUser] = useState<UserDto>(UserDtoHandler.getDefault());
  const [loading, setLoading] = useState(false);

  //puxando dados da API
  useEffect(() => {
    loadUser();
  }, []);

  function loadUser() {
    api
      .get("/clientes/cadastro", getHeaders())
      .then((response) => {
        setUser(UserDtoHandler.getByDatabase(response.data[0]));
      })
      .catch(() => console.log("Erro ao carregar dados do usuário"));
  }

  async function handleSave(userData: UserDto) {
    setLoading(true);
    const data = UserDtoHandler.editPassword(userData);
    console.log(data[0])

    await api
      .put("/clientes/cadastro", data[0], getHeaders())
      .then((res) => {
        setUser(UserDtoHandler.editPassword(res.data[0]));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      <Typography
        borderBottom={"1px solid"}
        borderColor={BORDER_GRAY_50}
        color={TEXT_LIGHT_GREEN}
        fontSize={32}
        variant="h1"
      >
        Alterar Senha
      </Typography>
      <UserPassword
        user={user}
        onChange={(e) => setUser(e)}
        onSave={handleSave}
        loading={loading}
      />
    </>
  );
}

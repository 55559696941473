import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import api from "../../services/api";
import { Apple, PlayStore } from "../../assets/imgs";

export default function BoletoPayment() {
  const navigate = useNavigate();
  const { contId, titleId } = useParams();

  async function loadPix() {
    const cont_id = contId;
    const titulo_id = titleId;
    const body = { cont_id, titulo_id };
    api
      .post("/cobrancas/geraBoleto", body, { responseType: "blob" })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" }); //Build a URL from the file
        const fileURL = window.URL.createObjectURL(file); //Open the URL on new Window
        window.open(fileURL);
      })
      .catch(() => console.log("Erro ao gerar boleto do usuário"));
  }

  async function handlerSubmit() {
    await loadPix();
  }

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        alignContent={"center"}
        width={"100%"}
        maxWidth={680}
        ml={"auto"}
        mr={"auto"}
        mt={8}
        mb={8}
        sx={{ backgroundColor: "#FFF", borderRadius: "15px" }}
      >
        <Grid item xs={12} p={4}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            width={"100%"}
            height={"100%"}
            maxHeight={320}
          >
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/stRixlgMKac"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </Box>

          <Typography
            variant="h5"
            textTransform={"uppercase"}
            textAlign={"center"}
            sx={{ mt: 2 }}
          >
            <span className="warning">ATENÇÃO</span> PARA TER ACESSO AO CONTEÚDO
            BASTA ACESSAR O NOSSO SITE{" "}
            <span
              className="linkSignature"
              onClick={() => window.open("https://rbvideo.com.br", "_blank")}
            >
              RBVIDEO.COM.BR
            </span>{" "}
            OU BAIXAR O NOSSO APP!
          </Typography>

          <Typography
            variant="body1"
            textTransform={"uppercase"}
            textAlign={"center"}
            fontWeight={500}
            color={"green"}
            sx={{ mt: 2 }}
          >
            Baixe agora nosso aplicativo
          </Typography>

          <Box display={"flex"} justifyContent={"center"}>
            <img
              src={PlayStore}
              className="appStore"
              alt=""
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=br.com.rbvideo.rbvideo&hl=pt_BR&pli=1",
                  "_blank"
                )
              }
            />
            <img
              src={Apple}
              className="appStore"
              alt=""
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/rb-video/id1324714161",
                  "_blank"
                )
              }
            />
          </Box>

          <Typography
            variant="body1"
            textAlign={"center"}
            textTransform={"uppercase"}
            sx={{ mt: 2 }}
          >
            Boleto gerado com <span className="sucessSignature">sucesso!</span>
          </Typography>

          <Box display={"flex"} justifyContent={"center"} mt={2}>
            <Typography variant="body1">Número do contrato:</Typography>
            <Typography variant="body1" color={"primary"} sx={{ ml: 4 }}>
              {contId}
            </Typography>
          </Box>

          <Button
            variant="contained"
            color="success"
            onClick={handlerSubmit}
            fullWidth
            disableElevation
            sx={{
              mt: 2,
              background: "#7EC057",
              ":hover": { bgcolor: "#6faa4e" },
            }}
          >
            Imprimir Boleto
          </Button>

          <Button
            variant="text"
            color="primary"
            onClick={() => navigate("/dashboard/video")}
            fullWidth
            disableElevation
            sx={{ mt: 2 }}
          >
            Assista os videos
          </Button>
        </Grid>
        <Box
          component={Box}
          sx={{ backgroundColor: "#215D9F", borderRadius: "0px 0px 15px 15px" }}
          p={2}
          mt={18}
          width={"100%"}
        >
          <Typography variant="subtitle2" textAlign={"center"}>
            <b>
              Seja bem vindo a maior e mais completa agencia de informações
              agropecuárias do Brasil!
            </b>
          </Typography>
          <Typography variant="subtitle2" textAlign={"center"}>
            Em caso de duvidas entre em contato com nossa central de
            atendimento:
          </Typography>
          <Typography variant="subtitle2" textAlign={"center"}>
            De segunda a sexta das <b>8h as 18h</b>:
          </Typography>
          <Typography variant="subtitle2" textAlign={"center"}>
            <b>(67)3026-7495 / (67)3025-5205 / (67)8464-1530</b>
          </Typography>
        </Box>
      </Grid>
    </>
  );
}

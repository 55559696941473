import { useEffect, useState } from 'react';
import './style.scss';
import { ArrowUpwardRounded } from '@mui/icons-material';

export default function ScrollButton() {
  const [visible, setVisible] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  //alterando o navBar para mobile
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', changeWidth);

    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);

  function toggleVisible() {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
    if (screenWidth < 1024) {
      setVisible(false);
    }
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  window.addEventListener('scroll', toggleVisible);

  return (
    <>
      <div
        className={
          visible === true
            ? 'fixed bottom-[10%] right-14 z-50 flex cursor-pointer rounded-lg border bg-[#215d9f] p-4 text-white opacity-90 duration-300 ease-in-out hover:shadow-[5px_5px_0_0_rgba(180,180,180)]'
            : 'hidden'
        }
        onClick={scrollToTop}
      >
        <ArrowUpwardRounded fontSize="large" />
      </div>
    </>
  );
}

import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PlanDetailDto, PlanDetailDtoHandler } from "../../dtos/PlanDetail";
import api from "../../services/api";
import ResumLoading from "../ResumLoading";

interface ResumProps {
  planId?: string;
  price?: number;
  periodo?: string;
  portion?: string;
  loading?: boolean;
}

export default function Resumo(props: ResumProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clientId, contract, title } = useParams();
  const [planDetail, setPlanDetail] = useState<PlanDetailDto>(
    PlanDetailDtoHandler.getDefault()
  );

  const params = pathname.split("/", 3);

  useEffect(() => {
    loadPlanDetail();
  }, []);

  if (props.loading) {
    return (
      <div className="container_pagamento">
        <ResumLoading />
      </div>
    );
  }

  async function loadPlanDetail() {
    await api
      .get(`/planos/id/${props.planId}`)
      .then((response) => {
        const newPlanDetails = PlanDetailDtoHandler.getByDatabase(
          response.data[0]
        );
        setPlanDetail(newPlanDetails);
      })
      .catch(() => console.log("Erro ao buscar o detalhes do plano"));
  }

  function handlerTradePlan() {
    if (params[1] === "pagamento") return navigate(`/planos/${clientId}`);

    navigate(`/dashboard/renewPlan/${clientId}/${contract}/${title}`);
  }

  return (
    <>
      <div className="container-resum">
        <h2 className="title-resum">Resumo da assinatura</h2>

        <div className="title-info_plan">
          <p className="tipo_plano">
            <b>Plano {planDetail.name}</b>
          </p>
          <p className={"trocar_plano"} onClick={handlerTradePlan}>
            Trocar
          </p>
        </div>
        <div className="title-info_total">
          <p className="total">Total:</p>
          <p className="valor_total" style={{ textAlign: "center" }}>
            {/* <b>R$ {((props.price! / 100) * 12).toFixed(2)}</b>
              <br/>
              <span style={{fontSize: '12px', fontStyle: 'italic'}}>em ate 12x <b>R$ {(props.price! / 100).toFixed(2)}</b></span> */}
            <b>R$ {(props.price! / 100).toFixed(2)}</b>
            <br />
            <span style={{ fontSize: "12px", fontStyle: "italic" }}>
              em ate 12x <b>R$ {(props.price! / 100 / 12).toFixed(2)}</b>
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

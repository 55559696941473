import { Box, Link, Typography } from "@mui/material";
import {
  TEXT_DARK_BLUE,
  TEXT_GRAY_600,
  TEXT_GRAY_700,
} from "../../constants/colors";
import { Loading } from "../../assets/imgs";
import { newDate } from "../../utils";

export default function DashboardNewsComponent({ posts, loading }: any) {
  const NewsPosts = () => {
    return (
      <>
        {posts.map((post: any, index: number) => (
          <Box
            display={"flex"}
            width={"360px"}
            marginBottom={"64px"}
            marginRight={"32px"}
            key={index}
          >
            <Link href={`/dashboard/news/${post.not_numero}`} underline="none">
              <Box>
                <Typography
                  color={TEXT_DARK_BLUE}
                  fontSize={"23px"}
                  fontWeight={600}
                  variant="h3"
                  sx={{ ":hover": { textDecoration: "underline" } }}
                >
                  {post.not_titulo}
                </Typography>
                <Typography
                  color={TEXT_GRAY_700}
                  fontSize={"16px"}
                  fontWeight={500}
                  margin={"8px 0"}
                  variant="h4"
                >
                  {post.not_lead}
                </Typography>
                <Typography color={TEXT_GRAY_600} fontSize={"0.95rem"}>
                  {newDate(post.not_data, "llll")}
                </Typography>
              </Box>
            </Link>
          </Box>
        ))}
      </>
    );
  };

  const RenderPosts = () => {
    return <>{loading ? <Loading /> : <NewsPosts />}</>;
  };

  return (
    <>
      <RenderPosts />
    </>
  );
}

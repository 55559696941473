import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import App from "./App";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAjKAG186pUXwaQd_CxROxZqWo_7f21KrM",
  authDomain: "fir-rbvideo.firebaseapp.com",
  databaseURL: "https://fir-rbvideo.firebaseio.com",
  projectId: "firebase-rbvideo",
  storageBucket: "firebase-rbvideo.appspot.com",
  messagingSenderId: "383324583382",
  appId: "1:383324583382:web:95b5c40be1a4eafd67e077",
  measurementId: "G-1VYTF3V6NS",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Firebase end

localStorage.setItem("promotionModal", "show");

const root = ReactDOM.createRoot(window.document.getElementById("root")!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

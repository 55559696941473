export const pageView = () => {
  window.fbq("track", "PageView");
};
export const Purchase = (value) => {
  window.fbq('track', 'Purchase', {currency: "BRL", value: value});
};
//InitiateCheckout
export const InitiateCheckout = () => {
  window.fbq('track', 'InitiateCheckout');
};
//AddToCart
export const AddToCart = () => {
  window.fbq('track', 'AddToCart');
};
//Subscribe
export const Subscribe = () => {
  window.fbq('track', 'Subscribe');
};
//CompleteRegistration
export const CompleteRegistration = () => {
  window.fbq('track', 'CompleteRegistration');
};
//CompleteRegistration
export const PagePlanView = () => {
  window.fbq('track', 'PagePlanView');
};
import { useNavigate } from 'react-router-dom';
import { PlayStore, Apple, MockupRuralBusiness } from '../../assets/imgs';
import { MovieRounded, NewspaperRounded, PhoneAndroid, ShoppingCart } from '@mui/icons-material';

export default function WelcomeComponent() {
  const navigate = useNavigate();

  return (
    <section className="relative flex h-full items-center justify-center overflow-hidden bg-[#eeeeee] px-3 py-5 md:px-28 lg:min-h-[70vh] xl:min-h-[70vh]">
      <div className="absolute bottom-[-77%] h-screen w-[300vh] -rotate-6 bg-white lg:bottom-[-30%] lg:top-[60%] xl:bottom-[-25%] xl:top-[68%]" />
      <div className="mx-auto grid h-full max-w-[1440px] grid-cols-1 items-center gap-20 bg-[#eeeeee] px-3 py-5 lg:grid-cols-2 lg:gap-5 xl:grid-cols-3">
        <div className="z-10 flex flex-col gap-5 xl:col-span-2">
          <h1 className="text-3xl font-medium uppercase text-[#215d9f] lg:text-4xl 2xl:text-6xl">
            O amanhã do agronegócio, <br />
            <p className="font-black text-[#215d9f] sm:mt-5">hoje</p>
          </h1>
          <p className="my-4 max-w-3xl text-lg leading-relaxed text-gray-500 xl:text-xl">
            Tenha na palma da sua mão informações especializadas dos mercados de soja, milho, trigo, boi, leite, frango
            e suíno, análise de mercado diária em vídeo, alertas do mercado, cotações e gráficos.
          </p>
          <div className="flex items-center gap-5">
            <div
              className="flex cursor-pointer items-center justify-center rounded-lg border border-[#215d9f] px-5 py-3 font-bold uppercase text-[#215d9f] duration-300 ease-in-out hover:shadow-[5px_5px_0_0_rgba(33,93,159)] sm:px-10 sm:text-xl"
              onClick={() => navigate(`/dashboard/video`)}
            >
              Entrar
            </div>
            <div
              className="flex cursor-pointer flex-nowrap items-center justify-center gap-2 rounded-lg bg-[#215d9f] px-5 py-3 text-sm font-bold uppercase text-white duration-300 ease-in-out hover:shadow-[5px_5px_0_0_rgba(180,180,180)] sm:px-10 sm:text-xl"
              onClick={() => navigate(`/plan`)}
            >
              Assine já
              <ShoppingCart />
            </div>
          </div>
          <div className="flex items-center gap-3">
            <img
              src={PlayStore}
              onClick={() =>
                window.open('https://play.google.com/store/apps/details?id=br.com.rbvideo.rbvideo&hl=pt_BR', '_blank')
              }
              className="h-14 cursor-pointer sm:h-14"
              alt="playstore link"
            />
            <img
              src={Apple}
              onClick={() => window.open('https://apps.apple.com/us/app/rb-video/id1324714161', '_blank')}
              className="h-11 cursor-pointer sm:h-11"
              alt="appstore link"
            />
          </div>
        </div>
        <div className="z-10 flex flex-col gap-16">
          <img src={MockupRuralBusiness} className="max-h-[400px] xl:scale-125" alt="" />
          <div className="grid grid-cols-3 justify-center gap-4">
            <div className="flex flex-col items-center justify-center gap-3 font-medium text-gray-500">
              <PhoneAndroid fontSize="large" />
              <p className="text-center uppercase xl:text-lg">
                Acesso <br /> <p className="font-normal xl:text-sm">via APP</p>
              </p>
            </div>
            <div className="flex flex-col items-center justify-center gap-3 font-medium text-gray-500">
              <MovieRounded fontSize="large" />
              <p className="text-center uppercase xl:text-lg">
                Séries <br /> <p className="font-normal xl:text-sm">exclusivas</p>
              </p>
            </div>
            <div className="flex flex-col items-center justify-center gap-3 font-medium text-gray-500">
              <NewspaperRounded fontSize="large" />
              <p className="text-center uppercase xl:text-lg">
                Alertas <br /> <p className="font-normal xl:text-sm">de mercado</p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

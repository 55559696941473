import api from "../../../services/api";
import getHeaders from "./getHeaders";

//buscando auth do user pelo token.
export default async function loadAuth(handleAuth) {
  await api
    .get("/clientes/logado", getHeaders())
    .then((response) => {
      handleAuth(response.data.error);
      // console.log("Usuário autenticado");
    })
    .catch(() => console.log("Erro ao autenticar o usuário"));
}

export interface PlanDetailDto {
  id?: number;
  name: string;
  description: string;
  value: number;
  status: boolean;
  days: number;
  interval: string;
  planMasterId: number;
  planMarket: number;
  paymentMethod: number;
  installment: string;
};

export class PlanDetailDtoHandler {
  public static getDefault(data: any = {}): PlanDetailDto {
    return {
      id: data.id || null,
      name: data.name || '',
      description: data.description || '',
      value: data.value || null,
      status: data.name || '',
      days: data.days || null,
      interval: data.interval || '',
      planMarket: data.planMarket || null,
      planMasterId: data.planMasterId || null,
      paymentMethod: data.paymentMethod || null,
      installment: data.installment || 1,
    }
  }

  public static getByDatabase(data: any = {}): PlanDetailDto {
    return this.getDefault ({
      id: data.plan_id,
      name: data.plan_nome,
      description: data.plan_descricao,
      value: data.plan_valor,
      status: data.plan_ativo,
      days: data.plan_qtd_dias,
      interval: data.plan_interval,
      planMarket: data.plan_mercado,
      planMasterId: data.plan_id_principal,
      paymentMethod: data.plan_forma_pgto,
      installment: data.plan_max_parcelas,
    })
  }

  public static toDatabase(data: PlanDetailDto): any {
    return  [{
      plan_id: data.id,
      plan_nome: data.name,
      plan_descricao: data.description,
      plan_valor: data.value,
      plan_ativo: data.status,
      plan_qtd_dias: data.days,
      plan_interval: data.interval,
      plan_mercado: data.planMarket,
      plan_id_principal: data.planMasterId,
      plan_forma_pgto: data.paymentMethod,
      plan_max_parcelas: data.installment,
    }]
  }
}
import api from "../../../services/api";
import handleHeight from "../handleHeight";
import getHeaders from "./getHeaders";

export default async function loadFilterPlanUser(
  setMarketUser,
  screenWidth,
  setHeight
) {
  await api
    .get(`/videos/filtro/`, getHeaders())
    .then((res) => {
      const newFilterMarket = res.data;
      setMarketUser(newFilterMarket);
      handleHeight(
        newFilterMarket.length,
        newFilterMarket[0],
        screenWidth,
        setHeight
      );
    })
    .catch(() => console.log("Erro ao carregar videos"));
}

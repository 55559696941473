import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PlanUserDto, PlanUserDtoHandler } from "../../dtos/PlanUserDto";
import api from "../../services/api";

export default function LoginToken() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [planUser, setPlanUser] = useState<PlanUserDto>(
    PlanUserDtoHandler.getDefault()
  );
  const planUserRef = useRef(planUser);

  useEffect(() => {
    const loadAxios = async () => {
      await loadPlanUser();
    };
    loadAxios();
  }, []);

  async function loadPlanUser() {
    //Headers da API
    const headers = {
      headers: {
        "x-access-token": `${token}`,
      },
    };

    await api
      .get("/contratos/situacao/", headers)
      .then((response) => {
        const newPlanUser = PlanUserDtoHandler.getByDataBase(response.data[0]);
        planUserRef.current = newPlanUser;
        setPlanUser(planUserRef.current);
        handleTokenNavigate();
      })
      .catch(() => console.log("Erro ao carregar planos do usuário."));
  }

  function handleTokenNavigate() {
    localStorage.setItem("user", JSON.stringify(token));

    if (token === "") return navigate("/login");
    else
      return navigate(
        `/dashboard/renewPlan/${planUserRef.current.id}/${planUserRef.current.contract}/${planUserRef.current.title}`
      );
  }

  return <></>;
}

import { useState, useEffect } from 'react';
import { convert } from 'html-to-text';
import { PauseCircle, PlayCircle, Replay } from '@mui/icons-material';

const TextToSpeech = ({ html }) => {
  const [isPaused, setIsPaused] = useState(true);
  const [isStopped, setIsStopped] = useState(true);
  const [utterance, setUtterance] = useState(null);
  const [voice, setVoice] = useState(null);
  const [rate, setRate] = useState(1);
  const [preferredLanguage, setPreferredLanguage] = useState('pt-BR');
  const text = convert(html);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);
    const voices = synth.getVoices();

    // Filtrar vozes pelo idioma preferido
    const preferredVoice = voices.find(voice => voice.lang === preferredLanguage);

    setUtterance(u);
    setVoice(preferredVoice || voices[0]);

    return () => {
      synth.cancel();
    };
  }, [text]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;

    utterance.voice = voice;
    utterance.pitch = 1;
    utterance.rate = rate;
    utterance.volume = 1;
    synth.speak(utterance);
    setIsPaused(true);
    setIsStopped(false);

    if (!isPaused) {
      synth.pause();
      setIsPaused(true);
    } else if (isPaused) {
      synth.resume();
      setIsPaused(false);
    }
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;
    synth.cancel();
    setIsPaused(true);
    setIsStopped(true);
  };

  const handleRateChange = () => {
    setRate(prevState => (prevState === 1 ? 2 : 1));
  };

  return (
    <div className="sticky top-2 mx-auto my-2 flex w-fit gap-6 rounded-full bg-gradient-to-tr from-blue-800 to-blue-500 p-2 text-white hover:from-blue-800 hover:to-blue-400">
      <button onClick={handlePlay} title="play" className="rounded-full p-2 hover:bg-blue-800 hover:bg-opacity-70">
        {!isPaused ? <PauseCircle fontSize="large" /> : <PlayCircle fontSize="large" />}{' '}
      </button>
      {!isStopped && (
        <button
          onClick={handleStop}
          title="reiniciar"
          className="rounded-full p-3 hover:bg-blue-800 hover:bg-opacity-70"
        >
          <Replay />
        </button>
      )}
      {(isStopped || !utterance) && (
        <button onClick={handleRateChange} title="velocidade" className="rounded-full bg-blue-800 px-4 opacity-70">
          <p className="text-lg text-white">{rate === 1 ? '1X' : '2X'}</p>
        </button>
      )}
    </div>
  );
};

export default TextToSpeech;

import { useLocation } from "react-router-dom";
import LogoNavComponent from "./LogoNavComponent";
import DashboardNavLayout from "../Dashboard/DashboardNavLayout";
import "../styles/modalStyle.scss";
import "../styles/headerStyle.scss";
import { Box } from "@mui/material";
import { CountdownTimer } from "../../components/Home";

export default function HeaderLayout() {
  const { pathname } = useLocation();
  const path = pathname.split("/", 3);

  const RenderNavOnDashboard = () => {
    return <>{path[1] === "dashboard" ? <DashboardNavLayout /> : null}</>;
  };

  return (
    <Box component={"header"} paddingY={"20px"}>
      <LogoNavComponent />
      <RenderNavOnDashboard />
    </Box>
  );
}

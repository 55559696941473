import { Box, Typography } from "@mui/material";
import { BORDER_GRAY_50, TEXT_LIGHT_GREEN } from "../../constants/colors";

export default function UserDataLayout({ children, title }: any) {
  return (
    <>
      <Typography
        color={TEXT_LIGHT_GREEN}
        fontSize={32}
        borderBottom={"1px solid"}
        borderColor={BORDER_GRAY_50}
      >
        {title}
      </Typography>
      <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
        {children}
      </Box>
    </>
  );
}

export interface SituationUserDto {
  id?: number;
  fullName: string;
  city: string;
  state: string;
  paymentAt: string;
  cpf: string;
  contract: number;
  dueDate: string;
  marketplace: number;
  title: string;
  value: number,
  situation: string;
  plan: string;
  period: string;
}

export class SituationUserDtoHandler {
  public static getDefault(data: any = {}): SituationUserDto {
    return {
      id: data.id || null,
      fullName: data.fullName || '',
      city: data.city || '',
      state: data.state || '',
      paymentAt: data.paymentAt || '',
      cpf: data.cpf || '',
      contract: data.contract || '',
      dueDate: data.dueDate || '',
      marketplace: data.marketplace || null,
      title: data.title || '',
      value: data.value || null,
      situation: data.situation || '',
      plan: data.plan || '',
      period: data.period || '',
    };
  }

  public static getByDatabase(data: any = {}): SituationUserDto {
    return this.getDefault({
      id: data.cli_id,
      fullName: data.cli_nome,
      city: data.cli_cidade,
      state: data.cli_estado,
      paymentAt: data.cli_data_cadastro,
      cpf: data.cli_cpf_cnpj,
      contract: data.cont_id,
      dueDate: data.cont_data_vencimento,
      marketplace: data.cont_mercado,
      title: data.tituloid,
      value: data.valorpago,
      situation: data.situacao,
      plan: data.plan_nome,
      period: data.plan_descricao,
    });
  }
}
import { HelpOutlineRounded, WhatsappRounded } from "@mui/icons-material";

export default function HelpBadge() {
  function handleLoadScript() {
    import("../../services/script")
      .then(({ script }) => {
        script();
      })
      .catch((err) => {
        // Handle failure
        console.log(err);
      });
  }
  return (
    <div className="col-span-full flex justify-center">
      <div className="bg-white flex text-zinc-800 border-2 border-zinc-300 items-center gap-6 flex-wrap w-fit p-5 rounded-md my-5 mx-2 justify-center">
        <div className="flex justify-center items-center gap-4">
          <HelpOutlineRounded fontSize="large" />
          <div>
            <p className="text-lg font-medium">Alguma dúvida?</p>
            <p>Tire dúvidas sobre os planos</p>
            <p>entre em contato com nossa equipe de atendimento!</p>
          </div>
        </div>
        <button
          onClick={handleLoadScript}
          className="hover:bg-green-400 flex items-center bg-green-500 text-white font-medium rounded-md py-2 px-10 gap-2"
        >
          <WhatsappRounded fontSize="large" />
          <p>Fale conosco</p>
        </button>
      </div>
    </div>
  );
}

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { TEXT_GRAY_600 } from "../../constants/colors";
import scrollToTop from "../../utils/scrollToTop";

export default function ContractsLayout({ title, children, gap }: any) {
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        maxWidth={"1024px"}
        margin={"auto"}
        padding={5}
        gap={gap}
      >
        <Typography
          sx={{
            color: TEXT_GRAY_600,
            cursor: "pointer",
            ":hover": { textDecoration: "underline" },
          }}
        >
          <p onClick={() => navigate(-1)}>&larr; Voltar</p>
        </Typography>
        <Typography variant="h1" fontSize={"2rem"} marginBottom={3}>
          {title}
        </Typography>
        {children}
      </Box>
    </>
  );
}

import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BUTTON_DARK_GREEN, BUTTON_LIGHT_GREEN } from "../../constants/colors";

interface PixProps {
  value: number;
  clientId: string;
  paymentMethod: number;
  planId: string;
  marketSelect: string;
}

export default function Index(props: PixProps) {
  const navigate = useNavigate();

  function handleNavigate() {
    if (props.marketSelect === "null")
      return window.alert("Necessario selecionar o mercado");

    navigate(
      `/pix/${props.value}/${props.planId}/${props.clientId}/${props.paymentMethod}/${props.marketSelect}`
    );
  }
  return (
    <>
      <div className="container__pix">
        <div className="content__pix">
          <p className="text__pix" style={{ width: "100%" }}>
            Você precisa realizar o pagamento para finalizar a sua compra.
          </p>
          <Button
            variant="contained"
            fullWidth
            color={"success"}
            type="submit"
            size="large"
            onClick={handleNavigate}
            disableElevation
            sx={{
              mt: 1,
              background: BUTTON_LIGHT_GREEN,
              ":hover": { bgcolor: BUTTON_DARK_GREEN },
            }}
          >
            Pagar no pix
          </Button>
        </div>
      </div>
    </>
  );
}

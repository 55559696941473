import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../assets/imgs";
import { formatPrice } from "../../utils";
import { Add, CheckRounded } from "@mui/icons-material";
import ButtonOutline from "../Buttons/ButtonOutline";

export default function Tab(props: any) {
  const navigate = useNavigate();
  const { clientId, contract, title } = useParams();
  const { pathname } = useLocation()
  const isBlackFriday = pathname.split("/").find((el) => el === 'blackfriday') === undefined
  const isNormal = props.variant === "normal"
  const isAdvancedPlan = props.plan === 34 || props.plan === 40 || props.isAdvancedPlan
  const isIntermediatePlan = props.plan === 33 || props.plan === 39 || props.isIntermediatePlan

  function handleNavigate() {
    if (clientId && contract === undefined && title === undefined) {
      //definindo rotas caso o usuário estiver trocando o plano
      if (props.market !== null) {
        return navigate(
          `/pagamento/mp/${props.plan}/${props.market}/${clientId}`
        );
      }
      return navigate(`/pagamento/mp/${props.plan}/0/${clientId}`);
    }
    if (clientId && contract && title) {
      //definindo rotas caso o usuário estiver renovando plano
      if (props.market !== null) {
        return navigate(
          `/dashboard/renewPlan/pagamento/mp/${props.plan}/${props.market}/${clientId}`
        );
      }
      return navigate(
        `/dashboard/renewPlan/pagamento/mp/${props.plan}/0/${clientId}`
      );
    } else if (props.market === null) {
      // rotas caso for novo plano
      return navigate(`/dados/${props.plan}/0`);
    } else {
      navigate(`/dados/${props.plan}/${props.market}`);
    }
  }

  const discountPercentage = Math.round(((1 - (parseInt(props.price) / parseInt(props.oldPrice))) * 100))

  return (
    <div
      className={`flex flex-col h-fit relative ${isNormal || isBlackFriday ? "bg-white" : "bg-[#181818]"
        } w-full max-w-xs col-span-1 rounded-lg ease-in-out duration-300 p-2 ${isAdvancedPlan
          ? "border-lime-400 border-[3px] scale-105 md:order-2 order-1"
          : isIntermediatePlan
            ? "border-green-500 border-2 md:order-1 order-2"
            : "border-[#215d9f] border-2 order-3"
        }`}
    >
      {isNaN(props.price) ? (
        <Loading />
      ) : (
        <>
          <div
            className={`absolute w-max flex items-center font-black rounded-full ${(isNormal || discountPercentage <= 0) ? "py-1 px-5 left-1/4 right-1/4 top-[-14px]" : "left-[12%] right-[12%] -top-5"} ${isAdvancedPlan
              ? `bg-lime-400 text-black`
              : isIntermediatePlan
                ? "bg-green-500 text-zinc-900"
                : "bg-[#215d9f] text-white"
              }`}
          >
            <p className={`text-center text-xs px-2`}>
              {isAdvancedPlan
                ? "MAIS COMPLETO"
                : isIntermediatePlan
                  ? "MAIS POPULAR"
                  : "MAIS ECONÔMICO"}
            </p>
            {
              (!isNormal && discountPercentage > 0) &&
              <div className={`text-center flex items-center gap-1 text-xs font-black rounded-full px-3 ${isNormal || isBlackFriday ? "bg-white text-zinc-900" : "bg-[#181818] text-white"
                } ${isAdvancedPlan
                  ? "border-lime-400 border-2"
                  : isIntermediatePlan
                    ? "border-green-500 border-2"
                    : "border-[#215d9f] border-2"
                }`}>
                <p className="text-base py-1">
                  {
                    discountPercentage.toString().concat("%")
                  }
                </p>
                <p className="py-1">de Desconto</p>
              </div>
            }
          </div>
          <div
            className={`flex flex-col items-center gap-3 justify-center p-4`}
          >
            <p
              className={`${isNormal || isBlackFriday ? "text-zinc-800" : "text-zinc-100"
                } font-helvetica font-bold text-xl text-center`}
            >
              {props.title}
            </p>
            <p
              className={`text-center ${isNormal || isBlackFriday ? "text-zinc-700" : "text-zinc-200"
                }`}
            >
              {props.commoditys}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center">
            {(!isNormal && discountPercentage > 0) && (
              <div
                className={`flex items-center justify-center gap-1 text-red-600`}
              >
                <p className="text-base">de</p>
                <p className="text-xs line-through">{props.oldPriceInstallments}X</p>
                <p className="font-helvetica text-2xl font-bold line-through">
                  {formatPrice(props.oldPrice)}
                </p>
                <p className="text-xs font-bold line-through">/ mês</p>
              </div>
            )}
            <div
              className={`flex items-center justify-center gap-1 ${isNormal || isBlackFriday ? "text-zinc-700" : "text-zinc-50"
                }`}
            >
              {props.variant !== "normal" && (
                <p className="text-lg mr-1">Por</p>
              )}
              <p className="text-sm">{props.priceInstallments}X</p>
              <p className="font-helvetica text-4xl font-bold">
                {formatPrice(props.price)}
              </p>
              <p className="text-sm font-medium">/ mês</p>
            </div>
            <p
              className={`${isNormal || isBlackFriday ? "text-zinc-600" : "text-zinc-400"
                } text-xs`}
            >
              Plano anual
            </p>
          </div>
          <div className={`pt-5 pb-2`}>
            <ButtonOutline className={
              isAdvancedPlan ? "bg-lime-400 text-zinc-900"
                : isIntermediatePlan ? "bg-green-500 text-zinc-900" : "bg-[#215d9f] text-white"}
              onClick={handleNavigate}
              icon={undefined}
              link={undefined}
            >
              {clientId && contract && title
                ? "Renovar"
                : clientId && contract === undefined && title === undefined
                  ? "Selecionar"
                  : "Assine Já"}
            </ButtonOutline>
            <p
              className={`${isNormal || isBlackFriday ? "text-zinc-600" : "text-zinc-400"
                } text-xs text-center p-2`}
            >
              Cartão de crédito, Boleto Bancário ou Pix
            </p>
          </div>
          <div className="flex flex-col gap-3 mb-2">
            {/* Special perks */}
            {props.specialPerks && (
              <div className="flex flex-col px-2 gap-x-2 gap-y-0 border-y">
                <p
                  className={`${isNormal || isBlackFriday
                    ? "text-zinc-800"
                    : "text-zinc-100"
                    } col-span-full text-center text-sm font-bold font-helvetica py-3 flex items-center justify-center`}
                >
                  <Add fontSize="small" />
                  Bônus Exclusivos
                </p>
                <div className="grid grid-cols-3 gap-2 justify-between items-center col-span-full pb-3">
                  {props.specialPerks?.map((item: any, index: any) => (
                    <div
                      key={index}
                      className="flex flex-col justify-center items-center gap-1 bg-lime-400 rounded-md p-1"
                    >
                      <div className="flex justify-center items-center">
                        {/* <Add fontSize="small" /> */}
                        <p className="font-bold font-helvetica text-sm text-black">
                          {item.title}
                        </p>
                      </div>
                      <p className="text-xs text-center font-helvetica text-black">
                        {item.text}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex flex-col gap-2">
              {props.perks?.map((item: any, index: any) => (
                <div
                  key={index}
                  className={`flex items-center gap-2 ${isNormal || isBlackFriday
                    ? "text-zinc-700"
                    : "text-zinc-100"
                    }`}
                >
                  <div>
                    <CheckRounded fontSize="small" />
                  </div>
                  <p className="">{item}</p>
                </div>
              ))}
            </div>
          </div>
          {(isAdvancedPlan || isIntermediatePlan) && (
            <p className="text-xs text-center p-3 text-zinc-400">
              * Somente para o mercado de Trigo as análises serão
              disponibilizadas 1 vez por semana
            </p>
          )}
        </>
      )}
    </div>
  );
}

export default async function loadNews() {
  const getTokenUrl = `${process.env.REACT_APP_API_RB}/noticias/listar`;
  const token = localStorage.getItem("user");
  const tokenJWT = token?.substring(1, token?.length - 1);

  try {
    const response = await fetch(getTokenUrl, {
      method: "GET",
      headers: {
        "x-access-token": `${tokenJWT}`,
      },
    });
    const noticiasAPI = await response.json();
    return noticiasAPI;
  } catch (error) {
    console.log(error);
  }
}

import { Person } from "@mui/icons-material";
import "../../pages/styles/myAccountStyle.scss";
import {
  BACKGROUND_GRAY_100,
  BACKGROUND_GRAY_50,
  BACKGROUND_WHITE,
  BORDER_GRAY_100,
  TEXT_DARK_BLUE,
  TEXT_GRAY_600,
} from "../../constants/colors";
import ContentLoader from "react-content-loader";
import { Box, Typography } from "@mui/material";

function LeftContentDashboardLayout({
  children,
  getUserRef,
  planUserRef,
}: any) {
  const LoadingUsername = () => {
    return (
      <Box height={"50px"}>
        <ContentLoader
          speed={3}
          width={"100%"}
          backgroundColor={BACKGROUND_GRAY_100}
          foregroundColor={BACKGROUND_GRAY_50}
          height={"fit-content"}
          style={{ borderRadius: 10 }}
        >
          <rect width="100%" height={50} />
        </ContentLoader>
      </Box>
    );
  };

  const LoadingUserPlan = () => {
    return (
      <Box height={"20px"} mt={1}>
        <ContentLoader
          speed={3}
          width={"20%"}
          backgroundColor={BACKGROUND_GRAY_100}
          foregroundColor={BACKGROUND_GRAY_50}
          height={"fit-content"}
          style={{ borderRadius: 10 }}
        >
          <rect width="100%" height={20} />
        </ContentLoader>
      </Box>
    );
  };

  return (
    <Box
      border={"1px solid"}
      borderColor={BORDER_GRAY_100}
      borderRadius={"10px"}
      bgcolor={BACKGROUND_WHITE}
      display={"flex"}
      flexDirection={"column"}
      gap={"20px"}
      height={"fit-content"}
      p={"20px"}
    >
      <Box>
        {!getUserRef ? (
          <LoadingUsername />
        ) : (
          <>
            <Person style={{ color: TEXT_DARK_BLUE }} />
            <Typography
              color={TEXT_DARK_BLUE}
              fontSize={"1.4rem"}
              fontWeight={"900"}
              variant="h1"
            >
              {getUserRef}
            </Typography>
          </>
        )}
        {!planUserRef ? (
          <LoadingUserPlan />
        ) : (
          <Typography
            color={TEXT_GRAY_600}
            fontSize={"1rem"}
            fontWeight={"300"}
            variant="h2"
          >
            {planUserRef || "Finaliza seu pagamento"}
          </Typography>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
        {children}
      </Box>
    </Box>
  );
}

export default LeftContentDashboardLayout;

// Error
export { default as ERROR404 } from "./errors/404.png";

// Apps
export { default as Instagram } from "./apps/Instagram.png";
export { default as YouTube } from "./apps/YouTube.png";
export { default as Apple } from "./apps/apple.svg";
export { default as Twitter } from "./apps/twitter.png";
export { default as Facebook } from "./apps/facebook.png";
export { default as PlayStore } from "./apps/playstore.png";

// Icons
export { default as ArrowUp } from "./icons/arrowUp.png";
export { default as Flags } from "./icons/bandeiras.png";
export { default as BullSkull } from "./icons/bull-skull.png";
export { default as Check } from "./icons/check.png";
export { default as Logo } from "./icons/logo.png";
export { default as LogoWhite } from "./icons/logoWhite.png";
export { default as Shop } from "./icons/shop.png";
export { default as About } from "./icons/sobre_img.png";

// Mockups
export { default as MockupOne } from "./mockups/mockupOne.png";
export { default as MockupCotacaoApp } from "./mockups/mockup_cotacao_app.png";
export { default as MockupLateralMobile } from "./mockups/mockup_lateral_mobile.png";
export { default as MockupMobile } from "./mockups/mockup_mobile.png";
export { default as MockupRuralBusiness } from "./mockups/mockup_ruralbusiness.png";
export { default as ScreenMockup } from "./mockups/screan_mockup.png";

// Others
export { default as Plans } from "./others/planos.png";
export { default as QRCode } from "./others/qrCode.png";
export { default as QuotationsScreen } from "./others/tela_cotacao.png";
export { default as GraphicScreen } from "./others/tela_grafico.png";
export { default as Video4 } from "./others/video4.png";
export { default as CommitmentIMG1 } from "./others/commitment1.jpg";
export { default as CommitmentIMG2 } from "./others/commitment2.jpg";

// SVGs
export { default as Movie } from "./svgs/Movie";
export { default as News } from "./svgs/News";
export { default as Phone } from "./svgs/Phone";
export { default as ToggleMenu } from "./svgs/ToggleMenu";
export { default as Loading } from "./svgs/Loading";
export { default as SmallLoading } from "./svgs/SmallLoading";
export { default as Checkbox } from "./svgs/Check";
export { default as Plus } from "./svgs/Plus";
export { default as PixLoading } from "./svgs/PixLoading";
export { default as VideoLoading } from "./svgs/VideoLoading";

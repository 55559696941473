import { Box, Typography } from "@mui/material";
import {
  BACKGROUND_WHITE,
  BORDER_GRAY_100,
  TEXT_GRAY_700,
} from "../../constants/colors";

export default function LoginLayout({
  title,
  children,
  errorMessage,
  bottomContent,
}: any) {
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"30px"}
        justifyContent={"center"}
        alignContent={"center"}
        width={"100%"}
        maxWidth={360}
        ml={"auto"}
        mr={"auto"}
        mt={8}
        mb={8}
      >
        {errorMessage}
        <Box
          bgcolor={BACKGROUND_WHITE}
          border={"1px solid"}
          borderColor={BORDER_GRAY_100}
          borderRadius={"10px"}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
          p={"20px"}
        >
          <Typography
            variant="h1"
            fontWeight={500}
            color={TEXT_GRAY_700}
            fontSize={"2rem"}
          >
            {title}
          </Typography>
          <Box
            autoComplete="off"
            component="form"
            display={"flex"}
            flexWrap={"wrap"}
            gap={"20px"}
            noValidate
            width={"100%"}
          >
            {children}
          </Box>
        </Box>
        {bottomContent}
      </Box>
    </>
  );
}

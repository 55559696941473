export interface PlanUserDto {
  id?: number;
  fullName: string;
  city: string;
  state: string;
  cpf: string;
  contract: number;
  createdAt: string;
  dueDayPlan: string;
  dueDay: string;
  payDay: string;
  value: number;
  payValue: number;
  situation: string;
  title: number;
  planName: string;
  payment: number;
  planPeriod: string;
}

export class PlanUserDtoHandler {
  public static getDefault(data: any = []): PlanUserDto {
    return {
      id: data.id || null,
      fullName: data.fullName || "",
      city: data.city || "",
      state: data.state || "",
      cpf: data.cpf || "",
      contract: data.contract || null,
      createdAt: data.createdAt || "",
      dueDayPlan: data.dueDayPlan || "",
      dueDay: data.dueDay || "",
      payDay: data.payDay || "",
      value: data.value || null,
      payValue: data.payValue || null,
      title: data.title || null,
      situation: data.situation || "",
      planName: data.planName || "",
      payment: data.payment || null,
      planPeriod: data.planPeriod || "",
    };
  }

  public static getByDataBase(data: any = {}): PlanUserDto {
    return this.getDefault({
      id: data.cli_id,
      fullName: data.cli_nome,
      city: data.cli_cidade,
      state: data.cli_estado,
      cpf: data.cli_cpf_cnpj,
      contract: data.cont_id,
      createdAt: data.cli_data_cadastro,
      dueDayPlan: data.cont_data_vencimento,
      dueDay: data.dataven,
      payDay: data.datapag,
      value: data.valor,
      payValue: data.valorpago,
      situation: data.situacao,
      title: data.tituloid,
      planName: data.plan_nome,
      payment: data.cont_forma_pgto,
      planPeriod: data.plan_descricao,
    });
  }
}

import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { BullSkull, ERROR404 } from "../../assets/imgs";
import { TEXT_GRAY_600 } from "../../constants/colors";
import "../styles/notFoundStyle.scss";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
        maxWidth={800}
        width={"100%"}
        mx={"auto"}
        p={4}
      >
        <Box
          component={"img"}
          src={BullSkull}
          alt="Caveira de Boi"
          width={"100%"}
          style={{ maxWidth: "300px" }}
          title="https://www.flaticon.com/free-icons/cultures"
        />
        <Box display={"flex"} flexDirection={"column"} p={4} gap={"8px"}>
          <Box
            component={"img"}
            src={ERROR404}
            alt="ERRO 404"
            width={"100%"}
            style={{ maxWidth: "300px" }}
          />
          <Typography variant="h2" color={TEXT_GRAY_600}>
            oops !!
          </Typography>
          <Typography variant="body1" color={TEXT_GRAY_600} maxWidth={300}>
            Parece que você está perdido, mas não tem mistério
          </Typography>
          <Typography variant="body1" color={TEXT_GRAY_600} maxWidth={300}>
            Apenas clicar no botão “Home” que irei te encaminhar.
          </Typography>
          <Button variant="outlined" size="small" onClick={() => navigate("/")} sx={{width: "fit-content"}}>
            home
          </Button>
        </Box>
      </Box>
    </>
  );
}

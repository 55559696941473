export default function handleHeight(mercado, type, screenWidth, setHeight) {
  const nome = { ...type };

  if (nome.nome === "Boi") {
    if (screenWidth <= 360) {
      return setHeight(2160);
    }
    if (screenWidth <= 600) {
      return setHeight(1600);
    }

    return setHeight(1450);
  }

  if (mercado <= 2) {
    return setHeight(600);
  }

  if (screenWidth <= 360) {
    return setHeight(2413);
  }
  if (screenWidth <= 768) {
    return setHeight(1808);
  }

  return setHeight(1808);
}

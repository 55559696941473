import { Alert, Button, Snackbar, TextField } from "@mui/material";
import { useState } from "react";
import { UserDto } from "../../../../dtos";
import {
  BUTTON_DARK_GREEN,
  BUTTON_LIGHT_GREEN,
  TEXT_RED,
} from "../../../../constants/colors";
import MyAccountFormButton from "../../../Buttons/MyAccountFormButton";

interface UserFormProps {
  user: UserDto;
  onChange: (user: UserDto) => void;
  onSave: (user: UserDto) => void;
  loading: boolean;
}

export default function UserForm(props: UserFormProps) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });

  function handleChange(key: string, value: any) {
    props.onChange({ ...props.user, [key]: value });
  }

  function handleValidate() {
    const emailValido =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!props.user.fullName)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo nome!",
      });
    if (props.user.fullName.length < 8)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o nome completo!",
      });
    if (!props.user.email)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo email!",
      });
    if (!props.user.email.match(emailValido))
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher um email valido!",
      });
    if (!props.user.phoneNumber)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo celular!",
      });
    if (!props.user.cep)
      return setStatus({
        type: "error",
        mensagem: "Necessário preencher o campo CEP!",
      });
    return true;
  }

  function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  }

  function handleSubmit(event: any) {
    event && event.preventDefault();

    if (!handleValidate()) return;

    setOpen(true);
    props.onSave(props.user);
  }

  return (
    <>
      {status.type === "error" ? (
        <p style={{ color: TEXT_RED, marginTop: "16px" }}>{status.mensagem}</p>
      ) : (
        ""
      )}

      <form className="myAccountForm" onSubmit={handleSubmit}>
        <TextField
          id="campo"
          className="label"
          label="Nome completo"
          type={"text"}
          size="small"
          color="success"
          style={{ width: "100%" }}
          InputLabelProps={{ shrink: true }}
          value={props.user.fullName}
          onChange={(e) => handleChange("fullName", e.target.value || "")}
          autoFocus
        />
        <TextField
          id="campo"
          className="label"
          label="Seu E-mail"
          size="small"
          color="success"
          style={{ width: "100%" }}
          value={props.user.email}
          onChange={(e) => handleChange("email", e.target.value || "")}
          type="email"
          InputLabelProps={{ shrink: true }}
        />
        <div className="campos__pequenos1" style={{ width: "100%" }}>
          <TextField
            id="campo"
            className="label2"
            label="CPF"
            size="small"
            color="success"
            value={props.user.cpf}
            style={{ width: "100%" }}
            onChange={(e) => handleChange("cpf", e.target.value || "")}
            disabled
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            id="campo"
            className="label2"
            label="Celular"
            type={"tel"}
            size="small"
            color="success"
            style={{ width: "100%" }}
            value={props.user.phoneNumber}
            onChange={(e) => handleChange("phoneNumber", e.target.value || "")}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 11 }}
          />
        </div>
        <div className="campos__pequenos2" style={{ width: "100%" }}>
          <TextField
            id="campo"
            className="label2"
            label="CEP"
            size="small"
            type={"tel"}
            color="success"
            style={{ width: "100%" }}
            value={props.user.cep}
            onChange={(e) => handleChange("cep", e.target.value || "")}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 8 }}
          />
          <TextField
            id="campo"
            className="label2"
            type={"text"}
            label="Cidade"
            size="small"
            color="success"
            style={{ width: "100%" }}
            value={props.user.city}
            onChange={(e) => handleChange("city", e.target.value || "")}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <MyAccountFormButton
          title={"Salvar"}
          type={"submit"}
          disabled={props.loading}
        />
      </form>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Usuário alterado com sucesso.
        </Alert>
      </Snackbar>
    </>
  );
}

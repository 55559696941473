import { useNavigate } from 'react-router-dom';

export default function ButtonOutline({ children, icon, link, onClick, className }) {
  const navigate = useNavigate();

  return (
    <button
      className={`${className} flex w-full items-center justify-center gap-3 rounded-lg border p-4 font-bold uppercase duration-300 ease-in-out hover:shadow-[5px_5px_0_0_rgba(30,30,30)] sm:w-full`}
      onClick={() => (onClick ? onClick() : navigate(link))}
    >
      {children}
      {icon}
    </button>
  );
}

// Backgrond Colors
export const BACKGROUND_WHITE = "#FFFFFF";
export const BACKGROUND_GRAY_50 = "#EEEEEE";
export const BACKGROUND_GRAY_100 = "#C9C9C9";
export const BACKGROUND_GRAY_600 = "#969696";
export const BACKGROUND_GRAY_700 = "#333333";
export const BACKGROUND_BLACK = "#000000";
export const BACKGROUND_DARK_BLUE = "#215D9F";
export const BACKGROUND_YELLOW = "#E9DF00";
export const BACKGROUND_LIGHT_GREEN = "#7EC057";
export const BACKGROUND_LIME_GREEN = "#69c462";

// Text Colors
export const TEXT_WHITE = "#FFFFFF";
export const TEXT_GRAY_50 = "#EEEEEE";
export const TEXT_GRAY_100 = "#C9C9C9";
export const TEXT_GRAY_600 = "#969696";
export const TEXT_GRAY_700 = "#333333";
export const TEXT_BLACK = "#000000";
export const TEXT_LIGHT_BLUE = "#6890BD";
export const TEXT_DARK_BLUE = "#215D9F";
export const TEXT_RED = "#FF0000";
export const TEXT_LIGHT_GREEN = "#7EC057";

// Border Colors
export const BORDER_DARK_BLUE = "#215D9F";
export const BORDER_WHITE = "#FFFFFF";
export const BORDER_GRAY_50 = "#EEEEEE";
export const BORDER_GRAY_100 = "#C9C9C9";
export const BORDER_GRAY_600 = "#969696";
export const BORDER_GRAY_700 = "#333333";
export const BORDER_LIGHT_GREEN = "#7EC057";
export const BORDER_LIME_GREEN = "#69c462";

// Button Colors
export const BUTTON_LIME_GREEN = "#69C462";
export const BUTTON_LIGHT_GREEN = "#7EC057";
export const BUTTON_DARK_GREEN = "#6FAA4E";
export const BUTTON_DARK_BLUE = "#215D9F";
export const BUTTON_GRAY_100 = "#C9C9C9";

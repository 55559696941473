import { FacebookRounded, Instagram, Twitter, YouTube } from '@mui/icons-material';
import { TEXT_WHITE } from '../../constants/colors';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

export default function FooterLayout() {
  const navigate = useNavigate();
  return (
    <>
      <footer className="flex flex-col items-center gap-8 bg-[#215d9f] p-8">
        <div className="flex w-full max-w-[1440px] flex-col items-center justify-between gap-x-10 gap-y-5 md:flex-row">
          <ul className="flex h-auto flex-col flex-wrap justify-center gap-x-8 gap-y-4 text-center text-sm text-white underline md:flex-row md:justify-start md:text-left">
            <li>
              <NavLink to="/plan">Quero Assinar</NavLink>
              <a onClick={() => navigate(``)} color={TEXT_WHITE}></a>
            </li>
            <li>
              <a href={'https://ruralbusiness.com.br/default.asp'} target="_blank" color={TEXT_WHITE}>
                www.ruralbusiness.com.br
              </a>
            </li>
          </ul>
          <ul className="flex h-auto flex-col flex-wrap justify-center gap-x-8 gap-y-4 text-center text-sm text-white underline md:flex-row md:justify-start md:text-left">
            <li>
              <a href="/ruralBusinessCommitment" color={TEXT_WHITE}>
                Compromisso Rural Business
              </a>
            </li>
            <li>
              <a href="/useLicenseAgreement" color={TEXT_WHITE}>
                Contrato de Licença e Uso
              </a>
            </li>
            <li>
              <a href="/privacyPolicy" color={TEXT_WHITE}>
                Políticas de Privacidade
              </a>
            </li>
            <li>
              <a href="/cookiesPolicy" color={TEXT_WHITE}>
                Políticas de Cookies
              </a>
            </li>
          </ul>
        </div>
        <div className="flex w-full max-w-[1440px] flex-col gap-10 text-center text-xs text-white md:text-left">
          <div className="flex w-full flex-col items-center justify-between gap-5 lg:flex-row">
            <p>
              Rural Business - Copyright © 2023 <p>Avenida Primeiro de Maio, 620 - Campo Grande (MS)</p>
            </p>
            <div className="flex content-between items-center gap-5 text-white">
              <a href="https://pt-br.facebook.com/ruralbusinessbr/" target="_blank" rel="noopener">
                <FacebookRounded fontSize="large" />
              </a>
              <a href="https://twitter.com/ruralbusinessbr" target="_blank" rel="noopener">
                <Twitter fontSize="large" />
              </a>
              <a href="https://www.instagram.com/ruralbusinessbr/" target="_blank" rel="noopener">
                <Instagram fontSize="large" />
              </a>
              <a href="https://www.youtube.com/channel/UC09bGSDmqxLV8RPMJ9hJcOQ" target="_blank" rel="noopener">
                <YouTube fontSize="large" />
              </a>
            </div>
          </div>
          <p className="text-base">
            Todos os direitos reservados. Republicação ou redistribuição do conteúdo produzido pela Rural Business é
            expressamente proibido sem autorização prévia por escrito.
            <span className="font-bold">
              {' '}
              Conforme estabelecido em contrato, erros, atrasos ou inexatidões, não poderão servir como base para
              reclamações ou ações judiciais.
            </span>
          </p>
        </div>
      </footer>
    </>
  );
}

import { UserDtoHandler } from "../../../dtos";
import api from "../../../services/api";
import getHeaders from "./getHeaders";

export default async function loadUser(setGetUser) {
  await api
    .get("/clientes/cadastro", getHeaders())
    .then((response) => {
      const newGetUser = UserDtoHandler.getByDatabase(response.data[0]);
      setGetUser(newGetUser);
    })
    .catch(() => console.log("Erro ao carregar planos do usuário."));
}

import { Button, Typography } from "@mui/material";
import {
  BORDER_GRAY_50,
  BUTTON_DARK_GREEN,
  BUTTON_LIGHT_GREEN,
  TEXT_GRAY_600,
  TEXT_LIGHT_GREEN,
} from "../../../constants/colors";
import ButtonHandler from "../../Buttons/ButtonHandler";

export default function EditPlan() {
  function handleLoadScript() {
    import("../../../services/script")
      .then(({ script }: any) => {
        script();
      })
      .catch((err) => {
        // Handle failure
      });
  }
  return (
    <>
      <Typography
        borderBottom={"1px solid"}
        borderColor={BORDER_GRAY_50}
        color={TEXT_LIGHT_GREEN}
        fontSize={32}
        variant="h1"
      >
        Trocar plano
      </Typography>

      <Typography color={TEXT_GRAY_600} fontSize={"1.2rem"}>
        A troca de planos é efetuada pelo nosso atendimento!
      </Typography>
      <ButtonHandler
        title={"Atendimento"}
        color={BUTTON_LIGHT_GREEN}
        action={handleLoadScript}
        disabled={false}
      />
    </>
  );
}

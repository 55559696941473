import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { initMercadoPago, Payment, StatusScreen } from "@mercadopago/sdk-react";
import axios from "axios";
import PaymentMPComponent from "../../../components/PaymentMp/PaymentMPComponent";
import api from "../../../services/api";
import { BUTTON_LIGHT_GREEN } from "../../../constants/colors";
import { getUserToken } from "../../../utils/apiFunctions/auth";
import { toast } from "react-toastify";
import { WarningRounded } from "@mui/icons-material";

initMercadoPago(process.env.REACT_APP_PUBLIC_KEY_MP || "", {
  locale: "pt-BR",
});

export default function RenewPaymentMp() {
  const navigate = useNavigate();
  const { planId, mercado, ad, clientId, userToken } = useParams();
  
  const [marketSelect, setMarketSelect] = useState<Number>(Number(mercado));
  const [errors, setErrors] = useState<boolean>(false);
  const [errorPayment, setErrorPayment] = useState<boolean>(false);
  const [statusButtonPayment, setStatusButtonPayment] =
    useState<boolean>(false);
  const [statusButtonHome, setStatusButtonHome] = useState<boolean>(false);
  const [donePayment, setDonePayment] = useState<boolean>(false);
  const [idPayment, setIdPayment] = useState<string>();
  const userCpf = useRef();
  const userEmail = useRef();
  const planValor = useRef();
  const planNome = useRef();
  const planDesc = useRef();
  const userNome = useRef("");
  const userRua = useRef();
  const userEstado = useRef();
  const userCep = useRef();
  const userBairro = useRef();
  const userCidade = useRef();
  const userNumRua = useRef();
  const [dataRequest, setDataRequest] = useState(false);

  const [iniData, setIniData] = useState({
    userCpf: "",
    userEmail: "",
    planValor: 0,
    userNome: "",
    userRua: "",
    userEstado: "",
    userCep: "",
    userBairro: "",
    userCidade: "",
    userNumRua: 0,
  });

  useEffect(() => {
    loadUser()
    loadInitialization();
  }, []);

  const { pathname } = useLocation();
  const params = pathname.split("/", 3);

  const isRenewLink = (userId: any) => {
    {
      // ad for diferente de none salva em localStorage
      ad !== "none" && localStorage.setItem("renewAd", String(ad));
      // apos pagamento renewAd sera apagado do localStorage
    }

    if (params[1] === "renewLink") {
      if (planId && mercado && ad && userToken && clientId) {
        localStorage.setItem("user", JSON.stringify(userToken));
        navigate(
          `/renewLink/pagamento/mp/${planId}/${mercado}/${ad}/${clientId}`,
          {
            replace: true,
          }
        );
      } else if (!userToken) {
        if (userId) {
          const userIdString = userId.toString();
          // o {replace: true} substitui a rota planId/mercado pela planId/mercado/userIdString, previne o usuario de voltar para a rota incompleta
          navigate(
            `/renewLink/pagamento/mp/${planId}/${mercado}/${ad}/${userIdString}`,
            { replace: true }
          );
        } else {
          setWithExpiry(
            "renewLink",
            [true, `${planId}`, `${mercado}`, `${ad}`],
            600000
          );
          navigate("/login");
        }
      }
    }
  };

  function setWithExpiry(key: string, value: any, ttl: any) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  async function loadUser() {
    await axios
      .all([
        axios.get(process.env.REACT_APP_API_RB + "/clientes/cadastro", {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": `${userToken ? userToken : getUserToken()}`,
          },
        }),
        axios.get(process.env.REACT_APP_API_RB + `/planos/id/${planId}`),
      ])
      .then(
        axios.spread((response, response2) => {
          const newGetUser = response.data[0];
          userCpf.current = newGetUser.cli_cpf_cnpj;
          userEmail.current = newGetUser.cli_email;
          userNome.current = newGetUser.cli_nome;
          userCep.current = newGetUser.cli_cep;
          userEstado.current = newGetUser.cli_estado;
          userCidade.current = newGetUser.cli_cidade;
          userBairro.current = newGetUser.cli_bairro;
          userRua.current = newGetUser.cli_rua;
          userNumRua.current = newGetUser.cli_numero;
          // confere se é um link de renovação caso usuario esteja logado
          isRenewLink(newGetUser.cli_id);
          const newPlanDetails = response2.data[0];
          planValor.current = newPlanDetails.plan_valor;
        })
      )
      .catch((e) => {
        isRenewLink(false);
        console.log("Erro ao buscar o detalhes do plano e dados do cliente", e);
      });
  }

  async function loadInitialization() {
    await axios
      .all([
        axios.get(process.env.REACT_APP_API_RB + "/clientes/cadastro", {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": `${userToken ? userToken : getUserToken()}`,
          },
        }),
        axios.get(process.env.REACT_APP_API_RB + `/planos/id/${planId}`),
      ])
      .then(
        axios.spread((response, response2) => {
          const newGetUser = response.data[0];

          const newPlanDetails = response2.data[0];
          planNome.current = newPlanDetails.plan_nome;
          planDesc.current = newPlanDetails.plan_desc;

          if(newPlanDetails.plan_ativo) {
            setIniData({
              userCpf: newGetUser.cli_cpf_cnpj,
              userEmail: newGetUser.cli_email,
              planValor: newPlanDetails.plan_valor,
              userNome: newGetUser.cli_nome,
              userRua: newGetUser.cli_rua,
              userEstado: newGetUser.cli_estado,
              userCep: newGetUser.cli_cep,
              userBairro: newGetUser.cli_bairro,
              userCidade: newGetUser.cli_cidade,
              userNumRua: newGetUser.cli_numero,
            });

          } else {
            toast.warn("Este plano não está disponível.", {icon: <WarningRounded color="warning"/>, theme:"colored",})
          }
        })
      )
      .finally( () => setDataRequest(true))
      .catch(() =>
        console.log("Erro ao buscar o detalhes do plano e dados do cliente")
      );
  }

  const initialization = () => {
    const nome = iniData.userNome!.split(" ", 1).join("");
    const sobrenome = iniData.userNome!.split(" ").pop();
    const price = Number(iniData.planValor!) / 100;
    const initialization = {
      amount: price, // valor total a ser pago
      //preferenceId: 'RV123',
      payer: {
        email: iniData.userEmail,
        firstName: nome,
        lastName: sobrenome,
        identification: {
          type: "CPF", //CpfOuCnpj(userCpf.current),
          number: iniData.userCpf || "0",
        },
        address: {
          zipCode: iniData.userCep || "",
          federalUnit: iniData.userEstado || "",
          city: iniData.userCidade,
          neighborhood: iniData.userBairro,
          streetName: iniData.userRua,
          streetNumber: iniData.userNumRua,
          complement: "",
        },
      },
    };
    return initialization;
  };

  function loadCustomization() {
    const customization = {
      paymentMethods: {
        creditCard: "all",
        debitCard: "all",
        bankTransfer: ["pix"],
        ticket: "all",
      },
    };
    return customization;
  }

  function handleChangeMarket(market: any) {
    setMarketSelect(market);
  }

  async function loadContract(param: any, body: any) {
    await api
      .post("/contratos/adiciona", body)
      .then((response) => {
        loadPayment(param, response.data);
      })
      .then(() => {
        localStorage.removeItem("renewAd");
      })
      .catch(() => console.log("Erro ao criar o contrato"));
  }

  async function loadPayment(param: any, contract: any) {
    const body = { ...param, contract };

    await api
      .post("/cobrancas/payment-mp/", body)
      .then((response) => {
        setErrorPayment(true);
        handleStatusComponents(response.data);
      })
      .catch((e) => console.log(e));
  }

  async function handleDataContract(param: any) {
    const cont_plano_id = planId;
    const cont_cliente_id = clientId;
    const cont_mercado = String(marketSelect);
    const cont_publicidade = Number(localStorage.getItem("renewAd"));

    if (param.paymentType === "credit_card") {
      const cont_forma_pgto = 1;
      const body = {
        cont_plano_id,
        cont_cliente_id,
        cont_forma_pgto,
        cont_mercado,
        cont_publicidade,
      };
      return loadContract(param, body);
    }
    if (param.paymentType === "debit_card") {
      const cont_forma_pgto = 1;
      const body = {
        cont_plano_id,
        cont_cliente_id,
        cont_forma_pgto,
        cont_mercado,
        cont_publicidade,
      };
      return loadContract(param, body);
    }
    if (param.paymentType === "ticket") {
      const cont_forma_pgto = 2;
      const body = {
        cont_plano_id,
        cont_cliente_id,
        cont_forma_pgto,
        cont_mercado,
        cont_publicidade,
      };
      return loadContract(param, body);
    }

    const cont_forma_pgto = 3;
    const body = {
      cont_plano_id,
      cont_cliente_id,
      cont_forma_pgto,
      cont_mercado,
      cont_publicidade,
    };
    return loadContract(param, body);
  }

  function handleStatusComponents(status: any) {
    setIdPayment(`${status.id}`);
    setDataRequest(false);
    setErrors(false);

    if (
      status.status === "rejected" ||
      status.status === "cancelled" ||
      status.status === "refunded" ||
      status.status === "charged_back"
    ) {
      return setStatusButtonPayment(true);
    }
    setStatusButtonPayment(false);
    setStatusButtonHome(true);
  }

  function handleButton() {
    setDataRequest(true);
    setStatusButtonPayment(false);
    setErrorPayment(false);
  }

  const memoizedPayment = useMemo(() =>
    (dataRequest && iniData) && (
      <Payment
        initialization={initialization()}
        customization={loadCustomization()}
        onSubmit={async (param) => {
          if (marketSelect === 0) {
            return setErrors(true);
          }
          handleDataContract(param);
          setDonePayment(true);
        }}
      />
    )
    , [marketSelect, dataRequest])

  return (
    <>
      <PaymentMPComponent
        planID={planId}
        marketID={mercado}
        showMarketOnID={"0"}
        errors={errors}
        handleChangeMarket={handleChangeMarket}
        donePayment={donePayment}
      >
        {dataRequest && memoizedPayment}
        {errorPayment && (
          <Box m={2}>
            <StatusScreen initialization={{ paymentId: `${idPayment}` }} />
          </Box>
        )}
        {statusButtonHome && (
          <Button
            variant="contained"
            disableElevation
            onClick={() => navigate("/dashboard/video")}
            sx={{
              backgroundColor: BUTTON_LIGHT_GREEN,
              height: "50px",
              marginX: 2,
              marginBottom: 2,
            }}
          >
            Ir parar os videos
          </Button>
        )}
        {statusButtonPayment && (
          <Button
            variant="contained"
            disableElevation
            color="error"
            onClick={handleButton}
            sx={{
              height: "50px",
              marginX: 2,
              marginBottom: 2,
            }}
          >
            Tentar novamente
          </Button>
        )}
      </PaymentMPComponent>
    </>
  );
}

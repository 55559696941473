export default function FormCheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#ffffff"
        strokeWidth={"2px"}
        d="m7 12 2.89 2.89a.155.155 0 0 0 .22 0L17 8"
      />
    </svg>
  );
}
